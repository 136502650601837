import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TemplateService } from '../../service/template.service';
import { combineLatest, finalize, forkJoin, startWith, Subscription } from 'rxjs';
import { ApplicationId, CreateTemplateResponse, DialogMsgTypes, EmailAddData, EmailResponse, GetOneTemplateResponse, LanguageResponse, SelectedLanguageDetails, SelectOptions, TemplateDialogMessages, TemplateFormDatas } from '../../models/template.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatSelectChange } from '@angular/material/select';
import { GetOneCategoryResponse } from 'src/app/zen-mail/Categories/models/categories.model';
import { Clipboard } from '@angular/cdk/clipboard';
import { Heading } from 'src/app/shared/constants/common-header.constants';
import { DynamicDataSource, dynamicTitle } from 'src/app/shared/models/common-card-data.model';
import { TEMPLATES_MESSAGES } from '../../constants/templates.constants';
import { MatDialog } from '@angular/material/dialog';
import { number } from 'echarts';
@Component({
  selector: 'app-add-edit-template',
  templateUrl: './add-edit-template.component.html',
  styleUrls: ['./add-edit-template.component.scss'],
})
export class AddEditTemplateComponent implements OnInit {

  /**
   * Type of action (create, view, edit).
   * @type {string}
   */
  action!: string;

  /**
   * Holds all available title according to page functionality.
   */
  dynamicTitle : dynamicTitle = {
    edit :{title : "Edit Template"},
    create : {title : "Create Template"},
    view : {title : "View Template"}
  }

  init: EditorComponent['init'] = {
    plugins: 'lists link image table code help wordcount'
  };
  /**
  * Variable SubscriptionObject is used to store the observables.
  * @type {Subscription}
  */
  SubscriptionObject: Subscription = new Subscription();
  /**
  * Variable userDetails is used to store user details.
  * @type {SelectOptions}
  */
  userDetails: SelectOptions[] = [];
  /**
  * Variable applicationDetails is used to store application details.
  * @type {SelectOptions}
  */
  applicationDetails: SelectOptions[] = [];
  /**
  * Variable regionDetails is used to store region details.
  * @type {SelectOptions}
  */
  regionDetails: SelectOptions[] = [];
  /**
   * Variable categoryDetails is used to store category details.
   * @type {SelectOptions}
   */
  categoryDetails: SelectOptions[] = [];
  /**
   * Variable subCategoryDetails is used to store sub category details.
   * @type {SelectOptions}
   */
  subCategoryDetails: SelectOptions[] = [];
  /**
  * Variable languageDetails is used to store language details.
  * @type {SelectOptions}
  */
  languageDetails: SelectOptions[] = [];
  /**
  * Variable isView is used to store the page is view mode or not.
  * @type {boolean}
  */
  isView: boolean = false;
  /**
  * Variable isEdit is used to store the page is edit mode or not.
  * @type {boolean}
  */
  isEdit: boolean = false;
  /**
  * Variable isDialogVisible is used to store test mail Dialog.
  * @type {boolean}
  */
  isDialogVisible: boolean = false;
  /**
  * Variable pathData is used to store the route path datas.
  * @type {boolean}
  */
  pathData!: string;
  /**
  * Variable languageId is used to store the page language.
  * @type {number}
  */
  languageId!: SelectedLanguageDetails | undefined;
  /**
  * Variable paramsId is used to store the page params id.
  * @type {number}
  */
  paramsId!: string;

  /**
   * Declaring form group 
   */
  templateForm!: FormGroup;
  /**
   * Declaring form group 
   */
  emailForm!: FormGroup;
  /**
  * @type {TemplateDialogMessages}
  * Assigning the constant templateMessages to the template Messages variable.
  */
  templateMessages: TemplateDialogMessages = TEMPLATES_MESSAGES;
  /**
* Variable isActivated is used to store status of template.
* @type {boolean}
*/
  isActivated: boolean = true;
  /**
* Variable isActivatedValue is used to store status of edit.
* @type {boolean}
*/
  isActivatedValue: boolean = false;
  /**
  * Variable selectedOption is used to store the region.
  * @type {string}
  */
  selectedOption!: string;
  /**
  * Variable loading is used to store the loader value.
  * @type {string}
  */
  loading: boolean = true;
  /**
  * Variable animate is used to store the apply animation or not.
  * @type {string}
  */
  animate: boolean = false;
  /**
 * Varaible used to specify removed mail id for test mail.
 */
  removable: boolean = true;
  /**
* Varaible used to store key codes.
*/
  separatorKeysCodes: number[] = [ENTER, COMMA];
  /**
   * Array used to store test mail ids.
   */
  emailList: string[] = [];
  /**
   * isSubCategory used to store isSubCategory status .
   */
  isSubCategory: boolean = false;
  /**
   * isCategory used to store Category status .
   */
  isCategory: boolean = false;
  /**
   * isDisabledInput used to template name input field will be disabled .
   */
  isDisabledInput: boolean = true;
  getOneTemplateData!: GetOneTemplateResponse;
  emailFormat: string = "zenmail@123.com";
   /**
     * Variable has header data to be displayed on commen header.
     * @type {Heading}
     */
   heading:Heading = {
    title : '',
  }
  customerId!:number|string
    /**
   * Reference to the template for the create or edit dialog.
   * @type {TemplateRef<any>}
   */
    @ViewChild('dialogDisplay', { static: false }) dialogDisplay?: TemplateRef<any>;
  /**
  * component constructor which is used to inject the required services.
  * @param route To refer to the navigate page.
  * @param templateService To refer to the templateService to access the mail Template functions.
  * @param authService To refer to the AuthService to access the region subscription.
  * @param dialogService to get the dialog information.
  * @param paramsRouter To refer to the params of the current path.
  */
  constructor(private route: Router,
    private authService: AuthService,
    private templateService: TemplateService,
    private dialogService: CommonDialogService,
    private paramsRouter: ActivatedRoute,
    private snackbar: CommonSnackbarService,
    private commonService: CommonService,
    private clipboard: Clipboard,
    private dialog: MatDialog) {
  }
  /**
  * Angular life cycle hook that initiates the component
  */
  ngOnInit(): void {
    this.getParamData();
    this.authService.region$.subscribe(res => {
      if (res) {
        this.loading=true;
        this.selectedOption = res;
        this.paramsRouter.params.subscribe((params) => {
          if(params['customer']){
            this.customerId=params['customer'];
          }
          if (params['action']) {
            this.action = params['action']
          }

          if (params['data']) {
            this.action = params['data']
            this.isView = params['data'] === 'view' ? true : false
            this.isEdit = params['data'] === 'edit' ? true : false
            const data = {
              customerId: this.customerId
            }
            this.SubscriptionObject = this.templateService?.getOneTemplate(this.paramsId, data).subscribe({
              next: (getOneTemplateData: GetOneTemplateResponse) => {
                if (getOneTemplateData) {
                  this.isActivated = getOneTemplateData?.response?.isActive;
                  this.getAllSubCategory(getOneTemplateData?.response?.categoryId);
                  this.getOneTemplateData = getOneTemplateData;
                  const applicationId = {
                    filterData: JSON.stringify({ 'applicationId': getOneTemplateData?.response?.applicationId })
                  };
                  this.templateService.getTemplateLanguage(applicationId);
                  this.formInitialization(getOneTemplateData);
                  if (this.isView === true) {
                    this.heading.title = 'Visual Template';
                    this.getAllApplication();
                  }
                  if (this.isEdit === true) {
                    this.heading.title = 'Personalize Template';
                    this.isCategory=true;
                    this.getAllApplication();
                  }
                }
              }, error: (err) => {
                this.snackbar.OpenSnackBar({
                  message: this.templateMessages?.failedTemplateList,
                  heading: 'Failed',
                  actionType: 'failure',
                  duration: 2
                });
                this.loading = false;
                this.route.navigate(['app/mail/templates'])
              }
            });
          }
          else {
            this.heading.title = 'New Template';
            this.getAllServices();
          }
        })
        this.formInitialization();
      }
    })
  }
  /**
 * method which is used to encrypt the template id
 */
  getParamData() {
    this.paramsRouter.paramMap.subscribe(params => {
      this.pathData = '' + params.get('data');
      this.paramsId = '' + this.commonService.getParam('' + params.get('id'));
    });
  }
  /**
  * method which is used to call all Template Api in one function
  */
  getAllServices() {
    this.getAllApplication();
    this.getAllCategory();
    this.getAllLanguage();
  }
  /**
   * method which is used to fetch data from Applications
   */
  getAllApplication() {
    this.SubscriptionObject = this.templateService.getTemplateApplication().subscribe({
      next: (applicationResponse) => {
        if (applicationResponse) {
          this.applicationDetails = applicationResponse?.applicationData?.rows.map((application) => ({
            id: application.id,
            name: application.applicationName
          }));
        }
      }, error: (err) => {
        this.snackbar.OpenSnackBar({
          message: this.templateMessages?.failedApplication,
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
        });
        this.loading = false;
      }
    })
  }
  /**
  * method which is used to fetch data from Categories
  */
  getAllCategory(applicaionId?: any) {
    const data = {
      filterData: JSON.stringify({ 'applicationId': applicaionId })
    };
    this.SubscriptionObject = this.templateService.getTemplateCategories(applicaionId ? data : applicaionId).subscribe({
      next: (categoryResponse) => {
        if (categoryResponse) {
          this.categoryDetails = categoryResponse?.category?.rows.map((category) => ({
            id: category.id,
            name: category.categoryName
          }));
          if(applicaionId){
            this.isCategory=true;
            this.isCategory = this.categoryDetails?.length === 0 ? false : true;
          }
        }
      }, error: (err) => {
        const msgType = {
          header: 'failure',
          message: 'Something went wrong in Categories,so unable to Create the template',
          actionType: 'failure',
          button: { right: 'leave'},
        }
        this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
          if (dialogResult) {
            this.templateForm.markAsPristine();
            this.loading = false;
            this.route.navigate(['app/mail/templates']);
          }
        })
        this.loading = false;
      }
    })
  }
  getAllSubCategory(categoryId: number) {
    this.subCategoryDetails = [];
    this.templateForm.controls['subCategoryId'].setValue(null);
    this.SubscriptionObject = this.templateService.getOneCategories(categoryId).subscribe({
      next: (subCategoryResponses: GetOneCategoryResponse) => {
        this.subCategoryDetails = subCategoryResponses?.category?.subCategory.map((subCategory) => ({
          id: subCategory.id,
          name: subCategory.subCategoryName
        }));
        if(categoryId){
          this.isSubCategory=true;
        }
        this.isSubCategory = this.subCategoryDetails?.length === 0 ? false : true;
      },error:(err)=>{
        this.snackbar.OpenSnackBar({
          message: this.templateMessages?.failedCategory,
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
        });
        this.loading = false;
      }
    })
  }
  /**
  * method which is used to fetch data from Languages
  */
  getAllLanguage(applicaionId?: any) {
    const data = {
      filterData: JSON.stringify({ 'applicationId': applicaionId })
    };
    this.SubscriptionObject = this.templateService.getTemplateLanguage(applicaionId ? data : applicaionId).subscribe({
      next: (languageResponse: LanguageResponse) => {
        if (languageResponse) {
          this.languageDetails = languageResponse?.data?.rows.map((language) => ({
            id: language.languageCode,
            name: language.language
          }));
        }
      }, error: (err) => {
        this.snackbar.OpenSnackBar({
          message: this.templateMessages?.failedLanguageList,
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
        });
        this.loading = false;
      }

    })
  }
  /**
   * Method which is used to initialize the form
   */
  formInitialization(TemplateData?: TemplateFormDatas) {
    this.templateForm = new FormGroup({
      applicationId: new FormControl(TemplateData?.response?.applicationId ? TemplateData?.response?.applicationId : null, [Validators.required]),
      region: new FormControl(TemplateData?.response?.region ?? this.selectedOption),
      categoryId: new FormControl(TemplateData?.response?.categoryId ?? null, [Validators.required]),
      subCategoryId: new FormControl(TemplateData?.response?.subCategoryId ?? null),
      generateTemplateName: new FormControl(TemplateData?.response?.templateName?this.toTitleCase(TemplateData?.response?.templateName):null ?? null, [Validators.required]),
      templateName: new FormControl(TemplateData?.response?.templateName ?? null, [Validators.required]),
      subject: new FormControl(TemplateData?.response?.contentArray[0].subject ?? '', [Validators.required]),
      lanCode: new FormControl(TemplateData?.response?.contentArray[0].lanCode ?? '', [Validators.required]),
      content: new FormControl(TemplateData?.response?.contentArray[0].content ?? '', [Validators.required])
    })
    this.templateForm.controls['generateTemplateName'].valueChanges.subscribe(value => {
      this.templateForm.controls['templateName'].setValue(this.generateCategoryCode(value));
    });
    if (TemplateData?.response?.applicationId) {
     this.getAllLanguage(TemplateData?.response?.applicationId);
     this.getAllCategory(TemplateData?.response?.applicationId);
}
setTimeout(() => {
  this.loading=false;
}, 1000);
  }
   /**
   * Method which is used to change  title case
   */
 toTitleCase(value:string){
  let result = value.replace(/_/g, ' ');
  result = result.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
  return result;
 }
  /**
   * Method which is used to handle navigation when the user wants to go back
   */
  onBack() {
    if (this.isActivatedValue) {
      this.templateForm.markAsPristine();
      const msgType = {
        header: 'confirmation',
        message: this.templateMessages?.unSavedMsg,
        actionType: 'confirmation',
        button: { right: 'Yes, Leave', left: 'Stay' },
      }
      this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.loading = false;
          this.route.navigate(['app/mail/templates']);
        }
      })
    }
    else {
      this.loading = false;
      this.route.navigate(['app/mail/templates']);
    }
  }
  /**
  * Method which is used to send test mail for user
  */
  onTestMail() {
    if (this.templateForm.valid) {
      this.createMailForm();
      if(this.dialogDisplay) {
        this.dialog && this.dialog.open(this.dialogDisplay, { disableClose: true, width: '450px' });
      }
      // this.isDialogVisible = true;
    }
    else {
      this.snackbar.OpenSnackBar({
        message: this.templateMessages?.mandatoryMsg,
        heading: 'Failed',
        actionType: 'failure',
        duration: 2
      });
    }
  }
  /**
* Method which is used to test mail form initialization
*/
  createMailForm() {
    this.emailForm = new FormGroup({
      email: new FormControl([]),
    });
  }
  /**
 * Method used to add test mail ids.
 * @param event returns added mail ids.
 */
  add(event: EmailAddData): void {
    let index;
    this.emailForm?.get('email')?.markAllAsTouched();
    if (this.emailForm?.get('email')?.valid) {
      if (event && event.value) {
        index = this.emailList.findIndex((email) => email === event.value);
        if (index === -1) {
          if (event.value.trim().match('^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$')) {
            this.emailList.push(event.value.trim());
          } else {
            this.snackbar.OpenSnackBar({
              message: this.templateMessages?.validMail,
              heading: 'Failed',
              actionType: 'failure',
              duration: 2
            });
          }
        } else {
          this.snackbar.OpenSnackBar({
            message: this.templateMessages?.existMail,
            heading: 'Failed',
            actionType: 'failure',
            duration: 2
          });
        }
        this.emailForm?.get('email')?.setValue(null);
      } else if (!this.emailList.length) {
        this.emailForm?.get('email')?.setErrors({ required: true });
      }
    }
  }
  /**
* Method used to set required error message.
*/
  onBackSpace() {
    if (!this.emailList?.length && !this.emailForm.get('email')?.value) {
      this.emailForm.get('email')?.setErrors({ required: true });
    }
  }
  /**
 * Method used to remove test mail ids from the array.
 * @param data returns removed id.
 */
  removeEmail(data: string): void {
    if (this.emailList?.indexOf(data) >= 0) {
      this.emailList.splice(this.emailList.indexOf(data), 1);
      if (!this.emailList.length) {
        this.emailForm?.get('email')?.setErrors({ required: true });
      }
    }
  }
  /**
  * Method which is used to send the template to the recipients.
  */
  sendMail() {
    if (this.emailForm.valid) {
      const emailDetails = {
        isZenmail: true,
        mailConfiguration: {
          recipients: this.emailList,
          sender: "appadmin@centizen.com",
          subject: this.templateForm.value?.subject,
        },
        mailData: {
          applicationId: this.templateForm.value?.applicationId,
          categoryId: this.templateForm.value?.categoryId,
          content: this.commonService.btoaEncryption(this.templateForm.value?.content),
          lanCode: this.templateForm.value?.lanCode
        }
      }
      this.SubscriptionObject = this.templateService.sendEmail(emailDetails).subscribe({
        next: (res: EmailResponse) => {
          if (res) {
            const msgType = {
              header: '',
              message: this.templateMessages?.MailSuccess,
              actionType: 'success',
              button: { right: 'Ok' },
            }
            this.dialogMsg(msgType);
          }
        }, error: (err) => {
          this.snackbar.OpenSnackBar({
            message: this.templateMessages?.MailApiWrong,
            heading: 'Failed',
            actionType: 'failure',
            duration: 2
          });
          this.loading = false;
        }
      })
    }
  }
  /**
 * Method which is used to close the email form.
 */
  closeDialog() {
    if (this.emailForm.dirty) {
      const msgType = {
        header: 'confirmation',
        message: this.templateMessages?.unSavedMsg,
        actionType: 'confirmation',
        button: { right: 'Yes, Leave', left: 'Stay' },
      }
      this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.dialogReset();
        }
      })
    }
    else {
      this.dialogReset();
    }
  }
  dialogReset() {
    this.dialog && this.dialog.closeAll();
    this.emailList=[]
  }
  /**
  * Method which is used to save and update the form
  */
  onSave() {
    if (this.templateForm.valid) {
      const templateForm = {
        applicationId: this.templateForm.value?.applicationId,
        categoryId: this.templateForm.value?.categoryId,
        subCategoryId: this.templateForm.value?.subCategoryId ?? null,
        content: this.commonService.btoaEncryption(this.templateForm.value?.content),
        skipDecryption: true,
        isActive: this.isActivated,
        lanCode: this.templateForm.value?.lanCode,
        subject: this.templateForm.value?.subject,
        templateName: this.templateForm.value?.templateName,
        customerId:(this.customerId==='default'?this.customerId:Number(this.customerId))
      }
      if (!this.isEdit) {
        this.loading=true;
        this.SubscriptionObject = this.templateService.createTemplate(templateForm).subscribe({
          next: (createTemplate: CreateTemplateResponse) => {
            if (createTemplate) {
              this.snackbar.OpenSnackBar({
                message: createTemplate?.success ? this.templateMessages?.templateCreatedSuccess : this.templateMessages?.templateCreatedFailed,
                heading:createTemplate?.success ?'Success':'Failed',
                actionType: createTemplate?.success ? 'success' : 'failure',
                duration: 2
              });
              this.templateForm.markAsPristine();
              if(createTemplate?.success){
              this.loading = false;
              this.route.navigate(['app/mail/templates'])
              }
            }
          }, error: (err) => {
            this.snackbar.OpenSnackBar({
              message: this.templateMessages?.wrongTempleteCreation,
              heading: 'Failed',
              actionType: 'failure',
              duration: 2
            });
            this.loading = false;
          }
        })
      }
      else if ((this.isEdit && this.templateForm.dirty) || this.isActivatedValue) {
        this.loading=true;
        const data = {
          id: this.paramsId
        }
        this.SubscriptionObject = this.templateService.updateTemplate(data.id, templateForm).subscribe({
          next: (updateResponse) => {
            this.snackbar.OpenSnackBar({
              message: this.templateMessages?.templateUpdatedSuccess,
              heading: 'Success',
              actionType: 'success',
              duration: 2
            });
            this.templateForm.markAsPristine();
            // if (updateResponse?.success) {
            this.loading=false;
              this.route.navigate(['app/mail/templates']);
            // }
          },
          error: (err) => {
            this.snackbar.OpenSnackBar({
              message: this.templateMessages?.wrongTempleteUpdation,
              heading: 'Failed',
              actionType: 'failure',
              duration: 2
            });
            this.loading = false;
          }
        });

      }
      else {
        this.snackbar.OpenSnackBar({
          message: this.templateMessages?.noChanges,
          heading: 'Warning',
          actionType: 'warning',
          duration: 2
        });
        this.loading = false;
      }
    } else {
      this.snackbar.OpenSnackBar({
        message: this.templateMessages?.mandatoryMsg,
        heading: 'Warning',
        actionType: 'failure',
        duration: 2
      });
    }
  }
  /**
  * Method which is used to show dialogs based on Action.
  */
  dialogMsg(msgType: DialogMsgTypes) {
    return this.dialogService.openDialog({
      header: msgType.header ?? '',
      message: msgType.message,
      actionType: msgType.actionType,
      button: { right: msgType.button?.right ?? '', left: msgType.button?.left ?? '' },
      disableClose: true
    });
  }
  /**
 * Method which is used to update the isActive.
 */
  toggleCliked(event: string) {
    this.isActivatedValue = true;
    this.animate = true;
    if (event === 'true') {
      this.isActivated = true;
    } else {
      this.isActivated = false;
    }
  }
  /**
* Method which is used to select the language and category based on applicationId.
*/
  onApplicationSelectionChange(event: MatSelectChange) {
    this.getAllLanguage(event.value);
    this.getAllCategory(event.value);
  }
  /**
* Method which is used to select the subcategory based on categoryId.
*/
  onCategorySelectionChange(event: MatSelectChange) {
    this.getAllSubCategory(event.value);
  }
  /**
* Method which is used to select the languages.
*/
  notAvaliableImage: string = "<p><img style=\"display: block; margin-left: auto; margin-right: auto;\" src=\"https://tse4.mm.bing.net/th?id=OIP.DxV_HfLqa-LEu7uY6pJxaQHaG8&amp;pid=Api&amp;P=0&amp;h=180\"></p>"
  onLanguageSelectionChange(event: MatSelectChange) {
    const selectedLanguage = event.value;
    if (this.getOneTemplateData) {
      const matchingContent = this.getOneTemplateData.response.contentArray.find(
        (contentArray) => contentArray.lanCode === selectedLanguage
      );
      const lanCode = matchingContent ? matchingContent.lanCode : event.value;
      const subject = matchingContent ? matchingContent.subject : null;
      const content = matchingContent ? matchingContent.content : this.notAvaliableImage;
      this.templateForm.controls['lanCode'].setValue(lanCode);
      this.templateForm.controls['subject'].setValue(subject);
      this.templateForm.controls['content'].setValue(content);
    }
  }
  
  /**
* Method which is used to send place holder text based on category avaliability .
*/
  getPlaceholderText(): string {
    return this.isSubCategory ? 'Select Sub Category' : 'Sub Categories Not Avaliable';
  }
    /**
* Method which is used to send place holder text based on application avaliability .
*/
  getPlaceholderText1(): string {
    return this.isCategory ? 'Select Category' : 'Categories Not Avaliable';
  }
  /**
* Method which is used to generate the category and sub category code and change to upperCase.
*/
  generateCategoryCode(templateName: string): string {
    if (!templateName) {
      return '';
    }
    return templateName.toUpperCase().replace(/\s+/g, '_');
  }
  copyTemplateName(templateName: string) {
    this.clipboard.copy(templateName);
    this.snackbar.OpenSnackBar({
      message: 'Copied Template Name Successfully',
      actionType: 'success',
      heading: 'Success',
      duration: 1
    });
  }
    /**
  * Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
  * @type {void}
  */
    ngOnDestroy(): void {
      if (this.SubscriptionObject) {
        this.SubscriptionObject.unsubscribe();
      }
    }
    headerEvent(evnt : any){
      (this as DynamicDataSource)[evnt]();
    }
    /**
      * Method used to deactivate the canDeactivate guard when the form is dirty.
      * @returns { boolean } dialog box
    */
    canDeactivate(): boolean {
      return this.templateForm ? this.templateForm.pristine : true;
    }
    onSaveTemplates(){
    }
}
