import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TemplateListComponent } from "../../../libs/shared/src/lib/components/template-list/template-list.component";



@NgModule({
  declarations: [  ],
  imports: [
    CommonModule,
    TemplateListComponent
]
})
export class ZenMarketingModule { }
