<div *ngIf="!loading" class="common-header">
    <app-common-header [heading]="dynamicTitle[action]" ></app-common-header>
</div>
<!-- <div class="view-configuration-header-container" fxlayout="row" fxLayoutAlign="space-between">
    <h2 class="view-configuration-header">{{createMode ? 'New Configuration' : "Personalize Configuration"}}</h2> -->
    <!-- <button (click)="onCancelClick()" class="button-border" mat-button data-control="back-button">
      <mat-icon matTooltip="Back To Configuration List" data-control="back-arrow-icon">arrow_back</mat-icon>Back
    </button> -->
<!-- </div> -->
    <div *ngIf="loading; else viewCustomer" class="add-edit-container common-card-style">
      <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
    </div>
    <ng-template #viewCustomer>
        <mat-card class="add-edit-container common-card-style" >
        <form *ngIf="configurationForm" [formGroup]="configurationForm" (ngSubmit)="editMode ? onUpdate() : onSubmit()" fxLayout="column" fxLayoutGap="15px" fxLayoutGap.lt-sm="10px">
            <div fxLayout="column" fxLayoutGap="2%">
                <div fxLayout="row">
                  <div fxLayoutAlign="start center" fxFlex="auto" [ngStyle]="{'padding-top':'20px'}">
                    <h4 class="mat-card-header">Configuration Details </h4>
                  </div>
                  <div fxFlex="auto" fxLayoutGap="30px" fxLayoutAlign="end center" fxLayoutAlign.xs="center end" fxLayout.xs="column">
                    <button type="button" class="cancel-button btn" mat-button (click)="onCancelClick()" data-control="cancel-button">Cancel</button>
                    <ng-container>
                        <button class="create-button btn" type="submit" mat-button data-control="create-button">{{ editMode ? 'Update' : 'Create' }}</button>
                    </ng-container>
                </div>
                </div>
                <div>
                  <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
                </div>
              </div>
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxLayoutGap.lt-sm="10px" fxFlex="100%">
                <!-- <div fxLayout="column" fxFlex="47.5" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Region</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <mat-select placeholder="Select Region" [value]="createMode ? '' : editMode ? configurationDetail?.region : ''">
                            <mat-option (onSelectionChange)="onRegionChange($event)" data-control="ap-south-1" [value]="'ap-south-1'">
                                ap-south-1
                            </mat-option>
                            <mat-option (onSelectionChange)="onRegionChange($event)" data-control="us-west-2" [value]="'us-west-2'">
                                us-west-2
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
                <ng-container *ngIf="this.createMode || (this.configurationDetail && this.configurationDetail.id !== 0 || this.configurationDetail && this.configurationDetail.applicationId !== 0)">
                    <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                        <mat-label>User Name</mat-label>
                        <mat-form-field class="form-container" appearance="outline">
                            <mat-select placeholder="Select User Name" formControlName="userId">
                                <mat-option (onSelectionChange)="onUserChange($event)" *ngFor="let user of userData" [attr.data-control]="user?.firstName + '-' + user?.lastName" [value]="user?.id">
                                    {{user.firstName + ' ' + user.lastName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="configurationForm?.get('userId')?.hasError('required')">{{configurationValidators.validators.userNameRequired}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                        <mat-label>Application Name</mat-label>
                        <mat-form-field class="form-container" appearance="outline">
                            <mat-select [placeholder]="this.applicationPlaceHolder ? this.applicationPlaceHolder : 'Select Application Name'" formControlName="applicationId">
                                <mat-option *ngFor="let application of applicationData" [attr.data-control]="application?.applicationName" [value]="application?.id">
                                    {{application?.applicationName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="configurationForm.get('applicationId')?.hasError('required')">{{configurationValidators.validators.applicationNameRequired}}</mat-error>
                        </mat-form-field>   
                    </div>
                    <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                        <mat-label>Category</mat-label>
                        <mat-form-field class="form-container" appearance="outline">
                            <mat-select [value]="configurationDetail?.categoryId" [placeholder]="categoryPlaceHolder ? categoryPlaceHolder : 'Select Category Name'" formControlName="categoryId">
                                <mat-option *ngFor="let category of categoryData" [attr.data-control]="category?.categoryName" [value]="category?.id">
                                    {{category?.categoryName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="configurationForm?.get('categoryId')?.hasError('required')">{{configurationValidators.validators.categoryRequired}}</mat-error>
                        </mat-form-field>
                    </div>
                </ng-container>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Server</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <mat-select placeholder="Select Server" formControlName="server" [value]="configurationDetail?.server">
                            <mat-option *ngFor="let serverData of serverOptionData" [attr.data-control]="serverData?.value" [value]="serverData?.value">{{serverData?.option}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="configurationForm?.get('server')?.hasError('required')">{{configurationValidators.validators.serverRequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Host</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="host" data-control="host-input" placeholder="Enter Host Url">
                        <mat-error *ngIf="configurationForm?.get('host')?.hasError('required')">{{configurationValidators.validators.hostRequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>User Mail</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="userMail" data-control="userMail-input" placeholder="Enter User Name">
                        <mat-error *ngIf="configurationForm?.get('userMail')?.hasError('required')">{{configurationValidators.validators.userMailRequired}}</mat-error>
                        <mat-error *ngIf="configurationForm?.get('userMail')?.hasError('email')">{{configurationValidators.validators.userMailPattern}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Password</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="password" data-control="password-input" placeholder="Enter Password">
                        <mat-error *ngIf="configurationForm?.get('password')?.hasError('required')">{{configurationValidators.validators.passwordRequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>From</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="from" data-control="email-input" placeholder="Enter Email Address">
                        <mat-error *ngIf="configurationForm?.get('from')?.hasError('required')">{{configurationValidators.validators.emailIdRequired}}</mat-error>
                        <mat-error *ngIf="configurationForm?.get('from')?.hasError('email')">{{configurationValidators.validators.emailIdPattern}}</mat-error>
                    </mat-form-field >
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Port</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="portNumber" data-control="port-input" placeholder="Enter Port Number" maxlength="5">
                        <mat-error *ngIf="configurationForm?.get('portNumber')?.hasError('required')">{{configurationValidators.validators.portNumberRequired}}</mat-error>
                        <mat-error *ngIf="configurationForm?.get('portNumber')?.hasError('pattern')">{{configurationValidators.validators.portNumberPattern}}</mat-error>
                        <mat-error *ngIf="configurationForm?.get('portNumber')?.hasError('maxRange') && !configurationForm?.get('portNumber')?.hasError('pattern')">
                            {{configurationValidators.validators.portNumberMaxRange}}
                        </mat-error>
                        <!-- <mat-error *ngIf="configurationForm.get('portNumber')?.hasError('maxLength')">{{configurationValidators.validators.portNumberMaxLength}}</mat-error> -->
                    </mat-form-field >
                </div>
            </div>
            <!-- <div fxFlex="100%" fxLayoutGap="30px" fxLayoutAlign="center start">
                <button type="button" class="cancel-button btn" mat-button (click)="onCancelClick()" data-control="cancel-button">Cancel</button>
                <ng-container *ngIf="viewMode">
                    <button type="button" class="view-logs-button btn" mat-button (click)="onViewLogClick()" data-control="cancel-button">View Logs</button>
                    <button type="button" class="view-templates-button btn" mat-button (click)="onViewTemplatesClick()" data-control="cancel-button">View Templates</button>
                </ng-container>
                <ng-container *ngIf="!viewMode">
                    <button class="create-button btn" type="submit" mat-button data-control="create-button">{{ editMode ? 'Update' : 'Create' }}</button>
                </ng-container>
            </div> -->
        </form>
    </mat-card>
    </ng-template>
