import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SideMenuBarComponent } from './shared/components/side-menu-bar/side-menu-bar.component';
import { SigninComponent } from './auth/components/signin/signin.component';
import { SignupComponent } from './auth/components/signup/signup.component';
import { DashboardComponent } from './auth/components/dashboard/dashboard.component';
import { ViewReportComponent } from './zen-mail/Reports/components/view-report/view-report.component';
import { authGuard } from './auth/service/auth.guard';
import { logoutGuard } from './auth/service/logout.guard';
import { ViewMailLogComponent } from './zen-mail/Logs/components/view-mail-log/view-mail-log.component';
import { ReportListComponent } from './zen-mail/Reports/components/report-list/report-list.component';
import { CategoriesListComponent } from './zen-mail/Categories/components/categories-list/categories-list.component';
import { MailTemplatesListComponent } from './zen-mail/Templates/components/mail-templates-list/mail-templates-list.component';
import { CustomersMailComponent } from './customers/components/customers/customers-mail.component';
import { LogListComponent } from './zen-mail/Logs/components/log-list/log-list.component';
import { ApplicationsComponent } from './zen-mail/Applications/components/applications/applications.component';
import { LanguageListComponent } from './zen-mail/Languages/components/language-list/language-list.component';
import { ConfigurationListComponent } from './zen-mail/Configurations/components/configuration-list/configuration-list.component';
import { AddEditCustomers } from './customers/components/add-edit-customers/add-edit-customers.component';
import { CanDeactivateGuard } from './shared/service/can-deactivate-guard.service';
import { ApllicationFormsComponent } from './zen-mail/Applications/components/apllication-forms/apllication-forms.component';
import { AddEditTemplateComponent } from './template/components/add-edit-template/add-edit-template.component';
import { AddEditConfigurationComponent } from './zen-mail/Configurations/components/add-edit-configuration/add-edit-configuration.component';
import { AddEditCategoriesComponent } from './zen-mail/Categories/components/add-edit-categories/add-edit-categories.component';
import { CustomersMarketingComponent } from './customers/components/customers-marketing/customers-marketing.component';
import { RegistorComponent } from './auth/components/registor/registor.component';
import { UserListComponent } from './zen-mail/Users/components/user-list/user-list.component';
import { ViewProfileComponent } from './view-profile/components/view-profile.component';

const routes: Routes = [{path: '',redirectTo: 'signin',pathMatch:'full'},
  { path: 'signin', component: SigninComponent, canActivate: [logoutGuard], },
  { path: 'set-password', component: RegistorComponent},
  { path: 'forgot-password', component: RegistorComponent},
  { path: 'change-password', component: RegistorComponent},
  { path: 'signup', component: SignupComponent },
  { path: 'app', component: SideMenuBarComponent, canActivate: [authGuard],
    children: [
      { path: 'profile', component: ViewProfileComponent },
      { path: 'mail', 
        children: [
          { path: 'dashboard',component: DashboardComponent},
          { path: 'application',component: ApplicationsComponent},
          { path: 'editApplication/:data/:action', component: ApllicationFormsComponent , canDeactivate:[CanDeactivateGuard]},
          { path: 'editApplication/:action', component: ApllicationFormsComponent , canDeactivate:[CanDeactivateGuard] },
          { path: 'reports', component: ViewReportComponent },
          { path: 'logs', component: LogListComponent},
          { path: 'languages',component: LanguageListComponent},
          { path: 'configuration', component: ConfigurationListComponent},
          { path: 'configuration/:action/:data', component: AddEditConfigurationComponent, canDeactivate: [CanDeactivateGuard]},
          { path: 'configuration/:action', component: AddEditConfigurationComponent, canDeactivate: [CanDeactivateGuard]},
          { path: 'view-templates/:id',component: ViewMailLogComponent},
          { path: 'customers', component: CustomersMailComponent},
          { path: 'customers/:action/:data', component: AddEditCustomers, canDeactivate: [CanDeactivateGuard]},
          { path: 'customers/create', component: AddEditCustomers, canDeactivate: [CanDeactivateGuard]},
          { path: 'report-list',component: ReportListComponent},
          { path: 'reports/:id',component: ViewReportComponent},
          { path: 'user-list',component: UserListComponent},
          { path: 'categories',component: CategoriesListComponent},
          { path: 'addeditcategories/:action', component: AddEditCategoriesComponent,canDeactivate:[CanDeactivateGuard]},
          { path: 'addeditcategories/:data/:id', component: AddEditCategoriesComponent,canDeactivate:[CanDeactivateGuard]},

          // { path: '**', component: NotFoundComponent }
          { path: 'templates', component: MailTemplatesListComponent },
          { path: 'addedittemplate/:action', component: AddEditTemplateComponent ,canDeactivate:[CanDeactivateGuard]},
          { path: 'addedittemplate/:data/:id/:customer', component: AddEditTemplateComponent ,canDeactivate:[CanDeactivateGuard]}
        ]
      },
      { path: 'marketing', 
        children: [
          { path: 'customers', component: CustomersMarketingComponent},
          { path: 'view-templates/:id',component: ViewMailLogComponent},
          { path: 'customers/:action/:data', component: AddEditCustomers, canDeactivate: [CanDeactivateGuard]},
          { path: 'customers/create', component: AddEditCustomers, canDeactivate: [CanDeactivateGuard]},
      
          // { path: '**', component: NotFoundComponent }
          { path: 'templates', component: MailTemplatesListComponent },
          { path: 'addedittemplate', component: AddEditTemplateComponent ,canDeactivate:[CanDeactivateGuard]},
          { path: 'addedittemplate/:data/:id', component: AddEditTemplateComponent ,canDeactivate:[CanDeactivateGuard]}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
