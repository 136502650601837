import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { dataConfig } from '../constants/common-constants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  /**
   * Variable used to flag the filter is clicked or not
   * @type { BehaviorSubject<boolean> }
   */
  isFilterClicked: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() { }
  /**
   * Method which is used to encrypt the param value.
   * @param data Parameter which is used to hold the data to encrypt.
   */
  setParamsObj(data: string | number) {
    if (data !== null && data !== undefined) {
      return CryptoJS.AES.encrypt(JSON.stringify(data), dataConfig.secretKey).toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
    }
    return ''
  }

  /**
   * Method which is used to decrypt the param value.
   * @param ciphertext Parameter Which is used to hold the data to decrypt.
   */
   getParam(ciphertext : string) {
     if (ciphertext) {
       const bytes = CryptoJS.AES.decrypt(ciphertext.toString().replace(/p1L2u3S/g, '+').replace(/s1L2a3S4h/g, '/').replace(/e1Q2u3A4l/g, '='), dataConfig.secretKey);
       return bytes.toString(CryptoJS.enc.Utf8);
     } else {
       return false;
     }
   }
   /**
   * Method which is used to encrypt the content value.
   * @param data content Which is used to hold the data to encrypt.
   */
   btoaEncryption(data:string){
    const content = window.btoa(unescape(encodeURIComponent(data)));
    return content
   }

}


