/**
 * An object containing messages for Categories.
 * @type {Readonly<messages>}
 */
export const CATEGORIES_MESSAGES ={
  wrongInApplication: "Something Went Wrong In Getting Applications",
  wrongInCategory: "Something Went Wrong In Getting Categories",
  deleteCategory: "Are you sure you want to delete this Category?",
  deleteCategorySuccess: "Category Deleted Successfully",
  deleteCategoryFailed: "Failed To Delete the Category",
  wrongInDeleteCategory: "Something went wrong in deleting the category",
  noChanges:"You have no changes to save.",
  unSavedMsg:"You have unsaved changes. Are you sure you want to leave this page?",
  mandatoryMsg:"Please fill out the mandatory fields!",
  removeSubCategory:"Are You Sure Do You Want To Remove This Sub Category?",
  createSubCategorySuccess:"Sub Category Added Successfully",
  updateSubCategorySuccess:"Sub Category Updated Successfully",
  wrongInUpadteCategory: "Something went wrong in updating the category.",
  createCategorySuccess:"Category Created Successfully",
  createCategoryFailed:"Failed To Create Category",
  updateCategorySuccess:"Category Updated Successfully",
  updateCategoryFailed:"Failed To update Category",
  deleteSubCategorySuccess:"Successfully Deleted this Sub Category"

}