import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationsService } from '../../service/applications.service';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { LanguageListService } from 'src/app/zen-mail/Languages/services/language-list.service';
import { editApplication, getSingleApplicationResponse, getUserListResponse } from '../../models/applications.model';
import { getLanguageListResponse } from 'src/app/zen-mail/Languages/models/language.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { snackber_message } from '../../constants/application.constants';
import { SNACKBAR_HEADING, SNACKBAR_MESSAGES, SNACKBAR_OPTIONS, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { dynamicTitle } from 'src/app/shared/models/common-card-data.model';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-apllication-forms',
  templateUrl: './apllication-forms.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./apllication-forms.component.scss']
})
export class ApllicationFormsComponent {
  /**
   * @type {string[]}
   * Array containing the region values.
   */
  region!: string;

  /**
   * FormGroup representing the application form data.
   * @type {FormGroup}
   */
  applicationForm?: FormGroup;

  /**
   * @type {boolean}
   * Flag indicating whether data is loading.
   */
  loading = false;

  /**
   * Subscription object to manage multiple subscriptions.
   * @type {Subscription}
   */
  subscriptionObject: Subscription = new Subscription();

  /**
   * Type of action (create, view, edit).
   * @type {string}
   */
  action!: string;

  /**
   * Holds the edit application request response.
   * @type {editApplication}
   */
  editorObjectHolder!: editApplication;

  /**
   * Holds the application data.
   * @type {getSingleApplicationResponse}
   */
  applicationData!: getSingleApplicationResponse;

  /**
   * Holds the language data.
   * @type {getLanguageListResponse}
   */
  languageData!: getLanguageListResponse;

  /**
   * Holds the data of all users.
   * @type {getUserListResponse}
   */
  userData!: getUserListResponse;

  /**
   * Application ID.
   * @type {string}
   */
  applicationId!: string;

  /**
   * Holds all available title according to page functionality.
   */
  dynamicTitle : dynamicTitle = {
    edit :{title : "Edit Application"},
    create : {title : "Create Application"},
    view : {title : "View Application"}
  }

  /**
   * Constructor to inject necessary services.
   * @param snackbar Common snackbar service.
   * @param commonService Common service for decrypting param data.
   * @param language Language list service for fetching language data.
   * @param router Router for navigation.
   * @param route ActivatedRoute for accessing route parameters.
   * @param applicationList Application service for application operations.
   */
  constructor(
    private snackbar: CommonSnackbarService,
    private commonService: CommonService,
    private language: LanguageListService,
    private router: Router,
    private route: ActivatedRoute,
    private applicationList: ApplicationsService,
    private auth: AuthService,
    private clipboard: Clipboard
  ) {}

  /**
   * Angular lifecycle hook that runs after component initialization.
   * Fetches parameter data and initializes form with fetched data.
   */
  ngOnInit() {
    this.auth.region$.subscribe((region : string) => {
      this.region = region
    })
    this.getParamData();
    this.loading = true;
    this.subscriptionObject = this.combinedSubscription().subscribe((result: editApplication) => {
      this.setData(result);
      if (this.action === 'view') {
        this.applicationForm?.disable();
      }
    });
  }

  /**
   * Fetches and decrypts parameter data from the route.
   */
  getParamData() : void {
   this.route.paramMap.subscribe(params => {
      this.action = '' + params.get('action');
      this.applicationId = '' + this.commonService.getParam('' + params.get('data'));
    });
  }

  /**
   * Combines subscriptions to fetch application, language, and user data concurrently.
   * @returns {Observable<editApplication>}
   */
  combinedSubscription() : Observable<editApplication> {
    return forkJoin({
      appllication: this.getApplication(),
      languages: this.language.getLanguagesList({}),
      user: this.applicationList.getUserList({})
    });
  }

  /**
   * Assigns API data to local variables.
   * @param {editApplication} data - The fetched application data.
   */
  setData(data: editApplication): void {
    this.editorObjectHolder = data;
    this.applicationData = data.appllication || {};
    this.languageData = data.languages;
    this.userData = data.user;
    this.formInitialize();
  }

  /**
   * Fetches a single application data using application ID.
   * @returns {Observable<getSingleApplicationResponse>}
   */
  getApplication() : Observable<getSingleApplicationResponse> {
    if (this.action !== 'create') {
      return this.applicationList.getSingleApplications('' + this.applicationId ? '' + this.applicationId : '');
    }
    return of({});
  }

  /**
   * Initializes form controls with fetched application data.
   */
  formInitialize() : void {
    this.applicationForm = new FormGroup({
      userId: new FormControl(this.applicationData?.applicationData?.userId || null, Validators.required),
      region: new FormControl(this.applicationData?.applicationData?.region || this.region, Validators.required),
      applicationName: new FormControl(this.applicationData?.applicationData?.applicationName || null, Validators.required),
      description: new FormControl(this.applicationData?.applicationData?.description || null, Validators.required),
      appHostUrl: new FormControl(this.applicationData?.applicationData?.appHostUrl || null, Validators.required),
      appOrginUrl: new FormControl(this.applicationData?.applicationData?.appOrginUrl || null, Validators.required),
      languageArray: new FormControl(this.selectedLanguages() || null, Validators.required),
      removedLanguagesId : new FormControl(),
    });
    setTimeout(() => {
    this.loading = false;
    }, 500);
  }

  /**
   * Returns currently selected languages in the application.
   * @returns {number[]}
   */
  selectedLanguages() : string[] {
    return this.applicationData.applicationData?.languageArray.map(languageData => languageData.languageId) || [];
  }

  /**
   * Finds a user by ID and returns their full name.
   * @param {number} userId - The user ID.
   * @returns {string | null}
   */
  findUser(userId: number) : string | null {
    const user = this.userData.response.rows.find(user => user?.id === userId);
    return user ? user?.firstName + ' ' + user?.lastName : null;
  }

  findRemovedLanguages(){
    const removedLanguageData  = this.languageData.data.rows.filter( x => !this.applicationForm?.get('languageArray')?.value.includes(x.id)  ).map(x => x.id)
    return removedLanguageData;
  }

  /**
   * Creates a new application using form values.
   */
  createApplication() : void {
    this.applicationForm?.get('removedLanguagesId')?.setValue(this.findRemovedLanguages());
    if (this.applicationForm?.valid) {
      this.applicationList.createApplication(this.applicationForm?.value).subscribe({
        next: () => {
          this.applicationForm?.markAsPristine();
          this.snackbar.OpenSnackBar({ message: snackber_message.APPLICATION_CREATED, actionType: SNACKBAR_OPTIONS.success });
          this.router.navigate(['app/mail/application']);
        },
        error: (error) => {
          this.snackbar.OpenSnackBar({ message: snackber_message.APPLICATION_CREATION_FAILED, actionType: SNACKBAR_OPTIONS.warning });
          console.error(snackber_message.ERROR_CUSTOMER_CREATION, error);
        }
      });
    } else {
      this.snackbar.OpenSnackBar({ message: snackber_message.FILL_MANTATORY, actionType: SNACKBAR_OPTIONS.warning });
    }
  }

  /**
   * Updates an existing application using form values.
   */
  updateApplication() :void {
    this.applicationForm?.get('removedLanguagesId')?.setValue(this.findRemovedLanguages());
    if (this.applicationForm?.valid && this.applicationForm?.dirty) {
      this.applicationList.updateApplication(this.applicationId, this.applicationForm?.value).subscribe({
        next: () => {
          this.applicationForm?.markAsPristine();
          this.snackbar.OpenSnackBar({ message: snackber_message.APPLICATION_UPDATED, actionType: SNACKBAR_OPTIONS.success });
          this.router.navigate(['app/mail/application']);
        },
        error: (error) => {
          this.snackbar.OpenSnackBar({ message: snackber_message.UPDATE_FAILED, actionType:SNACKBAR_OPTIONS.warning});
          console.error(snackber_message.ERROR_CUSTOMER_CREATION, error);
        }
      });
    }
    else if(this.applicationForm && !this.applicationForm.dirty) {
      this.snackbar && this.snackbar.OpenSnackBar({
        message: SNACKBAR_MESSAGES.noChanges,
        heading: SNACKBAR_HEADING.warning,
        actionType: SNACKBAR_TYPE.warning,
      });
    }  
    else {
      this.snackbar.OpenSnackBar({ message: snackber_message.ERROR_CUSTOMER_UPDATING, actionType: SNACKBAR_OPTIONS.warning });
    }
  }

  /**
   * Cancels the form submission and navigates back to the application list.
   */
  cancel() : void{
    this.router.navigate(['app/mail/application']);
  }

  /**
   * Checks if the form can be deactivated, showing a confirmation dialog if the form is dirty.
   * @returns {boolean} - True if the form can be deactivated, false otherwise.
   */
  canDeactivate(): boolean {
    return this.applicationForm ? this.applicationForm.pristine : true;
  }
  copyTemplateName(templateName: any) {
    this.clipboard.copy(templateName);
    this.snackbar.OpenSnackBar({
      message: 'Successfully copied the Access ID',
      actionType: 'success',
      heading: 'Success',
      duration: 1
    });
}
}
