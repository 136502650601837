import { Component, OnDestroy, OnInit } from '@angular/core';
import { concatMap, of, Subscription, tap} from 'rxjs';
import { TemplateService } from 'src/app/template/service/template.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ActionClicked, DynamicDataSource, OptionalEvent, PaginationData, SearchSetting, TableAction, columnDef } from 'src/app/shared/models/common-card-data.model';
import { FilterData, SelectOptions } from 'src/app/shared/models/common-filter.model';
import { ColumnType, TEMPLATE_MESSAGES, } from 'src/app/shared/constants/common-card-data.constants';
import { Category, Customer, DialogMsgTypes, NullFilter, TemplateCategoryResponse, TemplateCustomerResponse, TemplateDialogMessages, TemplateFilterData, TemplateResponse, UpdateResponse, templateTableData} from 'src/app/template/models/template.model';
import { FilterType } from 'src/app/shared/constants/common-filter-data.contants';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { ButtonInfo, Heading } from 'src/app/shared/constants/common-header.constants';
import { TEMPLATES_MESSAGES } from 'src/app/template/constants/templates.constants';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-template-list',
  standalone: true,
  imports: [SharedModule, CommonModule],
  templateUrl: './template-list.component.html',
  styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit, OnDestroy {
  /**
* Flag Variable used to indicate whether enable filter or not.
* @type {boolean}
*/
  isFilter: boolean = false;
  /**
* Variable used to store the details for filter.
* @type {FilterData[]}
*/
  filterDetails!: FilterData[];
  /**
* Variable used to store search settings data.
* @type {SearchSetting}
*/
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'Template Name',
    appearance: 'outline'
  }
  /**
 * Variable used to store the searching text.
 * @type {string}
 */
  searchText: string = '';
  /**
* Variable used to store the table filter data.
* @type {TemplateFilterData}
*/
  filterValue!: TemplateFilterData;
  /**
   * Variable used to store the table filter data of optional event.
   * @type {Array<OptionalEvent>}
   */
  filterData: Array<OptionalEvent> = [
    { eventName: 'onFilter', icon: 'filter_list', toolTip: 'Filter', class: 'filter', color: 'white' }
  ]
  /**
* Variable used to store the isFilterValue.
* @type {boolean}
*/
  isFilterValue: boolean = false;
  /**
  * Variable used to store the Template Active status to perform filter.
  * @type {SelectOptions[]}
  */
  availabilityFilterOption = [{ name: "ActiveTemplate", id: "true" }, { name: "In ActiveTemplate", id: "false" }]
  /**
* Variable used to store the categories of the mail to perform filter.
* @type {SelectOptions[]}
*/
  filterCategory!: SelectOptions[];
  /**
  * Variable used to store the categories of the mail to perform filter.
  * @type {SelectOptions[]}
  */
  filterCustomer!: SelectOptions[];
  /**
  * Variable used to store the categories of the mail to perform filter.
  * @type {SelectOptions[]}
  */
  filterApplication: SelectOptions[] = [];
  /**
  * Variable used to store the application of the mail to perform primaty filter.
  * @type {FilterData[]}
  */
  primaryfilterDetails!: FilterData;
  /**
   * Flag Variable used to indicate whether enable loader or not.
   * @type {boolean}
   */
  templateListLoader: boolean = true;
  /**
* Variable used to store the table data.
* @type {templateTableData[]}
*/
  templateData!: templateTableData[];
  /**
 * Variable used to store the table data length.
 * @type {number}
 */
  templateListCount!: number;
  /**
* Variable used to store the observables.
* @type {Subscription}
*/
  SubscriptionObject: Subscription = new Subscription();
  /**
 * Variable used to store the table column definition.
 * @type {Array<columnDef>}
 */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox, columnWidth: '10%', columnAlign: 'start' },
    { field: 'templateName', header: 'Template Name', type: ColumnType.Text, columnWidth: '40%', removeUnderscore: true, columnAlign: 'start' },
    // { field: '', header: 'Sub Category', type: ColumnType.Text, columnWidth: '30%', removeUnderscore: true, columnAlign: 'center' },
    { field: 'availability', header: 'Availability', type: ColumnType.Toggle, columnWidth: '30%', toggleCondition: 'isActive', columnAlign: 'center' },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '20%', color: 'black', columnAlign: 'center' },
  ];
  enabledNotifyColums: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox, columnWidth: '10%', columnAlign: 'start' },
    { field: 'templateName', header: 'Template Name', type: ColumnType.Text, columnWidth: '40%', removeUnderscore: true, columnAlign: 'start' },
    { field: 'availability', header: 'Availability', type: ColumnType.Toggle, columnWidth: '30%', toggleCondition: 'isActiveCustomertemplate',disable: (item:any) => item?.isDefault ,columnAlign: 'center' },
    { field: 'enableNotification', header: 'Enable Notification', type: ColumnType.Toggle, columnWidth: '30%', toggleCondition:'isEnableNotification' , columnAlign: 'center' },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '20%', color: 'black', columnAlign: 'center' },
  ];
    /**
   * Variable used to store no data message.
   * @type {string}
   */
    noDataMessage: string = TEMPLATE_MESSAGES.NO_RECORD_TEMPLATE;
  /**
 * Variable used to store the table actions data.
 * @type {Array<TableAction>}
 */
  actions: Array<TableAction> = [
    { icon: 'visibility', tooltip: 'View Template', method: 'onView', color: '#0068b5' },
    { icon: 'edit', tooltip: 'Edit Template', method: 'onEdit', color: '#00b907' },
    { icon: 'delete', tooltip: 'Delete Template', method: 'onDelete', color: '#ff2929' }
  ];
  /**
* Variable used to store the table Pagination data.
* @type {PaginationData}
*/
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
  }
  /**
  * @type {TemplateDialogMessages}
  * Assigning the constant templateMessages to the template Messages variable.
  */
  templateMessages: TemplateDialogMessages = TEMPLATES_MESSAGES;
  isPrimary: boolean = false;
      /**
   * Variable has button data to be displayed on commen header.
   * @type {Heading}
   */
      button:ButtonInfo[] = [{
        class : 'primary-button',
        color : '#ffffff',    
        name : 'Create',
        method : 'onCreateTemplate',
        disabled : false,
        iconClass : 'outline',
        iconToolTip : 'btn'
      }]
    /**
     * Variable has header data to be displayed on commen header.
     * @type {Heading}
     */
    heading:Heading = {
      title : "Templates",
      description : ' Manage templates with options to create, edit, view, delete, and update availability.',
      fontWeight : '500'
    }
    /**
     * Variable subCategoryCode is used to store the subCategoryCode.
     * @type {Array}
     */
    subCategoryCode:string[]=[];
    customerId!:number|string;
    isEnabledNotification:boolean=false;
    upDatedData:any;
    isMarketing:boolean=false;
  /**
* component constructor which is used to inject the required services.
* @param templateService To refer to the templateService to access the mail Template functions.
* @param authService To refer to the AuthService to access the region subscription.
* @param dialogService to get the dialog information.
* @param route To refer to the navigate page.
*/
  constructor(private templateService: TemplateService,
    private authService: AuthService,
    private dialogService: CommonDialogService,
    private route: Router,
    private commonService: CommonService,
    private snackbar: CommonSnackbarService,
    private paramsRouter: ActivatedRoute) {
  }
    /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;
  
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number = -1;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;

  /**
 * Angular life cycle hook that initiates the component
 */
  ngOnInit(): void {
  this.initialLoader=true;
  const params= this.route.url;
  const pathSegments = params.split('/');
  const marketingSegment = pathSegments.find(segment => segment === 'marketing');
  if(marketingSegment){
    this.isMarketing=true;
  }
    this.authService.region$
    .subscribe((res) => {
      this.appliedFilters = -1;
      this.isFilter = false;
       if(res){
        this.SubscriptionObject=this.templateService.getTemplateApplication().subscribe({
          next:(templateApplicationResponse)=>{
            if(templateApplicationResponse){
            this.filterApplication = templateApplicationResponse.applicationData.rows.map((application) => ({
            id: application.id,
            name: application.applicationName
          }));
          if (this.filterApplication.length) {
            this.primaryfilterDetails = { title: 'Choose Application', type: FilterType.Select, options: this.filterApplication, field: 'applicationId', displayName: 'name', valueName: 'id' };
          }
          if(this.filterApplication.length>0){
            const order = [['templateName', 'ASC']];
            const initialTemplateData = {
              offset: 0,
              limit: 5,
              filterData: JSON.stringify(this.filterApplication.length===0?{ 'applicationId':0 }:{'applicationId':this.filterApplication[0]['id']}),
              order: JSON.stringify(order)
            };
            // this.templateListLoader = true;
            this.getAllTemplateList(initialTemplateData);
          }
          else{
            this.templateData = [];
            this.paginationData.count = 0;
            this.initialLoader=false;
            this.templateListLoader = false;
          }
          }
          },error:(err)=>{
                this.snackbar.OpenSnackBar({
                message: this.templateMessages?.failedApplication,
                heading: 'Failed',
                actionType: 'failure',
                duration: 2
              });
              this.templateListLoader = false;
          }
        })
       }
    })
  this.commonService.isFilterClicked.subscribe(res =>{
    this.isFilterClicked = res;
  })
  }
  /**
  * method which is used to fetch data from template list
  */
  getAllTemplateList(datas: any) {
    if (typeof datas.filterData === 'string') {
      datas.filterData = JSON.parse(datas?.filterData);
    }
    let fetchCategories$ = of(null);
    if (this.isMarketing) {
      const applicaionId = {
        filterData: JSON.stringify({ 'applicationId': datas.filterData['applicationId'] })
      };
      fetchCategories$ = this.templateService.getTemplateCategories(applicaionId).pipe(
        tap((CategoryResponse:any) => {
          if (CategoryResponse) {
            const categoryCode = CategoryResponse.category.rows
              .filter((categoryResponse:any) => categoryResponse.categoryCode === 'MARKETING')
              .map((categoryId:any) => categoryId);
            datas.filterData.categoryId = categoryCode?.[0]?.id;
          }
        })
      );
    } else {
      datas.filterData.subCategoryCode = this.subCategoryCode ?? [];
    }
    fetchCategories$
      .pipe(
        concatMap(() => {
          const data = {
            offset: datas.offset ?? 0,
            limit: datas.limit ?? 5,
            searchText: this.searchText ?? '',
            filterData: JSON.stringify(datas?.filterData) ?? '',
            order: datas.order ?? ''
          };
          return this.templateService.getAllTemplateList(data);
        })
      )
      .subscribe({
        next: (res: TemplateResponse) => {
          this.templateData = res.response.rows;
          this.templateListCount = res?.response?.count;
          this.paginationData.count = this.templateListCount;
          if (this.customerId !== 'default' && this.customerId !== undefined) {
            const customerTemplateMapping = this.templateData?.map((item: any) => ({...item,
              isEnableNotification: item?.customertemplatemapping?.isEnableNotification,
              isActiveCustomertemplate: item?.customertemplatemapping?.isActive,
              isDefault:item?.isActive
            }));
            this.templateData = customerTemplateMapping;
          }
          this.initialLoader = false;
          this.templateListLoader = false;
        },
        error: (err) => {
          this.templateData = [];
          this.paginationData.count = 0;
          this.initialLoader = false;
          this.templateListLoader = false;
          this.snackbar.OpenSnackBar({
            message: this.templateMessages?.failedTemplateList,
            heading: 'Failed',
            actionType: 'failure',
            duration: 2
          });
        }
      });
  }
  
  /**
 * Method which is used to emit value while using filter.
 * @param event It has the emitted value from the filter.
 */
  filterEmittedEvent(event: TemplateFilterData) {
    this.customerId=event?.customerId??'default';
    if(this.customerId!=='default'){
      this.isEnabledNotification=true;
    }
    else{
      this.isEnabledNotification=false;
    }
    if (event) {
      this.filterValue = { ...this.filterValue, ...event };
      let data;
      if (this.searchText !== '') {
        data = {
          offset: 0,
          limit: this.paginationData.limit,
          searchText: this.searchText ?? '',
          filterData: JSON.stringify(this.filterValue),
          order: JSON.stringify([['templateName', 'ASC']])
        }
        this.templateListLoader=true;
        this.getAllTemplateList(data)
      }
      else {
        data = {
          offset: 0,
          limit: this.paginationData.limit,
          filterData: JSON.stringify(this.filterValue),
          order: JSON.stringify([['templateName', 'ASC']])
        }
        this.templateListLoader=true;
        this.getAllTemplateList(data)
      }
    }
    else {
      const data = {
        offset: 0,
        limit: this.paginationData.limit,
        searchText: '',
        filterData: JSON.stringify(this.filterValue) ?? '',
        order: JSON.stringify([['templateName', 'ASC']])
      }
      this.templateListLoader=true;
      this.getAllTemplateList(data);
    }
    this.paginationData = {
      ...this.paginationData,
      offset: 0
    };
  }
  
  /**
   * Method used to assign total applied filters as number.
   * @param { number } totalAppliedFilters 
   */
  appliedFiltersLength(totalAppliedFilters: number): void {
    this.appliedFilters = totalAppliedFilters;
  }

  /**
* Method which is used for table Pagination.
* @param event It has the Pagination value which is emitted.
*/
  handlePaginationChange(event: PaginationData): void {
    const data = {
      offset: event.offset,
      limit: event.limit,
      searchText: this.searchText ?? '',
      filterData: JSON.stringify(this.filterValue) ?? '',
      order: JSON.stringify([['templateName', 'ASC']])
    }
    this.paginationData.limit = event.limit;
    this.paginationData.count = event.count;
    this.paginationData.offset = event.offset;
    this.templateListLoader=true;
    this.getAllTemplateList(data);
  }
  /**
 * Method which is used for searching the table.
 * @param event It has the searched string which is emitted.
 */
  handleSearchChange(event: string): void {
    this.searchText = event;
    let data;
    if (event && event?.length) {
      data = {
        offset: 0,
        limit: this.paginationData.limit,
        searchText: event,
        filterData: this.filterValue ? JSON.stringify(this.filterValue) : '',
        order: JSON.stringify([['templateName', 'ASC']])
      }
      this.templateListLoader=true;
      this.getAllTemplateList(data);
    }
    else {
      data = {
        searchText: '',
        offset: 0,
        limit: this.paginationData.limit,
        filterData: this.filterValue ? JSON.stringify(this.filterValue) : '',
        order: JSON.stringify([['templateName', 'ASC']])
      }
      this.templateListLoader = true;
      this.getAllTemplateList(data);
    }
  }
  /**
 * Method which is used to perform filtering in the table.
  * @param event It has the event of filter in the table.
  */
  handleOptionalEvent(event: OptionalEvent): void {
    if (event && event.eventName === 'onFilter' && this.isFilterClicked) {
      this.onFilter();
    }
  }
  /**
  * Method which is used to assign the filter data to the table.
  */
  onFilter(): void {
    if(this.isMarketing){
      this.filterDetails = [
        { title: 'Customers', type: FilterType.Select, options: this.filterCustomer, field: 'customerId', displayName: 'name', valueName: 'id' },
        { title: 'Availability', type: FilterType.Select, options: this.availabilityFilterOption, field: 'isActive', displayName: 'name', valueName: 'id' },
        { title: 'Created Date', type: FilterType.Date, field: 'customDateRange', displayName: 'name', valueName: '', isMaxDateToday: true, error: false, format: "yyyy-MM-dd" }
      ]
    } else{
      this.filterDetails = [
        { title: 'Customers', type: FilterType.Select, options: this.filterCustomer, field: 'customerId', displayName: 'name', valueName: 'id' },
        { title: 'Categories', type: FilterType.Select, options: this.filterCategory, field: 'categoryId', displayName: 'name', valueName: 'id' },
        { title: 'Availability', type: FilterType.Select, options: this.availabilityFilterOption, field: 'isActive', displayName: 'name', valueName: 'id' },
        { title: 'Created Date', type: FilterType.Date, field: 'customDateRange', displayName: 'name', valueName: '', isMaxDateToday: true, error: false, format: "yyyy-MM-dd" }
      ]
    }
  
    if (this.templateData?.length) {
      this.isFilterValue = true;
      this.isFilter= true;
    }
  }
  /**
  * Method which is used to close the filter.
  */
  closeFilter(applicationIdFilter?: number) {
    this.isEnabledNotification=false;
    this.customerId='default';
    this.isFilterValue = false
    setTimeout(() => {
      this.isFilter = false
    }, 300);
    const applicationId = this.filterValue.applicationId;
    if (applicationIdFilter) {
      this.filterValue = { 'applicationId': applicationIdFilter }
    }
    else {
      this.filterValue = {};
      this.filterValue = { 'applicationId': applicationId }
    }
    const data = {
      offset: 0,
      limit: this.paginationData.limit,
      filterData: JSON.stringify(this.filterValue)
    }
    this.getAllTemplateList(data)
  }
  /**
* Method which is used to Active and inactive status for templates.
*/
  onToggleChanged(event: any) {
    if(this.customerId!=='default' && this.customerId!==undefined){
      this.upDatedData = {
        customerId: this.customerId,
        isActive: event?.toggleCondition==='isActiveCustomertemplate'? !event?.customertemplatemapping?.isActive : event?.customertemplatemapping?.isActive,
        isEnableNotification: event?.toggleCondition==='isEnableNotification' ? !event?.customertemplatemapping?.isEnableNotification : event?.customertemplatemapping?.isEnableNotification,
        lanCode: event?.templatelocalizations?.lanCode
      };
  } else{
    this.upDatedData = {
      customerId:this.customerId===undefined?'default':this.customerId,
      isActive: event?.toggleCondition==='isActive' ? !event.isActive : event.isActive,
      lanCode: event?.templatelocalizations?.lanCode
    }
  }
    const msgType = {
      header: 'Confirmation',
      message:(event?.toggleCondition==='isActive' || event?.toggleCondition==='isActiveCustomertemplate') && this.upDatedData.isActive ? this.templateMessages?.templateEnabled 
      :(event?.toggleCondition==='isActive' || event?.toggleCondition==='isActiveCustomertemplate') && !this.upDatedData.isActive? this.templateMessages?.templateDisabled
      :event?.toggleCondition==='isEnableNotification' && this.upDatedData.isEnableNotification?this.templateMessages?.notificationEnabled:this.templateMessages?.notificationDisabled,
      actionType: 'confirmation',
      button: { right:(event?.toggleCondition==='isActive' || event?.toggleCondition==='isActiveCustomertemplate') && this.upDatedData.isActive ? 'Yes, Enable'
        :(event?.toggleCondition==='isActive' || event?.toggleCondition==='isActiveCustomertemplate') && !this.upDatedData.isActive?'Yes, Disable'
        :event?.toggleCondition==='isEnableNotification' && this.upDatedData.isEnableNotification?'Yes, Enable':'Yes, Disable', left: 'No' },
    }
    this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.templateService.updateTemplate(event?.id, this.upDatedData).subscribe({
          next: (res: UpdateResponse) => {
            const msgType = {
              message: res.success ? this.upDatedData.isActive ? this.templateMessages?.templateEnableUpdatedSuccessfull : this.templateMessages?.templateDisableUpdatedSuccessfull : this.templateMessages?.templateUpdatedFailed,
              actionType: res.success ? 'success' : 'failure',
              button: { right: 'Okay' }
            }
            this.dialogMsg(msgType).subscribe((dialogResult:boolean)=>{
              if(dialogResult){
                // this.initialLoader=true;
                this.templateListLoader = true;
               const data = {
                  offset: 0,
                  limit: 5,
                  searchText: this.searchText ?? '',
                  filterData: JSON.stringify(this.filterValue) ?? '',
                  order: JSON.stringify([['templateName', 'ASC']])
                }
                this.getAllTemplateList(data)
              }
            })
          }, error: (error: Error) => {
            this.snackbar.OpenSnackBar({
              message: 'Something Went Wrong In Upadate the Toggle',
              heading: 'Failed',
              actionType: 'failure',
              duration: 2
            });
            this.templateListLoader = false;
          }
        })
      }
      else {
        this.templateListLoader = false;
      }
    })
  }
  /**
  * Method which is used to view/edit/delete methods for templates.
  */
  handleActionClicked(event: ActionClicked<DynamicDataSource>) {
    if (event.method === 'onDelete') {
      const data = {
        id: event?.data['id']
      };
      const msgType = {
        message: this.templateMessages?.templateDeleteMsg,
        actionType: 'verification',
        button: { left: 'No', right: 'Yes, Delete' }
      }
      this.dialogMsg(msgType).subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.templateService.deleteTemplate(data.id,'' + dialogResult).subscribe({
            next: (res:any) => {
              this.snackbar.OpenSnackBar({
                message: res?.success? this.templateMessages?.templateDeletedSuccessfull: this.templateMessages?.templateDeletedFailed,
                heading: res?.success?'Success':'Failed',
                actionType:res?.success?'success':'failure',
                duration: 2
              });
              this.templateListLoader = true;
              const data = {
                offset: 0,
                limit: 5,
                searchText: this.searchText ?? '',
                filterData: JSON.stringify(this.filterValue),
                order: JSON.stringify([['templateName', 'ASC']])
              }
              this.getAllTemplateList(data)
            },
            error: (error) => {
              this.snackbar.OpenSnackBar({
                message: this.templateMessages?.wrongDeleteTemplate,
                heading: 'Failed',
                actionType: 'failure',
                duration: 2
              });
              this.templateListLoader = false;
              const data = {
                offset: 0,
                limit: 5,
                searchText: this.searchText ?? '',
                filterData: JSON.stringify(this.filterValue),
                order: JSON.stringify([['templateName', 'ASC']])
              }
              this.getAllTemplateList(data)
            }
          });
        }
      });
    }
    if (event.method === 'onView' || event.method === 'onEdit') {
      const templateId = event?.data['id'];
      const customerId=this.customerId??'default';
      this.route?.navigate(['app/mail/addedittemplate', event.method === 'onView' ? 'view' : 'edit', this.commonService.setParamsObj(templateId),customerId]);
    }
  }
  /**
 * Method which is used to show dialogs based on Action.
 */
  dialogMsg(msgType: DialogMsgTypes) {
    return this.dialogService.openDialog({
      header: msgType.header ?? '',
      message: msgType.message,
      actionType: msgType.actionType,
      button: { right: msgType.button?.right ?? '', left: msgType.button?.left ?? '' },
      disableClose: true
    });
  }
  /**
* This method removes any null or undefined objects in the filterValue.
* @param { NullFilter } filterValue 
* @returns { NullFilter }
*/
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue)
      .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
      .reduce((acc: NullFilter, key: string) => {
        if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
          const cleanedValue = this.removeNullValues(filterValue[key]);
          if (Object.keys(cleanedValue).length > 0) {
            acc[key] = cleanedValue;
          }
        } else {
          acc[key] = filterValue[key];
        }
        return acc;
      }, {});
  }
  /**
* Method which is used to show primaryDropdownEvent.
*/
  primaryDropdownEvent(applicaionId: SelectOptions) {
    this.filterValue = this.removeNullValues(Object.keys(applicaionId)?.length === 0 && applicaionId?.constructor === Object?{'applicationId':0}:applicaionId);
    if (this.isFilter === true) {
      this.closeFilter(this.filterValue.applicationId)
    }
    this.getAllTemplateCustomerAndCategory(this.filterValue);
  }
/**
* Method which is used to call customer and category
*/
  getAllTemplateCustomerAndCategory(filterValue:any){
    this.templateService.getTemplateCustomer({filterData: JSON.stringify(filterValue) }).subscribe({
      next: (templateCustomerResponse: TemplateCustomerResponse) => {
        this.filterCustomer = templateCustomerResponse.customer.rows.map((customer: Customer) => ({
          id: customer.customerId,
          name: customer.customerName
        }));
        this.filterCustomer.splice(0, 0, { id: 'default', name: 'Default' });
         },error:(err)=>{
            this.snackbar.OpenSnackBar({
            message: this.templateMessages?.failedCustomer,
            heading: 'Failed',
            actionType: 'failure',
            duration: 2
             });
           this.templateListLoader = false;
      }
    })
    const applicaionId = {
      filterData: JSON.stringify(filterValue)
    }
    this.templateService.getTemplateCategories(applicaionId).subscribe({
      next: (templateCategoryResponse:TemplateCategoryResponse) => {
        this.filterCategory = templateCategoryResponse.category.rows.map((category: Category) => ({
          id: category.id,
          name: category.categoryName
        }));
      },error:(err)=>{
          this.snackbar.OpenSnackBar({
          message: this.templateMessages?.failedCategory,
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
          });
          this.templateListLoader = false;
      }
    })
    this.SubscriptionObject=this.templateService.getAllSubcategories().subscribe({
      next:(subCategoryResponse:any)=>{
        if(subCategoryResponse){
        this.subCategoryCode=subCategoryResponse?.data?.map((subCategory:any)=>subCategory?.subCategoryCode)
        // this.subCategoryCode=[]
        }
      const data = {
      offset: 0,
      limit: 5,
      searchText: '',
      filterData: JSON.stringify(filterValue),
      order : JSON.stringify([['templateName', 'ASC']])
      }
      this.templateListLoader=true;
      this.getAllTemplateList(data);
      },error:(err)=>{
        this.snackbar.OpenSnackBar({
          message: this.templateMessages?.subCategoryApiWrong,
          heading: 'Failed',
          actionType: 'failure',
          duration: 2
        });
        this.templateListLoader = false;
        this.subCategoryCode=[];
        const data = {
          offset: 0,
          limit: 5,
          searchText: '',
          filterData: JSON.stringify(filterValue),
          order : JSON.stringify([['templateName', 'ASC']])
          }
         this.getAllTemplateList(data);
      }
    })
  }
  /**
    * Method which is used to create new templates
    */
  onCreateTemplate() {
    this.route.navigate(['app/mail/addedittemplate' , 'create']);
  }
  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }
  /**
  * Angular life cycle hook ngOnDestroy is used to unsubscribe the subscribtion.
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}
