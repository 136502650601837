import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersService } from '../../services/customers.service';
import { Subscription } from 'rxjs';
import { getUserListResponse } from 'src/app/zen-mail/Applications/models/applications.model';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AddEditViewCustomer, CUSTOMER_SNACKBAR_MESSAGES } from '../../constants/customers-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { Applications, GetApplicationData, GetUserListResponse, SingleCustomer, SingleCustomerData } from '../../models/customers.model';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { SNACKBAR_HEADING, SNACKBAR_MESSAGES, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { dynamicTitle } from 'src/app/shared/models/common-card-data.model';

@Component({
  selector: 'app-view-customers',
  templateUrl: './add-edit-customers.component.html',
  styleUrls: ['./add-edit-customers.component.scss']
})
export class AddEditCustomers {

  /**
   * Type of action (create, view, edit).
   * @type {string}
   */
  action!: string;

  /**
  * Declaring form group
  * @type { FormGroup }
  */
  customersForm?: FormGroup;

  /**
   * To store the selected id.
   * @type { string }
   */
  routeId: string = '';

  /**
   * Variable used to store the single customer data
   * @type { SingleCustomerData }
   */
  viewCustomerDetails?: SingleCustomerData;
  
  /**
   * Variable used to detect the current page is edit customer page.
   * @type { boolean } 
   */
  editMode: boolean = false;
  
  /**
   * Variable used to detect the current page is view customer page.
   * @type { boolean }
   */
  viewMode: boolean = false;
  
  /**
   * Variable used to detect the current page is create customer page.
   */
  createMode: boolean = false;

  /**
   * Variable that holds validators for the form.
   */
  addEditViewCustomer = new AddEditViewCustomer();
  
  /**
   * @type {boolean}
   * Flag indicating whether data is loading.
   */
  loading: boolean = false;

  /**
   * Variable used to store the observables.
   * @type {Subscription}
   */
  SubscriptionObject: Subscription = new Subscription();

  /**
   * Variable used to store the all application data
   * @type { GetApplicationList }
   */
  applicationData!: Applications[];

  /**
   * Variable used to store the user data.
   * @type { getUserListResponse }
   */
  userData!: getUserListResponse;

  // /**
  //  * Variable used to store the region.
  //  * @type { string }
  //  */
  // region?: string;

  /**
   * Variable used to set placeholder for applicationId formaControl.
   * @type { string }
   */
  applicationPlaceHolder: string = 'No Data Available'

  /**
   * Variable used to store the userId when selected from mat-select
   * @type { number }
   */
  userIdParam?: number;

  /**
  * Variable isActivated is used to store status of template.
  * @type {boolean}
  */
  isActivated:boolean = true;

  /**
  * Variable isView is used to store the page is view mode or not.
  * @type {boolean}
  */
  isView: boolean = false;

  /**
   * Holds all available title according to page functionality.
   */
  dynamicTitle : dynamicTitle = {
    edit :{title : "Edit Customer"},
    create : {title : "Create Customer"},
    view : {title : "View Customer"}
  }
  
  /**
    * component constructor which is used to inject the required services.
    * @param route - Service to handle route-related functionalities and access route parameters.
    * @param customerService - Service to handle HTTP requests related to customers.
    * @param router - Service to handle navigation and routing.
    * @param commonService To refer to the CommonService to set the id as encrypted.
    */
  constructor(private commonService: CommonService, private route: ActivatedRoute, private customerService: CustomersService, 
    private router: Router, private auth: AuthService, private snackbar: CommonSnackbarService) {}

  /**
    * Sets the loader to true for the initial loading and makes the necessary API calls for the customer list page.
    * Angular life cycle hook.
    * @returns {void}
    */
  ngOnInit(): void {  
    this.loading = true;
    this.decryptRouteId();
    this.SubscriptionObject = this.detectPageMode();
    if(this.createMode) {
      this.SubscriptionObject = this.auth && this.auth.region$ && this.auth.region$.subscribe(region => {
        this.onRegionChange();
      });
      // this.region = this.auth.region$.value;
      this.SubscriptionObject = this.getUserList();
    }
    else {
      this.SubscriptionObject = this.getSingleCustomer();
    }
  }

  /**
   * Method to decrypt the route parameter 'data' and assign it to routeId if not in create mode.
   * Subscribes to the route's paramMap and uses the CommonService to decrypt the 'data' parameter.
   */
  decryptRouteId(): void {
    if(!this.createMode) 
      this.route && this.route.paramMap && this.route.paramMap.subscribe(params => {
        this.routeId! = '' + this.commonService.getParam(''+params.get('data')) ;
      });  
  }

  /**
   * Method to detect the current page mode (edit, view, create) based on the URL segments.
   * Subscribes to the route's URL and sets the mode flags accordingly.
   * @returns { Subscription } Subscription to the route's URL observable.
   */
  detectPageMode(): Subscription {
    this.route.paramMap.subscribe(params => {
      this.action = '' + params.get('action');
    });
    return this.route && this.route.url && this.route.url && this.route.url.subscribe(urlSegments => {
      if(urlSegments) {
        this.editMode = urlSegments.some(segment => segment.path === 'edit');
        this.viewMode = urlSegments.some(segment => segment.path === 'view');
        this.createMode = urlSegments.some(segment => segment.path === 'create');
      }
    });
  }

  /**
   * Fetches the user list from the customer service and initializes the form with the fetched data.
   * @returns {Subscription} The subscription object for the HTTP request.
   */
  getUserList(): Subscription {
    return this.customerService && this.customerService.getUserList({}) && this.customerService.getUserList({}).subscribe({
      next: (res: GetUserListResponse) => {
        this.userData = res;        
        // this.loading = false;
        this.formInitialize();
      },
      error: (err: string) => {
        console.error('Error fetching user details', err);
        this.loading = false;
        this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerUserFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
      }
    })
  }

  /**
   * Fetches the details of a single customer based on the route ID.
   * @returns {Subscription} The subscription object for the HTTP request.
   */
  getSingleCustomer(): Subscription {
    return this.customerService && this.customerService.getSingleCustomer(this.routeId) && this.customerService.getSingleCustomer(this.routeId).subscribe({
      next: (res: SingleCustomer) => {
      // this.loading = false;
      if(res) {
        this.viewCustomerDetails = res.customer
        this.isActivated = (this.viewCustomerDetails && this.viewCustomerDetails.isActive) ?? false;
        this.formInitialize();
        if(this.viewMode) {
          this.formDisable();
        }
        else {
          this.customersForm && this.customersForm.get('customerId')?.disable();
        }
      }
      },
      error: (err: string) => {
        console.error('Error fetching customer details', err);
        this.loading = false;
        this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
      }
    })
  }

  /**
   * This method used to initialize the formControls for the formGroup named "customersForm".
   */
  formInitialize(): void {
    this.customersForm = new FormGroup({
      customerName: new FormControl(this.viewCustomerDetails && this.viewCustomerDetails.customerName ? this.viewCustomerDetails.customerName : null, 
        [Validators.required]),
      phoneNumber: new FormControl(this.viewCustomerDetails && this.viewCustomerDetails.phoneNumber ? this.viewCustomerDetails.phoneNumber : null,
        [Validators.required, Validators.pattern(/^\+[1-9]\d{0,3} \d{7,14}$/)]),
      emailId: new FormControl(this.viewCustomerDetails && this.viewCustomerDetails.emailId ? this.viewCustomerDetails.emailId : null,
        [Validators.required, Validators.email]),
      customerId: new FormControl(this.viewCustomerDetails && this.viewCustomerDetails.customerId ? this.viewCustomerDetails.customerId : null,
        [Validators.required, Validators.pattern(/^(-1|[0-9]\d*)$/)])
    })
    this.addFormControls();
    if(this.loading){
      setTimeout(() => {
        this.loading=false;
      }, 500);
    }
  }

  /**
   * This method used to create new form control based on the page mode.
   */
  addFormControls(): void {
    if(!this.createMode && this.customersForm) {
      this.customersForm.addControl('isActive', new FormControl(this.viewCustomerDetails && this.viewCustomerDetails.isActive ? true : false,
        [Validators.required]));
    }
    else if(this.customersForm) {
      this.customersForm.addControl('applicationId', new FormControl(null, [Validators.required]));
      this.customersForm.addControl('userId', new FormControl(null, [Validators.required]));
    }
  }

  /**
  * formDisable() method which is used to disables the customersForm FormGroup.
  */ 
  formDisable() : void {
    this.customersForm && this.customersForm.disable();
  } 

  /**
   * This method used to navigate to customers list page when cancel button is clicked
   */
  onCancelClick(): void {
    this.router && this.router.navigate(['app/mail/customers']);
  }

  /**
   * This method used to navigate to logs list page when View Logs button is clicked
   */
  onViewLogClick(): void {
    this.router && this.router.navigate(['/app/mail/logs']);
  }

  /**
   * This method used to navigate to templates list page when View Templates button is clicked
   */
  onViewTemplatesClick(): void {
    this.router && this.router.navigate(['/app/mail/templates'])
  }

  /**
   * Used to update the form when the user clicks the update button.
   * On successful updation, it navigates the user to the customers list page.
   */
  onUpdate(): void {
    if(this.customersForm && this.customersForm.valid && this.customersForm.dirty){
      this.loading = true;
      const param = this.customersForm && this.customersForm.getRawValue();
      // param.isActive = this.isActivated;
      this.customerService && this.customerService.updateCustomer(param, param?.customerId).subscribe({
      next: (response: SingleCustomerData) => {
        // if(response) {
          this.loading = false;
          this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerUpdateSuccess, 
            heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
          this.afterUserInfoResponse();
          this.router && this.router.navigate(['/app/mail/customers']);
        // }
      },
      error: (error: any) => {
        this.loading = false;
        console.error("Error creating customer", error)
        this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerUpdateFailed, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        }
      })
    } 
    else if(this.customersForm && !this.customersForm.dirty) {
      this.snackbar && this.snackbar.OpenSnackBar({
        message: SNACKBAR_MESSAGES.noChanges,
        heading: SNACKBAR_HEADING.warning,
        actionType: SNACKBAR_TYPE.warning,
      });
    } 
    else {
      this.snackbar && this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField,
        heading: SNACKBAR_HEADING.warning, actionType : SNACKBAR_TYPE.warning });
    }
  }

  /**
   * Used to submit the form when the user clicks the submit button.
   * On successful creation, it navigates the user to the customers list page.
   */
  onSubmit(): void {
    if(this.customersForm && this.customersForm.valid){
      this.loading = true;
      const param = this.customersForm && this.customersForm.getRawValue();
      param.isActive = this.isActivated;
      param.customerId = isNaN(Number(param.customerId)) ? null : Number(param.customerId);
      this.customerService && this.customerService.createCustomer(this.customersForm && this.customersForm.value).subscribe({
        next: () => {
          this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerCreatedSuccess, 
            heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
          this.loading = false;
          this.afterUserInfoResponse();
          this.router && this.router.navigate(['/app/mail/customers']);
        },
        error: (error: any) => {
          this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerCreatedFailed, 
            heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
          this.loading = false;
          console.error("Error creating customer", error);
        }
      })
    } else {
      this.snackbar && this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField,
        heading: SNACKBAR_HEADING.warning, actionType : SNACKBAR_TYPE.warning });
    }
  }

  /**
  * Method afterUserInfoResponse used to make the form as pristine when clicked on the submit or update button.
  */
  afterUserInfoResponse(): void {
    this.customersForm && this.customersForm.markAsPristine();
    this.loading = false
  }
  /**
   * This method used to emit all application data based on the user selection
   * @param { MatOptionSelectionChange } event 
   */
  onUserChange(event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.userIdParam = event && event && event.source && event.source.value;
      this.customersForm && this.customersForm.get('applicationId')?.setValue('');
      this.getApplicationList({userId: this.userIdParam});
    }
  }

  /**
   * This method used to emit all application data whenever the region changes.
   * @param { MatOptionSelectionChange } event 
   */
  onRegionChange(): void {
    // if (event.isUserInput) {
      // this.auth.region$.next(event.source.value);
      this.customersForm && this.customersForm.get('applicationId')?.setValue('');
      this.userIdParam ? this.getApplicationList({userId: this.userIdParam}) : "";
    // }
  }

  /**
   * Fetches the list of applications by calling the customer service's getApplicationList method.
   * @param {any} [param] - Optional parameters to filter.
   * @returns {Subscription} - The subscription to the application list request.
   */
  getApplicationList(param?: any): void {
    this.SubscriptionObject =  this.customerService && this.customerService.getApplicationList(param).subscribe({
      next: (res: GetApplicationData) => {
        if(res) {
          this.applicationData = res && res.applicationData && res.applicationData.rows;
          this.applicationIdPlaceholder();
        }
      },
      error: (err: string) => {
        console.error('Error fetching application details', err);
        this.snackbar && this.snackbar.OpenSnackBar({ message : CUSTOMER_SNACKBAR_MESSAGES.customerApplicationFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
      }
    })
  }

  /**
   * Sets the applicationId placeholder based on the number of applications.
   * If there are no applications, the placeholder will be "No Data Available".
   * If applications are present, the placeholder will be "Application Name".
   */
  applicationIdPlaceholder(): void {
    const applicationLength = this.applicationData && this.applicationData.length
    if(applicationLength > 0) {
        this.applicationPlaceHolder = "Select Application Name";
      }
      else {
        this.applicationPlaceHolder = "No Data Available";
      }
  }

  /**
  * Method which is used to update the isActive.
  * @type { boolean }
  */
  toggleClicked(event: boolean): void {
    const newValue = !event;
    this.isActivated = newValue;
    if (this.customersForm && !this.customersForm.dirty) {
      this.customersForm?.markAsDirty();
    }
    this.customersForm?.get('isActive')?.setValue(this.isActivated);
  }

  /**
    * Method used to call the canDeactivate guard when the form is not pristine.
    * @returns { boolean } dialog box
  */
  canDeactivate(): boolean {
    return this.customersForm ? this.customersForm.pristine : true;
  }

  ngOnDestroy() {
    this.SubscriptionObject.unsubscribe();
  }
}
