import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { AddEditViewCustomer } from 'src/app/customers/constants/customers-constant';
import { GetUserListResponse, GetApplicationData, Applications, UserListData } from 'src/app/customers/models/customers.model';
import { CustomersService } from 'src/app/customers/services/customers.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfigurationService } from '../../services/configuration.service';
import { CategoriesService } from 'src/app/zen-mail/Categories/services/categories.service';
import { CONFIGURATION_SNACKBAR_MESSAGES, ConfigurationValidaorConstants } from '../../constants/configuration.constant';
import { EditableFormControls, RequiredFormControls, ServerOptionData, SingleConfigurationData, SingleConfigurationResponse, UpdateConfigurationData, UpdateResponse } from '../../models/configuration.model';
import { Category } from 'src/app/zen-mail/Categories/models/categories.model';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { SNACKBAR_HEADING, SNACKBAR_MESSAGES, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { dynamicTitle } from 'src/app/shared/models/common-card-data.model';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-add-edit-configuration',
  templateUrl: './add-edit-configuration.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./add-edit-configuration.component.scss']
})
export class AddEditConfigurationComponent {
  /**
   * Contains constants for validators
   */
  configurationValidators = new ConfigurationValidaorConstants();

  /**
  * Declaring form group
  * @type { FormGroup }
  */
  configurationForm?: FormGroup;

  /**
   * To store the selected id.
   * @type { string }
   */
  routeId: string = '';

  /**
   * Variable used to store the single configuration data
   * @type { SingleConfigurationData }
   */
  configurationDetail?: SingleConfigurationData;
  
  /**
   * Variable used to detect the current page is edit configuration page.
   * @type { boolean } 
   */
  editMode: boolean = false;
  
  /**
   * Variable used to detect the current page is create configuration page.
   */
  createMode: boolean = false;

  /**
   * Variable that holds validators for the form.
   */
  addEditViewCustomer = new AddEditViewCustomer();
  
  /**
   * @type {boolean}
   * Flag indicating whether data is loading.
   */
  loading: boolean = false;

  /**
   * Variable used to store the observables.
   * @type {Subscription}
   */
  SubscriptionObject: Subscription = new Subscription();

  /**
   * Variable used to store the observables.
   * @type {Subscription}
   */
  authSubscriptionObject: Subscription = new Subscription();

  /**
   * Variable used to store the all application data
   * @type { GetApplicationList }
   */
  applicationData!: Applications[];

  /**
   * Variable used to store the user data.
   * @type { userListData }
   */
  userData!: UserListData[];

  /**
   * Variable used to get total number of applications.
   * @type { number }
   */
  applicationDataLength: number = 0;

  /**
   * Variable used to set placeholder for applicationId formaControl.
   * @type { string }
   */
  applicationPlaceHolder: string = 'No Data Available'

  /**
   * Variable used to set placeholder for applicationId formaControl.
   * @type { string }
   */
  categoryPlaceHolder: string = 'No Data Available'

  /**
   * Variable used to store categories data.
   * @type { Category[] }
   */
  categoryData?: Category[]

  /**
   * Variable used to store the userId when selected from mat-select
   * @type { number }
   */
  userIdParam?: number;

  /**
   * Variable used to set options and value for server formcontrol.
   * @type { ServerOptionData[] }
   */
  serverOptionData: ServerOptionData[] = [{option: "Gmail", value: "gmail"}, {option: "Ionos", value: "ionos"}]

  /**
   * Holds all available title according to page functionality.
   */
  dynamicTitle : dynamicTitle = {
    edit :{title : "Edit Configuration"},
    create : {title : "Create Configuration"},
    view : {title : "View Configuration"}
  }
  /**
   * Type of action (create, view, edit).
   * @type {string}
   */
  action!: string;

  /**
    * component constructor which is used to inject the required services.
    * @param route - Service to handle route-related functionalities and access route parameters.
    * @param customerService - Service to handle HTTP requests related to customers.
    * @param configurationService - Service to handle HTTP requests related to configuration.
    * @param categoryService - Service to handle HTTP requests related to category.
    * @param router - Service to handle navigation and routing.
    * @param auth To refer to the AuthService to access the region subscription.
    * @param commonService To refer to the CommonService to set the id as encrypted.
    * @param snackbar Used to access common snackbar service. 
    */
  constructor(private commonService: CommonService, private route: ActivatedRoute, private customerService: CustomersService, 
      private router: Router, private auth: AuthService, private configurationService: ConfigurationService, 
      private paramsRouter: ActivatedRoute,
      private categoryService: CategoriesService, private snackbar: CommonSnackbarService) {}

  /**
    * Sets the loader to true for the initial loading and makes the necessary API calls for the configuration list page.
    * Angular life cycle hook.
    * @returns {void}
    */
  ngOnInit(): void {  
    this.decryptRouteId();
    this.SubscriptionObject = this.detectPageMode();
    if(this.editMode) { 
      this.loading = true;
      this.SubscriptionObject = this.getSingleConfiguration();
    }
    else {
      this.formInitialize();
      this.authSubscriptionObject = this.auth && this.auth.region$ && this.auth.region$.subscribe(() => {
        this.onRegionChange();
      });
      this.SubscriptionObject = this.getAllUser();
      this.SubscriptionObject = this.getAllCategory();
    }
  }

  /**
   * Method to decrypt the route parameter 'data' and assign it to routeId if not in create mode.
   * Subscribes to the route's paramMap and uses the CommonService to decrypt the 'data' parameter.
   */
  decryptRouteId(): void {
    if(!this.createMode) 
      this.route && this.route.paramMap && this.route.paramMap.subscribe(params => {
        this.routeId! = '' + (this.commonService && this.commonService.getParam(''+params.get('data')));
      });  
  }

  /**
   * Method to detect the current page mode (edit, view, create) based on the URL segments.
   * Subscribes to the route's URL and sets the mode flags accordingly.
   * @returns { Subscription } Subscription to the route's URL observable.
   */
  detectPageMode(): Subscription {
    this.paramsRouter.params.subscribe((params) => {
      if (params['action']) {
        this.action = params['action']
      }
    })
    return this.route && this.route.url && this.route.url.subscribe(urlSegments => {
      this.editMode = urlSegments && urlSegments.some(segment => segment.path === 'edit');
      this.createMode = urlSegments && urlSegments.some(segment => segment.path === 'create');
    });
  }

  /**
   * Fetches the user list from the customer service and initializes the form with the fetched data.
   * @returns {Subscription} The subscription object for the HTTP request.
   */
  getAllUser(): Subscription {
    return this.customerService && this.customerService.getUserList({}).subscribe({
      next: (res: GetUserListResponse) => {
        this.userData = res && res.response && res.response.rows;        
        this.loading = false;
      },
      error: (err: string) => {
        console.error('Error fetching user details', err);
        this.loading = false;
        this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationuUserFetchFailed, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
      }
    })
  }

  getAllCategory(): Subscription {
    return this.categoryService && this.categoryService.getAllCategories({}).subscribe({
      next: (response => {
        if(response) {
          this.categoryData = response && response.category && response.category.rows;
          this.categoryIdPlaceholder();
        }
      }),
      error: (err => {
        console.error("Error while fetching all categories", err);
        this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationCategoryFetchFailed, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
      }) 
    })
  }

  /**
   * Fetches the details of a single configuration based on the route ID.
   * @returns {Subscription} The subscription object for the HTTP request.
   */
  getSingleConfiguration(): Subscription {
    return this.configurationService && this.configurationService.getSingleConfiguration(this.routeId).subscribe({
      next: (res: SingleConfigurationResponse) => {
        this.loading = false;
        if(res) {
          this.configurationDetail = res && res.data
        }
      },
      error: (err: string) => {
        console.error('Error fetching configuration details', err);
        this.loading = false;
      },
      complete: () => {
        // if(this.configurationDetail && this.configurationDetail.region) {
        //   this.auth && this.auth.region$ && this.auth.region$.next(this.configurationDetail && this.configurationDetail.region);
        // }
        this.authSubscriptionObject = this.auth && this.auth.region$ && this.auth.region$.subscribe(() => {
          this.onRegionChange();
        });
        this.SubscriptionObject = this.getAllUser();
        this.SubscriptionObject = this.getAllCategory();
        this.formInitialize();
      }
    })
  }

  /**
   * This method used to initialize the formControls for the formGroup named "configurationForm".
   */
  formInitialize(): void {
    const formControls: EditableFormControls = {
      server: new FormControl(this.configurationDetail && this.configurationDetail.server || null,
        [Validators.required]),
      host: new FormControl(this.configurationDetail && this.configurationDetail.host || null,
        [Validators.required]),
      userMail: new FormControl(this.configurationDetail && this.configurationDetail.userMail || null,
        [Validators.required, Validators.email]),
      password: new FormControl(this.configurationDetail && this.configurationDetail.password || null,
        [Validators.required]),
      from: new FormControl(this.configurationDetail && this.configurationDetail.from || null,
        [Validators.required, Validators.email]),
      port: new FormControl(this.configurationDetail && this.configurationDetail.portNumber || null,
        [Validators.required, Validators.pattern('^[0-9]{1,5}$'), this.maxRangeValidator(65535)])
    }
    if(this.createMode || !((this.configurationDetail && this.configurationDetail.id === 0) || (this.configurationDetail && this.configurationDetail.applicationId === 0))) {
      const controls: any = {
        userId: new FormControl(this.configurationDetail && this.configurationDetail.userId || null, 
          [Validators.required]),
        applicationId: new FormControl(this.configurationDetail && this.configurationDetail.applicationId || null, 
          [Validators.required]),
        categoryId: new FormControl(this.configurationDetail && this.configurationDetail.categoryId || null, 
          [Validators.required]),
        }
      Object.keys(controls).forEach((key) => {
        if(this.editMode && controls) {
          controls[key].disable();
        }
      })
      Object.assign(formControls, controls)
    }
    this.configurationForm = new FormGroup(formControls);
  }

  maxRangeValidator(max: number) {
    return (control: AbstractControl) => {
      const value = Number(control.value);
      return (isNaN(value) || value < 0 || value > max) ? { maxRange: true } : null;
    };
  }

  /**
   * This method used to navigate to configuration list page when cancel button is clicked
   */
  onCancelClick(): void {
    this.router && this.router.navigate(['app/mail/configuration']);
  }

  /**
   * Used to update the form when the user clicks the update button.
   * On successful updation, it navigates the user to the configurations list page.
   */
  onUpdate(): void {
    if(this.configurationForm && this.configurationForm.valid && this.configurationForm.dirty){
      this.loading = true;
      const update: UpdateConfigurationData = {
        value: this.configurationForm.value,
        id: (this.configurationDetail && this.configurationDetail.id) + ""
      }
      this.configurationService && this.configurationService.updateConfiguration(update.value, update.id).subscribe({
        next: (response: UpdateResponse) => {
          if(response) {
            this.afterUserInfoResponse();
            this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationUpdateSuccess, 
              heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
            this.router && this.router.navigate(['/app/mail/configuration']);
          }
        },
        error: (error: any) => {
          this.loading = false;
          this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationUpdateFailed, 
            heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
          console.error("Error while updating configuration", error)
        }
      })
    }
    else if(this.configurationForm && !this.configurationForm.dirty) {
      this.snackbar && this.snackbar.OpenSnackBar({
        message: SNACKBAR_MESSAGES.noChanges,
        heading: SNACKBAR_HEADING.warning,
        actionType: SNACKBAR_TYPE.warning,
      });
    } 
    else {
      this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField, 
        heading: SNACKBAR_HEADING.warning, actionType : SNACKBAR_TYPE.warning });
    }
  }

  /**
   * Used to submit the form when the user clicks the submit button.
   * On successful creation, it navigates the user to the configuration list page.
   */
  onSubmit(): void {
    if(this.configurationForm && this.configurationForm.valid){
      this.loading = true;
      const createFormValue = this.configurationForm && this.configurationForm.getRawValue();
      if (createFormValue && createFormValue.port) {
        createFormValue.portNumber = createFormValue.port;
        delete createFormValue.port;
      }
      this.configurationService && this.configurationService.createConfiguration(createFormValue).subscribe({
        next: () => {
          this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationCreatedSuccess, 
            heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
          this.afterUserInfoResponse();
          this.router && this.router.navigate(['/app/mail/configuration']);
        },
        error: (error: any) => {
          this.loading = false;
          this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationCreatedFailed, 
            heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
          console.error("Error creating configuration", error);
        }
      })
    }
    else {
      this.snackbar && this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField, 
        heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.warning });
    }
  }

  /**
  * Method afterUserInfoResponse used to make the form as pristine when clicked on the submit or update button.
  */
  afterUserInfoResponse(): void {
    this.configurationForm && this.configurationForm.markAsPristine();
    this.loading = false
  }

  /**
   * This method used to emit all application data based on the user selection
   * @param { MatOptionSelectionChange } event 
   */
  onUserChange(event: MatOptionSelectionChange): void {
    if (event.isUserInput) {
      this.userIdParam = event.source.value;
      this.configurationForm && this.configurationForm.get('applicationId')?.setValue('');
      this.getApplicationList({userId: this.userIdParam});
    }
  }

  /**
   * This method used to call getApplicationList() method with or without userId.
   * @returns { void }
   */
  onRegionChange(): void {
      this.userIdParam ? this.getApplicationList({userId: this.userIdParam}) : this.editMode ? this.getApplicationList({}) : "";
  }

  /**
   * Fetches the list of applications by calling the customer service's getApplicationList method.
   * @param {any} param - Optional parameters to filter.
   */
  getApplicationList(param?: any): void {
    this.SubscriptionObject =  this.customerService && this.customerService.getApplicationList(param).subscribe({
      next: (res: GetApplicationData) => {
        if(res) {
          this.applicationData = res && res.applicationData && res.applicationData.rows;
          this.applicationIdPlaceholder();
        }
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationApplicationFetchFailed, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching application details', err);
      }
    })
  }

  /**
   * Sets the applicationId placeholder based on the number of applications.
   * If there are no applications, the placeholder will be "No Data Available".
   * If applications are present, the placeholder will be "Select Application Name".
   */
  applicationIdPlaceholder(): void {
    const applicationLength = this.applicationData && this.applicationData.length
      if(applicationLength > 0) {
        this.applicationPlaceHolder = "Select Application Name";
      }
      else {
        this.applicationPlaceHolder = "No Data Available";
      }
  }

  /**
   * Sets the category placeholder based on the number of applications.
   * If there are no categories, the placeholder will be "No Data Available".
   * If categories are present, the placeholder will be "Select Category Name".
   */
  categoryIdPlaceholder(): void {
    const categoryLength = this.categoryData && this.categoryData.length ? this.categoryData.length : 0;
      if(categoryLength > 0) {
        this.categoryPlaceHolder = "Select Category Name";
      }
      else {
        this.categoryPlaceHolder = "No Data Available";
      }
  }

  /**
    * Method used to call the canDeactivate guard when the form is not pristine.
    * @returns { boolean } dialog box
  */
  canDeactivate(): boolean {
    return this.configurationForm ? this.configurationForm.pristine : true;
  }

  ngOnDestroy() {
    this.SubscriptionObject && this.SubscriptionObject.unsubscribe();
    this.authSubscriptionObject && this.authSubscriptionObject.unsubscribe();
  }
}
