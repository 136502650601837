import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { TokenData, User } from 'src/app/auth/models/auth.model';
import { MenuItem, SideNavConfig } from '../../models/menu-bar.model';
import { CommonDialogService } from '../../service/common-dialog.service';

@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss']
})
export class SideMenuBarComponent {
    /**
    * viewchild for get Mat-sidenav selector
    */
    @ViewChild(MatSidenav)

    /**
   * The side navigation component.
   * @type {MatSidenav}
   */
    sidenav!: MatSidenav;

    /**
     * Indicates whether the device is mobile.
     * @type {boolean}
     * @default true
     */
    isMobile: boolean = true;

    /**
     * to change animation based on the mat-drawer is closed or opened.
     * @type {boolean}
     * @default false
     */
    isCollapsed: boolean = false;

    /**
     * Indicates whether the side navigation is opened or closed.
     * @type { boolean }
     * @default true
     */
    isOpened: boolean = true;

    /**
     * The menus available in the side navigation.
     * @type {MenuItem}
     */
    menus!: MenuItem;

    /**
     * The ID of the currently selected menu.
     * @type {number}
     */
    menuId!: number;

    /**
     * Indicates whether to show the submenu.
     * @type {boolean}
     * @default true
     */
    showSubmenu: boolean = true;

    /**
     * The ID of the previously selected menu.
     * @type {number}
     */
    previousMenuId!: number;

    /**
     * The menus available in the side navigation.
     * @type {SideNavConfig}
     */
    menubarData!:any;
    isDashboaderloader:boolean=false;
    isAdmin:boolean=false;
  /**
   * Component constructor to inject required services.
   * @param breakpointObserver To check if it mobileview or webview.
   * @param router To navigate desired route.
   * @param commonService To get menu details.
   */
  constructor(
    private observer: BreakpointObserver ,
    private router: Router,
    private commonService : SharedService,
    public authService : AuthService,
    private dialogService:CommonDialogService
  ) {}

    /**
   * Angular life cycle hooks
   */
  ngOnInit() {
    this.authService.role$.subscribe({
      next:(tokenData:TokenData|null)=>{
         if(tokenData?.roleId==="1"){
            this.isAdmin=true;
            this.getmenuDetails(this.isAdmin);
         }else{
          this.isAdmin=false;
          this.getmenuDetails(this.isAdmin);
         }
      }
    })
  }
  getmenuDetails(isAdmin?:boolean){
    this.isDashboaderloader=true;
    this.commonService.getMenuDetails().subscribe((res: any) => {
      if(isAdmin){
        this.menubarData = res;
      } else{
        this.menubarData=res;
        this.menubarData = this.menubarData?.map((item:SideNavConfig) => {
          return {...item,
                 menu: item?.menu?.filter((menuItem:MenuItem) => menuItem?.pageCustomTitle !== 'Users')
          };
        });
      }
      this.menus = this.menubarData[0];
      this.menubarData[0].isSelected = true;
      setTimeout(() => {
      this.isDashboaderloader=false;
      this.router.navigate(["app/mail/dashboard"]);
      }, 500);
    })
    this.isMobileScreen();
  }

    /**
  * The method used to detect the current screen size of the window
  */
  isMobileScreen(){
    this.observer.observe(['(max-width: 960px)']).subscribe((screenSize) => {
      if (screenSize.matches) {
        this.isMobile = true;
        this.isCollapsed = true;
      } else {
        this.isMobile = false;
        this.isCollapsed = !this.isOpened;
      }
    });
  }

   /**
  * The method used to load the menu datas.
  * @param index To get the index for selected menu.
  * @param selectedData To get selected menu detail.
  */
  menuDataLoading(index:number, selectedData:any){
    this.isDashboaderloader=true;
    if(index===0){
      this.router.navigate(["app/mail/dashboard"]);
    }
    if(index===1){
      this.router.navigate(["app/marketing/customers"]);
    }
    this.menus = this.menubarData[index];
    this.menubarData.forEach((item:any) => {
      item.isSelected = false;
      item.isRegion = false;
    });
    selectedData.isSelected = true;
    selectedData.isRegion = true;
    setTimeout(() => {
      this.isDashboaderloader=false;
      }, 500);
  }

   /**
  * The method used to sign out.
  */
  logout(){
     this.dialogService.openDialog({
      header: 'Logout',
      message: 'Are you sure you want to logout?',
      actionType: 'success',
      image:'../../../../assets/exit (1).png',
      button: { right: 'Yes, Logout', left: 'Stay' },
      disableClose: true
      }).subscribe((dialogResult:boolean)=>{
        if(dialogResult){
          this.router.navigate(["signin"]);
        }
      })
  }

  /**
   * Toggles the state of the drawer and updates the `isCollapsed` variable.
   */
  drawerToggle(): void {
    setTimeout(() => {
      if(!this.isMobile) {
        this.isCollapsed = !this.isCollapsed
      }
      this.isOpened = !this.isOpened;
    }, 0)
  }
}
