import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { HeaderService } from './header.service';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/auth.model';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { ApiResponse } from 'src/app/common/models/api-response.model';
import { GET_USER_API_URL } from '../constant/auth.constants';

export const authGuard: CanActivateFn = (route, state) => {

  const authService = inject(AuthService);
  const headerService = inject(HeaderService);
  const router = inject(Router);

  if (authService.isAuthenticated()) {
    const token = authService.getToken();
    if (token) {
        headerService.setheader('default', 'Authorization', token);
        headerService.setheader('default', 'Content-Type', 'application/json');
        authService.setRegionInHeader();
        getUserDetils();
    }
    return true;
  } else {
    router.navigate(['/signin']);
    return false;
  }

};

/**
* Fetches user details and updates the user$ BehaviorSubject.
*/
const getUserDetils= ()=>{
  const authService = inject(AuthService);
  const httpService = inject(HttpRoutingService);

  const storedRoleId = localStorage.getItem('roleId');
  authService.role$.next({roleId: storedRoleId })

  httpService.getRequest<ApiResponse<User[]>>(GET_USER_API_URL).subscribe(res=>{
    authService.user$.next(res.response)
  })
}
