<div *ngIf="!loading" class="common-header">
    <app-common-header [heading]="dynamicTitle[action]" ></app-common-header>
</div>
<div *ngIf="loading; else viewCustomer" class="add-edit-container common-card-style">
    <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div>

    <ng-template #viewCustomer>
        <mat-card class="add-edit-container common-card-style">
        <form *ngIf="customersForm" [formGroup]="customersForm" (ngSubmit)="editMode ? onUpdate() : onSubmit()" fxLayout="column" fxLayoutGap="15px" fxLayoutGap.lt-sm="10px">
            <div fxLayout="column" fxLayoutGap="2%">
                <div fxLayout="row">
                    <div fxLayoutAlign="start center" fxFlex="auto" [ngStyle]="{'padding-top':'20px'}">
                        <h4 class="mat-card-header">Customer Details</h4>
                    </div>
                    <div fxFlex="auto" fxLayoutGap="15px" fxLayoutAlign="end center" fxLayoutAlign.xs="center end" fxLayout.xs="column">
                        <div *ngIf="!createMode" style="width: 100px;" fxLayout="row" fxLayoutAlign="end center">
                            <div fxLayout="row" fxLayoutAlign="space-around center" [ngStyle]="viewMode ? {'opacity': '0.5', 'cursor': 'default'} : {'opacity': '1'}" class="toggle-button" (click)="editMode ? toggleClicked(isActivated) : ''" [ngClass]="{ 'inactiveLine': !isActivated, 'activeLine': isActivated }">
                                <div class="isActiveContainer" [attr.data-control]="isActivated ? 'Active-Customer' : 'Inactive-Customer'" 
                                    fxLayoutAlign="center center" [matTooltip]="isActivated ? 'Active Customer' : 'Inactive Customer'" 
                                    [ngClass]="{ 'inactive': !isActivated, 'active': isActivated }" >
                                        {{isActivated ? "Active" : "Inactive"}}
                                </div>
                            </div>  
                        </div>
                        <button type="button" class="cancel-button btn" mat-button (click)="onCancelClick()" data-control="cancel-button">Cancel</button>
                        <ng-container *ngIf="viewMode">
                            <div class="image-container view-logs-container" fxLayoutAlign="start center" (click)="onViewLogClick()">
                                <img matTooltip="Logs list" class="view-logs-image img" data-control="view-logs-image" src="../../../../assets/view-log.png" >
                            </div>
                            <div class="image-container view-reports-container" fxLayoutAlign="start center" (click)="onViewTemplatesClick()">
                                <img matTooltip="Templates list" class="view-templates-image img" data-control="view-templates-image" src="../../../../assets/view-template.png" >
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!viewMode">
                            <button class="create-button btn" type="submit" mat-button data-control="create-button">{{ editMode ? 'Update' : 'Create' }}</button>
                        </ng-container>
                    </div>
                </div>
                <div>
                  <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
                </div>
              </div>
            <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxLayoutGap.lt-sm="10px" fxFlex="100%">
                <div fxLayout="column" *ngIf="createMode" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>User Name</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <mat-select placeholder="Select User Name" formControlName="userId">
                            <mat-option (onSelectionChange)="onUserChange($event)" *ngFor="let userData of userData?.response?.rows" [attr.data-control]="userData?.firstName + '-' + userData?.lastName" [value]="userData?.id">
                                {{userData?.firstName + ' ' + userData?.lastName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="customersForm?.get('userId')?.hasError('required')">{{addEditViewCustomer.validators.userNameRequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" *ngIf="createMode" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Application Name</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <mat-select [placeholder]="this.applicationPlaceHolder ? this.applicationPlaceHolder : 'Select Application Name'" formControlName="applicationId">
                            <mat-option *ngFor="let application of applicationData" [attr.data-control]="application?.applicationName" [value]="application?.id">{{application?.applicationName}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="customersForm?.get('applicationId')?.hasError('required')">{{addEditViewCustomer.validators.applicationNameRequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Customer Name</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="customerName" data-control="customer-name-input" placeholder=" Enter Customer Name">
                        <mat-error *ngIf="customersForm?.get('customerName')?.hasError('required')">{{addEditViewCustomer.validators.customerNameRequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Phone Number</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="phoneNumber" data-control="phone-number-input" placeholder="Enter Phone Number">
                        <mat-error *ngIf="customersForm?.get('phoneNumber')?.hasError('required')">{{addEditViewCustomer.validators.phoneNumberRequired}}</mat-error>
                        <mat-error *ngIf="customersForm?.get('phoneNumber')?.hasError('pattern')">{{addEditViewCustomer.validators.phoneNumberPattern}}</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Email Id</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="emailId" data-control="email-input" placeholder="Enter Email Address">
                        <mat-error *ngIf="customersForm?.get('emailId')?.hasError('required')">{{addEditViewCustomer.validators.emailIdRequired}}</mat-error>
                        <mat-error *ngIf="customersForm?.get('emailId')?.hasError('email')">{{addEditViewCustomer.validators.emailIdPattern}}</mat-error>
                    </mat-form-field >
                </div>
                <div fxLayout="column" fxFlex="47.5%" fxFlex.lt-sm="100%" fxLayoutGap="2%">
                    <mat-label>Customer Id</mat-label>
                    <mat-form-field class="form-container" appearance="outline">
                        <input type="text" matInput formControlName="customerId" data-control="customer-input" placeholder="Enter Customer Id">
                        <mat-error *ngIf="customersForm?.get('customerId')?.hasError('required')">{{addEditViewCustomer.validators.customerIdRequired}}</mat-error>
                        <mat-error *ngIf="customersForm?.get('customerId')?.hasError('pattern')">{{addEditViewCustomer.validators.customerIdPattern}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </mat-card>
    </ng-template>
