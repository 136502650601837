import { Component } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { cardDetails } from '../../models/auth.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
   /**
   * Store the dashboard card details.
   * @type {cardDetails}
   */
  cardDetails!: cardDetails[];
  
  /**
    * constructor for injecting needed services
    * @param AuthService used to access methods from Auth service
  */
  constructor(private authservice: AuthService) { }
  
  /**
   * component onInit life cycle hook.
  */
  ngOnInit() {
    this.authservice.getDashboardDetails().subscribe((res: any) => {
      if (res) {
        this.cardDetails = res;
      }
    })
  }
}
