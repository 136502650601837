import { Component, Input } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { MenuItem, RegionArray, RegionOption, SideNavConfig, SubMenu } from '../../models/menu-bar.model';

@Component({
  selector: 'app-menu-drawer',
  templateUrl: './menu-drawer.component.html',
  styleUrls: ['./menu-drawer.component.scss']
})
export class MenuDrawerComponent {

    /**
     * Indicates whether the device is in a mobile view.
     * @type {boolean}
     * @default true
     */
    isMobile: boolean = true;

    /**
     * The ID of the currently selected menu.
     * @type {number}
     */
    menuId!: number;

    /**
     * Indicates whether to show the submenu.
     * @type {boolean}
     * @default true
     */
    showSubmenu: boolean = true;

    /**
     * The ID of the previously selected menu.
     * @type {number}
     */
    previousMenuId!: number;

    /**
     * Indicates whether the item is selected.
     * @type {boolean}
     * @default false
     */
    selected: boolean = false;

    /**
    * input given for menus
    */
    @Input() menus!:any;

    options = [
      { value: 'ap-south-1', viewValue: 'INDIA', countryImage:'../../../../assets/india.jpg' ,sortValue:'IN' },
      { value: 'us-west-2', viewValue: 'USA', countryImage:'../../../../assets/USA.png' ,sortValue:'US' }
    ];

    selectedOption!: any;

    /**
    * Component constructor to inject required services.
    * @param router To navigate desired route.
    */
    constructor(private router: Router , private authService : AuthService) {}

    ngOnInit(){
      this.authService.region$.subscribe((res:string) => {
        this.selectedOption = this.options.filter((v:any)=>{
           return v.value === res;
        });
      })
    }



    /**
    * Method which is used to navigate to route while click menu.
    * @param menu To get particular menu detail.
    * @returns Returns true if menu having submenu.
    */
    onRouterNavigation(menu:MenuItem) {
      // if (menu && menu.subMenu && menu.subMenu.length > 0 && !this.previousMenuId !== menu.id) {
      if (menu && menu.subMenu && menu.subMenu.length > 0 && this.previousMenuId !== menu.id) {
        this.showSubmenu = !this.showSubmenu;
        this.menuId = menu.id;
        if (this.previousMenuId !== menu.id) {
          this.router.navigate([menu.subMenu[0].pageCustomLink]);
          this.showSubmenu = true;
        }
        this.previousMenuId = menu.id;
      }
      this.menus.menu.forEach((item:MenuItem) => {
        if (item.subMenu) {
          item.subMenu.forEach((subItem:SubMenu) => subItem.isSelected = false);
        }
      });
      menu.subMenu[0].isSelected =true;
    }

    /**
   * Method which is used to navigate to route while clicking submenu.
   * @param routerLink To get router.
   * @param submenu To get selected list.
   */
    onSubMenuClick(routerLink:string, submenu:SubMenu) {
      this.menus.menu.forEach((item:MenuItem) => {
        if (item.subMenu) {
          item.subMenu.forEach((subItem:SubMenu) => subItem.isSelected = false);
        }
      });
      submenu.isSelected = true;
      this.router.navigate([routerLink]);
    }

    onSelectionChange(event: MatSelectChange) {
      this.selectedOption = event.value;
      this.authService.region$.next(event.value.value);
    }
}
