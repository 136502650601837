import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportService } from '../../services/report.service';
import * as echarts from 'echarts';
import { Category, CategoryResponse, MailCategories, MailCounts, customerReport, customerReportResponse } from '../../models/reports.model';
import { EChartsOption } from 'echarts';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Heading } from 'src/app/shared/constants/common-header.constants';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  standalone:true,
  imports:[SharedModule,CommonModule,NgxEchartsModule],
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit, OnDestroy {

  /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;

  /**
  * Variable used to store the pie chart configurations.
  * @type {EChartsOption}
  */
  pieChartOptions!: EChartsOption;

  /**
  * Variable used to store the total number of success mails.
  * @type {number}
  */
  totalSuccessCount: number = 0;

  /**
  * Variable used to store the total number of failed mails.
  * @type {number}
  */
  totalFailureCount: number = 0;

  /**
  * Variable used to store the total number of mails.
  * @type {number}
  */
  totalMails: number = 0;

  /**
  * Variable used to store the mail counts.
  * @type {MailCounts[]}
  */
  mailCounts: MailCounts[] = []

  /**
  * Variable used to store the categories of the mails.
  * @type {MailCategories[]}
  */
  mailCategories: MailCategories[] = []

  /**
  * Variable used to store the customer name.
  * @type {string}
  */
  customerName!: string;

  /**
  * Variable used to store the observables.
  * @type {Subscription}
  */
  SubscriptionObject: Subscription = new Subscription();

  /**
   * Variable used to store the parameter Id from the URL.
   * @type {number}
   */
  paramId!: number

  /**
   * Variable used to store the mail report data..
   * @type {customerReportResponse}
   */
  mailReportData!: customerReportResponse;

  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  headerDetails:Heading = {
    title : "View Reports"
  }

  /**
  * component constructor which is used to inject the required services.
  * @param report To refer to the ReportService to access the mail report functions.
  * @param route  To subscribe the params from the URL.
  * @param router To refer to the router to navigate to the report page.
  */
  constructor(private report: ReportService, private route: ActivatedRoute, private router: Router) { }

  /**
  * Angular life cycle hook.
  * @type {void}
  */
  ngOnInit(): void {
    this.initialLoader = true;
    this.route.params.subscribe((res: Params) => {
      this.paramId = res['id'];
    })
    this.SubscriptionObject = forkJoin({
      categoryList: this.report.getMailCategories(),
      mailReport: this.report.getMailReport(this.paramId)
    }).subscribe(({ categoryList, mailReport }) => {
      this.initialLoader = false;
      this.processMailCategories(categoryList);
      setTimeout(() => {
        this.processMailReport(mailReport);
      },0)
    });
  }

  /**
  * Method used to get the categories list.
  * @type {void}
  * @param categoryList To refer to an Element
  */
  processMailCategories(categoryList: CategoryResponse): void {
    this.mailCategories = categoryList.category.rows.map((element: Category) => ({
      categoryName: element.categoryName,
      categoryId: element.id
    }));
  }

  /**
  * Method used to get the mail report counts and calls the chart initialization functions.
  * @type {void}
  */
  processMailReport(mailReport: customerReportResponse): void {
    this.mailReportData = mailReport
    this.calculateMailCounts(mailReport);
    this.customerName = mailReport.report[0].customers.customerName;
    this.createPieChart();
    this.mailCounts = mailReport?.report?.map((element: customerReport) => ({
      successMails: element.successCount,
      failedMails: element.failureCount,
      totalMails: element.successCount + element.failureCount
    }));
    this.getBarChartElements();
  }

  /**
  * Method used to get the elements to create bar charts.
  * @type {void}
  */
  getBarChartElements(): void {
    this.mailCategories.forEach((_, index) => {
      const categoryElement = document.getElementById('category' + index);
      if (categoryElement) {
        const categoryChart = echarts.init(categoryElement);
        this.createBarChart(categoryChart, index);
      }
    });
  }

  /**
  * Method used to set the pie chart configurations.
  * @type {void}
  */
  createPieChart(): void {
    this.pieChartOptions = {
      tooltip: { trigger: 'item' },
      legend: { orient: 'horizontal', left: 'center', top: 'bottom' },
      series: [{
        name: 'Mails',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: { show: false, position: 'center' },
        emphasis: { label: { show: false, fontSize: '20', fontWeight: 'bold' } },
        labelLine: { show: true },
        data: [
          { value: this.totalSuccessCount, name: 'Success Mails' },
          { value: this.totalFailureCount, name: 'Failed Mails' }
        ],
        color: ['#4682B4', '#FF6347']
      }],
      graphic: {
        type: 'text',
        left: 'center',
        top: 'middle',
        style: {
          text: `Total Mails\n${this.totalMails}`,
          textAlign: 'center',
          fill: '#000',
          fontSize: 20,
          fontWeight: 'bold'
        }
      }
    } as EChartsOption;
  }

  /**
  * Method used to set the bar chart configurations.
  * @type {void}
  */
  createBarChart(chartElement: echarts.EChartsType, index: number): void {
    const { successMails = 0, failedMails = 0, totalMails = 0 } = this.mailCounts[index] || {};
    const option: EChartsOption = {
      title: {
        text: this.mailCategories[index].categoryName,
        left: 'center',
        subtext: `Total Mails: ${totalMails}`,
        subtextStyle: { align: 'center', top: 'bottom' }
      },
      tooltip: {
        trigger: 'item',
        axisPointer: { type: 'shadow' }
      },
      xAxis: {
        type: 'category',
        show: true,
        axisTick: { show: false },
        axisLine: { show: true },
        min: 0,
        max: 1,
        axisLabel: { show: false },
        boundaryGap: 3,
      },
      yAxis: {
        type: 'value',
        max: totalMails,
        min: 0,
        show: true,
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLine: { show: true }
      },
      series: [
        {
          name: 'Success Mails',
          type: 'bar',
          barWidth: '80%',
          data: [{ value: successMails > 0 ? successMails : 0, name: 'Success Mails' }],
          itemStyle: { color: '#37e137' },
          label: { show: true, position: 'top' }
        },
        {
          name: 'Failed Mails',
          type: 'bar',
          barWidth: '50%',
          data: [{ value: failedMails, name: 'Failed Mails' }],
          itemStyle: { color: '#FF6347' },
          label: { show: true, position: 'top' }
        }
      ],
      grid: {
        left: '30%',
        containLabel: true,
        bottom: '20%'
      }
    } as EChartsOption;
    chartElement.setOption(option);
  }

  /**
  * Method used to calculate the success, failed and total mails.
  * @type {void}
  */
  calculateMailCounts(reportData: customerReportResponse): void {
    this.totalSuccessCount = this.calculateTotal(reportData.report, 'successCount');
    this.totalFailureCount = this.calculateTotal(reportData.report, 'failureCount');
    this.totalMails = this.totalSuccessCount + this.totalFailureCount;
  }

  /**
  * Method used to calculate the total mails in all categories mails.
  * @type {void}
  */
  calculateTotal(reportArray: customerReport[], key: string): number {
    return reportArray.reduce((acc, curr) => acc + (curr[key] || 0), 0);
  }

  onClick() {
    this.router.navigate(["app/mail/report-list"])
  }
  /**
  * Angular life cycle hook.
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}