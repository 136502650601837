import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ColumnType, TEMPLATE_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { ActionClicked, DynamicDataSource, OptionalEvent, PaginationData, SearchSetting, TableAction, columnDef } from 'src/app/shared/models/common-card-data.model';
import { Subscription } from 'rxjs';
import { EmittedData, FilterData } from 'src/app/shared/models/common-filter.model';
import { FilterType } from 'src/app/shared/constants/common-filter-data.contants';
import { AuthService } from 'src/app/auth/service/auth.service';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { DialogMsgTypes, UpdateResponse } from 'src/app/template/models/template.model';
import { BUTTON_TEXT, CONFIRM_MESSAGES, DIALOG_HEADER, DIALOG_TYPE, USER_MESSAGES } from 'src/app/shared/constants/common-dialog-data.constants';
import { DialogType, DialogHeader, ButtonText } from 'src/app/shared/models/common-dialog-data.model';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NullFilter } from 'src/app/customers/models/customers.model';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { SNACKBAR_HEADING, SNACKBAR_MESSAGES, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { Heading, ButtonInfo } from 'src/app/shared/constants/common-header.constants';
import { DeleteUserDetail, UserActionEvent, UserDialogMessages, userFilterData, UserListParams, UserListResponse, userTableData } from 'src/app/zen-mail/Users/models/user.model';
import { USER_SNACKBAR_MESSAGES, UserValidators } from '../../constants/user.constants';
import { UserListService } from 'src/app/zen-mail/Users/service/user-list.service';
import { SharedModule } from "../../../../shared/shared.module";
import { CommonModule } from '@angular/common';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {
  /**
   * Reference to the template for the create or edit dialog.
   * @type {TemplateRef<any>}
   */
  @ViewChild('dialogDisplay', { static: false }) dialogDisplay?: TemplateRef<any>;

  userValidators = new UserValidators();
  /**
   * Flag indicating if the component is in edit mode.
   * @type {boolean}
   */
  isEditMode: boolean = false;

  /**
   * Flag indicating if the component is in create mode.
   * @type {boolean}
   */
  isCreateMode: boolean = false;

  /**
  * Declaring form group
  * @type { FormGroup }
  */
  userFormGroup?: FormGroup;

  /**
   * Assigning the constant CONFIRM_MESSAGES to the dialogConfirmMessages variable.
   */
  message = CONFIRM_MESSAGES;

  /**
   * Assigning the constant BUTTON_TEXT to the buttonText variable
   * @type {ButtonText}
   */
  buttonText: ButtonText = BUTTON_TEXT

  /**
   * Variable used to store the selected user's id.
   * @type { number }
   */
  userId?: number

  /**
  * Variable used to store the table data.
  * @type {userTableData[]}
  */
  data!:userTableData[];
  /**
  * Variable used to store the searching text.
  * @type {string}
  */
  searchData:string='';
  /**
  * Variable used to store the filter data.
  * @type {string}
  */
  filterData: string = '';
  /**
  * Variable used to store the details for filter.
  * @type {FilterData[]}
  */
  filterDetails!: FilterData[];
  /**
  * Flag Variable used to indicate whether enable filter or not.
  * @type {boolean}
  */
  isFilter: boolean = false;
  /**
  * Variable used to store the observables.
  * @type {Subscription}
  */
  SubscriptionObject: Subscription = new Subscription();
  /**
  * Flag Variable used to indicate whether enable loader or not.
  * @type {boolean}
  */
  userListLoader: boolean = true;
  /**
  * Variable used to store the table data length.
  * @type {number}
  */
  responseCount!:number;
  /**
  * Variable used to store the table filter data.
  * @type {userFilterData}
  */
  filterValue:userFilterData={'isSuspend':''};
  /**
  * Variable used to store the table Pagination data.
  * @type {PaginationData}
  */
  paginationData:PaginationData={
   limit:5,
   count:0,
   offset:0
  }
  /**
  * Variable used to show filter options.
  * @type {PaginationData}
  */
  filterOption= [{ name:"Active", id:"false" }, { name:"Suspend", id:"true" }]
  /**
  * Variable used to store the table column definition.
  * @type {Array<columnDef>}
  */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox ,columnWidth: '10%',columnAlign:'start'},
   { field: 'firstName', header: 'First Name', type: ColumnType.Text, columnWidth: '15%',columnAlign:'start' },
   { field: 'lastName', header: 'Last Name', type: ColumnType.Text, columnWidth: '15%',columnAlign:'start' },
   { field: 'email', header: 'Email', type: ColumnType.Text ,columnAlign:'start'},
   { field: 'Suspend', header: 'Suspend', type: ColumnType.DropDown, columnWidth: '20%', dropDownCondition:'isSuspend', dropDownOption:[
    { 'id':false, 'name':'Active', 'backgroundColor': 'rgba(28, 167, 26, 0.69)', 'textColor':'white' },
    { 'id':true, 'name':'Suspend', 'backgroundColor': '#ff5959', 'textColor':'white' }]},
   { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '15%', color: 'white' },
  ];
  /**
  * Variable used to store the table actions data.
  * @type {Array<TableAction>}
  */
  actions: Array<TableAction> = [
    { icon: 'edit', tooltip: 'Edit', method: 'onEdit', color: '#00b907'},
    { icon: 'delete', tooltip: 'Delete', method: 'onDelete', color: 'red'}
  ];
  /**
  * Variable used to store search settings data.
  * @type {SearchSetting}
  */
  searchSettingData:SearchSetting={
   searchText:'',
   placeHolder:'First Name',
   appearance:'outline'
  }
  /**
  * Variable used to store the table filter data of optional event.
  * @type {Array<OptionalEvent>}
  */
  optionalEvent: Array<OptionalEvent> = [
    { eventName: 'onFilter', icon: 'filter_list', toolTip: 'Filter', class: 'filter', color: 'white' }
  ]
  /**
  * @type {TemplateDialogMessages}
  * Assigning the constant templateMessages to the template Messages variable.
  */
  userMessages: UserDialogMessages = USER_MESSAGES;
  /**
   * @type {DialogType}
   * Assigning the constant DIALOG_TYPE to the dialogType variable.
   */
  dialogType: DialogType = DIALOG_TYPE;
  /**
   * @type {DialogHeader}
   * Assigning the constant DIALOG_HEADER to the dialogHeader variable.
   */
  dialogHeader: DialogHeader = DIALOG_HEADER;
  /**
   * Assigning the constant CONFIRM_MESSAGES to the dialogConfirmMessages variable.
   */
  dialogConfirmMessages = CONFIRM_MESSAGES;
      /**
  * Variable used to store the isFilterValue.
  * @type {boolean}
  */
  isFilterValue:boolean=false;

  /**
   * Variable used to store no data message.
   * @type {string}
   */
  noDataMessage : string = TEMPLATE_MESSAGES.NO_RECORD_USER; 
    /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;
  /**
  * component constructor which is used to inject the required services.
  * @param report To refer to the ReportService to access the mail report functions.
  * @param auth To refer to the AuthService to access the region subscription.
  * @param userListService to get the user list and update user information.
  */

  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Users",
    description : 'Edit or delete user details effortlessly with quick and easy management options.',
    fontWeight : '500'
  }

  /**
   * Variable has button data to be displayed on commen header.
   * @type {Heading}
   */
  button:ButtonInfo[] = [{
    color : '#ffffff',    
    name : 'Create',
    method : 'onCreateClick',
    disabled : false,
    iconClass : 'outline',
    iconToolTip : 'btn',
    class : 'primary-button'
  }]
    
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number = -1;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;
    
  constructor(
    private userListService: UserListService, private auth: AuthService, private snackbar: CommonSnackbarService,
    private dialogService: CommonDialogService, private dialog: MatDialog, private commonService: CommonService) { }

  ngOnInit(){
    this.SubscriptionObject = this.auth && this.auth.region$ && this.auth.region$.subscribe(()=>{
      this.initialLoader=true;
      this.userListLoader=true;
      this.gettingUserList({ offset:0, limit:5 })
      this.appliedFilters = -1;
      this.isFilter = false;
    });
    this.commonService.isFilterClicked.subscribe(res =>{
      this.isFilterClicked = res;
    })
  }
  
  /**
  * Method used to get the list of all users.
  * @param { UserListParams } params - Parameters for pagination, sorting, searching, and filtering.
  * @returns { void }
  */
  gettingUserList(params: UserListParams): void{
    this.SubscriptionObject = this.userListService && this.userListService.getUserList(params).subscribe({
      next: (res: UserListResponse) => {
        this.userListLoader = false;
        this.data = res && res.response && res.response.rows;
        this.responseCount = res && res.response && res.response.count;
        this.paginationData.count = this.responseCount;
        this.initialLoader = false;
      },
      error: (err: string) => {
        console.error('Error fetching user list', err);
        this.initialLoader=false;
        this.userListLoader = false;
        this.snackbar && this.snackbar.OpenSnackBar({ message : USER_SNACKBAR_MESSAGES.userFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
      }
    });
  }

  /**
  * Method which is used to active and suspend status for users.
  */
  onToggleChanged(event: any): void {
    const data = {
      id: event.id,
      isSuspend: !event.isSuspend
    };
    this.dialogService && this.dialogService.openDialog({
      header: 'Confirmation',
      message: data.isSuspend ? this.userMessages && this.userMessages.userDisabled : this.userMessages && this.userMessages.userEnabled,
      actionType: 'confirmation',
      button: { right: data.isSuspend?'Yes, Suspend':'Yes, Restore', left: 'No' },
      disableClose:true
    }).subscribe((dialogResult: boolean) => {
      if (dialogResult) {
        this.userListService && this.userListService.updateUser(data).subscribe({
          next: (res: UpdateResponse) => {
            const msgType = {
              message: (res && res.success) ? data && data.isSuspend ? this.userMessages && this.userMessages.userDisableUpdatedSuccessfull 
                : this.userMessages && this.userMessages.userEnableUpdatedSuccessfull : this.userMessages && this.userMessages.userUpdatedFailed,
              actionType: (res && res.success) ? 'success' : 'failure'
            }
            this.dialogMsg(msgType);
          }, error: (error: Error) => {
            this.userListLoader = false;
            this.dialogService && this.dialogService.openDialog({
              header: '',
              message: 'Something Went Wrong',
              actionType: 'failure',
              button: { right: 'Ok' },
              disableClose: true
            });
          }
        })
      }
      else {
        this.userListLoader = false;
      }
    })
  }

  /**
  * Method which is used to show dialogs based on Action.
  * @type { DialogMsgTypes } messageType
  * @returns { void }
  */
  dialogMsg(messageType: DialogMsgTypes): void {
    this.dialogService && this.dialogService.openDialog({
      header: '',
      message: messageType && messageType.message,
      actionType: messageType && messageType.actionType,
      button: { right: 'Ok' },
      disableClose:true
    });
    this.userListLoader = false;
    this.gettingUserList({ offset: 0, limit: this.paginationData.limit })
  }

  /**
  * Method used to handle action events triggered from the UI.
  * @param {ActionClicked<DynamicDataSource>} event - The event object containing the method name and data 
  * associated with the action.
  * @returns {void}
  */
  onActionClicked(event: ActionClicked<DynamicDataSource>): void {
    (this as DynamicDataSource)[event.method](event);
  }

  /**
  * Method which is used to delete application data.
  * @param { UserActionEvent } dataToBeDeleted It has the application data that have to be deleted.
  * @returns {void}
  */
  onDelete(dataToBeDeleted : UserActionEvent): void{
    this.setDialogData( this.dialogHeader && this.dialogHeader.confirmation, this.dialogType && this.dialogType.confirmation, { left: 'No', right: 'Yes, Delete' }, this.dialogConfirmMessages && this.dialogConfirmMessages.deleteUser).subscribe(( dialogResult: boolean | string ) => {
      if(dialogResult){
        this.userListService && this.userListService.deleteUser(dataToBeDeleted && dataToBeDeleted.data && dataToBeDeleted.data.id).subscribe({
          next: (deleteUserResponse: DeleteUserDetail) => {
          if(deleteUserResponse && deleteUserResponse.success) {
            this.setDialogData( this.dialogHeader && this.dialogHeader.success , this.dialogType && this.dialogType.success , { right: 'Okay' } , '' , deleteUserResponse );
          }
          else {
            this.setDialogData(this.dialogHeader && this.dialogHeader.failure , this.dialogType && this.dialogType.failure , { right: 'Okay'} , '' , deleteUserResponse);
          }
        },  
        error: (err: string) => {
          console.error(err);
          this.snackbar && this.snackbar.OpenSnackBar({ message : USER_SNACKBAR_MESSAGES.userDeleteFailed, 
            heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        },
        complete : ()=> {
          const param = this.paramAssign();
          param.offset = 0;
          this.gettingUserList(param);
        }
      })
      }
    })
  }

  /**
   * Handles the edit action for a user.
   * Opens a dialog with the user data pre-filled for editing.
   * @param { UserActionEvent } event - The event containing user data to edit.
   */
  onEdit(event: UserActionEvent): void {
    this.isEditMode = true;
    const userData = event && event.data;
    this.userId = event && event.data && event.data.id
    if(event && event.data) {
      this.formInitialize(userData);
      if(this.dialogDisplay) {
        this.dialog && this.dialog.open(this.dialogDisplay, { disableClose: true, width: '550px' });
      }
    }
  }

  /**
   * Initializes the user form group.
   * If user data is provided, the form is pre-filled with this data.
   * @param { userTableData } userData - The optional user data to pre-fill the form.
   */
  formInitialize(userData?: userTableData) {
    this.userFormGroup = new FormGroup({
      firstName: new FormControl(userData && userData.firstName ? userData.firstName : null, [Validators.required]),
      lastName: new FormControl(userData && userData.lastName ? userData.lastName : null, [Validators.required]),
      email: new FormControl(userData && userData.email ? userData.email : null, 
        [Validators.required, Validators.email]),
      // password: new FormControl(userData && userData.password ? userData.password : null, [Validators.required]),
    })
  }

  /**
   * Handles the create action for a new user.
   * Opens a dialog with an empty form for creating a new user.
   */
  onCreateClick(): void {
    this.isCreateMode = true;
    this.formInitialize();
    if(this.dialogDisplay) {
      this.dialog && this.dialog.open(this.dialogDisplay, { disableClose: true, width: '550px' });
    }
  }

  /**
   * Method to close the dialog box and resetting the form in the dialogbox
   * @returns { void }
   */
  dialogClose(): void {
    if (this.userFormGroup && !this.userFormGroup.pristine) {
      this.setDialogData(this.dialogHeader && this.dialogHeader.areYouSure, this.dialogType && this.dialogType.confirmation, 
        {left: "Stay", right: "Yes, Leave"}, this.message && this.message.unsavedChanges).subscribe(result => {
          if (result) {
            this.dialogReset();
          }
        });
    } else {
      this.dialogReset();
    }
    this.isCreateMode = false;
    this.isEditMode = false;
  }

  /**
   * Resets the dialog and user form.
   * If the dialog exists, closes all open dialogs.
   * If the userFormGroup exists, resets the form to its initial state.
   */
  dialogReset() {
    this.dialog && this.dialog.closeAll();
    this.userFormGroup && this.userFormGroup.reset();
  }

  /**
  * Method used to set data for the dialog box.
  * @param {string} header - The header text for the dialog box.
  * @param {string} action - The method name that needs to be executed.
  * @param {Object} buttonData - Text for the buttons in the dialog box.
  * @param {string} [buttonData.right] - Text for the right button.
  * @param {string} [buttonData.left] - Text color for the left button.
  * @param {string} [messageData] - Optional custom message data.
  * @param {deleteCustomerDetail} [deleteCustomerResponse] - The API response data for deleting customer details.
  * @returns {void}
  */
  setDialogData(header: string, action: string, buttonData: {right ?: string; left?: string}, messageData?: string, deleteUserResponse?: DeleteUserDetail){
    const dialogData = {
      header: header,
      message: messageData,
      actionType: action,
      button: buttonData,
      disableClose: true
    }
    if(deleteUserResponse && dialogData) {
      dialogData.message = (deleteUserResponse && deleteUserResponse.success) ? deleteUserResponse.message : deleteUserResponse.error && deleteUserResponse.error.message  || messageData
    }
    return this.dialogService && this.dialogService.openDialog(dialogData);
  }

  /**
  * Method which is used for table Pagination.
  * @param event It has the Pagination value which is emitted.
  */
  handlePaginationChange(event: PaginationData): void {
    this.userListLoader = true;
    if(this.paginationData) {
      this.paginationData.limit = event.limit;
      this.paginationData.offset = event.offset;
    }
    const data = this.paramAssign();
    this.gettingUserList(data);
  } 
  /**
  * Method which is used for searching the table.
  * @param event It has the searched string which is emitted.
  */
  handleSearchChange(event: string): void {
    this.userListLoader = true;
    this.searchData = event;
    const data = this.paramAssign();
    if(data)
      data.offset = 0
    this.gettingUserList(data);
  }

  /**
  * Method which is used to emit value while using filter.
  * @param event It has the emitted value from the filter.
  */
  emittedEvent(event: EmittedData) {
    this.userListLoader = true;
    this.filterValue = event;
    const data = this.paramAssign();
    if(data)
      data.offset = 0;
    this.paginationData = {
      ...this.paginationData,
      offset:  0
    };
    this.gettingUserList(data);
  }
  
  /**
   * Method used to assign total applied filters as number.
   * @param { number } totalAppliedFilters 
   */
  appliedFiltersLength(totalAppliedFilters: number): void {
    this.appliedFilters = totalAppliedFilters;
  }

  /**
  * Method used to assign parameters for the user list response.
  * @returns { UserListParams } - The parameters for the user list API call.
  * @description This method cleans the filter values by removing null values, 
  * and constructs the parameters object with pagination, sorting, and filter data. 
  * If search data is present, it adds it to the parameters.
  */
  paramAssign(): UserListParams {
    let data: UserListParams = {
      offset: this.paginationData && this.paginationData.offset,
      limit: this.paginationData && this.paginationData.limit
    }
    let cleanedFilterValue = {};
    if (this.isFilter) {
      cleanedFilterValue = this.removeNullValues(this.filterValue);
    }
    if (this.searchData && data) {
      data.searchData = this.searchData;
    }
    if (Object.keys(cleanedFilterValue) && Object.keys(cleanedFilterValue).length > 0 && data) {
      data.filterData = JSON.stringify(cleanedFilterValue);
    }
    return data;
  }
  
  /**
  * This method removes any null or undefined objects in the filterValue.
  * @param { NullFilter } filterValue 
  * @returns { NullFilter }
  */
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue) && Object.keys(filterValue)
    .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
    .reduce((acc: NullFilter, key: string) => {
      if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
        const cleanedValue = this.removeNullValues(filterValue[key]);
        if (Object.keys(cleanedValue) && Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else {
        acc[key] = filterValue[key];
      }
      return acc;
    }, {});
  }
    
  /**
  * Method which is used to perform filtering in the table.
   * @param event It has the event of filter in the table.
   */
  handleOptionalEvent(event: OptionalEvent): void {
    if (event && event.eventName === 'onFilter' && this.isFilterClicked) {
      this.onFilter();
    }
  }
  /**
  * Method which is used to assign the filter data to the table.
  */
  onFilter(): void {
    this.filterDetails = [{ title: 'User Status', type: FilterType.Select , options: this.filterOption, field: 'isSuspend', displayName: 'name', valueName: 'id' }]
    if (this.data.length) {
      this.isFilterValue = true;
      this.isFilter= true;
    }
  }
  /**
  * Method used to close the filter tab and reset any active filters.
  * @returns { void }
  */
  closeFilter(): void{
    this.isFilterValue = !this.isFilterValue;
    setTimeout(() => {
      this.isFilter = !this.isFilter
      const data = this.paramAssign();
      if(data)
        data.offset = 0;
      this.gettingUserList(data);
    }, 300)
  }

  /**
   * Used to update the form when the user clicks the update button.
   */
  onUpdate(): void {
    if(this.userFormGroup && this.userFormGroup.valid && this.userFormGroup.dirty){
        this.userListLoader = true;
        const userData = this.userFormGroup.value;
        if(userData)
          userData.id = this.userId;
        this.SubscriptionObject = this.userListService && this.userListService.updateUser(userData).subscribe({
          next: () => {
            this.snackbar && this.snackbar.OpenSnackBar({ message : USER_SNACKBAR_MESSAGES.userUpdateSuccess, 
              heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
            const param = this.paramAssign();
            this.gettingUserList(param);
            this.userListLoader = false;
            this.dialogReset();
            this.isEditMode = false;
          },
          error: (error: any) => {
            this.userListLoader = false;
            console.error("Error updating user", error);
            this.dialogReset();
            this.snackbar && this.snackbar.OpenSnackBar({ message : USER_SNACKBAR_MESSAGES.userUpdateFailed, 
              heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
            this.isEditMode = false;
          },
        })
      }
    else if(this.userFormGroup && !this.userFormGroup.dirty) {
      this.snackbar && this.snackbar.OpenSnackBar({
        message: SNACKBAR_MESSAGES.noChanges,
        heading: SNACKBAR_HEADING.warning,
        actionType: SNACKBAR_TYPE.warning,
      });
    } 
    else {
      this.snackbar && this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField,
        heading: SNACKBAR_HEADING.warning, actionType : SNACKBAR_TYPE.warning });
    }
  }

  /**
   * Used to submit the form when the user clicks the submit button.
   */
  onSubmit(): void {
    if(this.userFormGroup && this.userFormGroup.valid){
      this.userListLoader = true;
      this.SubscriptionObject = this.userListService && this.userListService.createUser(this.userFormGroup.value).subscribe({
        next: () => {
          this.snackbar && this.snackbar.OpenSnackBar({ message : USER_SNACKBAR_MESSAGES.userCreateSuccess, 
            heading: SNACKBAR_HEADING.success, actionType : SNACKBAR_TYPE.success });
          const param = this.paramAssign();
          this.gettingUserList(param);
          this.userListLoader = false;
          this.dialogReset();
          this.isCreateMode = false;
        },
        error: (error: any) => {
          this.userListLoader = false;
          this.snackbar && this.snackbar.OpenSnackBar({ message : USER_SNACKBAR_MESSAGES.userCreateFailed, 
            heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
          console.error("Error creating user", error);
          this.dialogReset();
          this.isCreateMode = false;
        }
      })
    } else {
      this.snackbar && this.snackbar.OpenSnackBar({ message : SNACKBAR_MESSAGES.mandatoryField, 
        heading: SNACKBAR_HEADING.warning, actionType : SNACKBAR_TYPE.warning });
    }
  }
  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }
  /**
  * Angular life cycle hook.
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}
