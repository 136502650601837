<div class="dashboard-container" fxLayout="column">
  <!-- Dashboard Banner -->
  <div class="dashboard-banner" fxLayout="row">
    <div fxFlex="50%" fxFlex.lt-sm="100%" fxFlex.lt-md="40%" fxLayout="column" fxLayoutAlign="center center" class="banner-text-section">
      <h5 data-control="dashboard-banner-text" class="dashboard-banner-text">
        Welcome To ZenMail
      </h5>
      <p data-control="dashboard-banner-subtext" class="dashboard-banner-subtext">
        A Personalized Email Service Designed Just for You!
      </p>
      <button mat-button data-control="banner-button" class="banner-button">Try Zen Mail</button>
    </div>
    <div class="banner-image-section" fxFlex="50%" fxFlex.lt-md="60%" fxHide.lt-sm fxLayoutAlign="center center">
      <img class="banner-image" data-control="banner-image" src="../../../../assets/banner.png" >
    </div>
   </div>
  <!-- Dashboard Card -->
  <div *ngIf="cardDetails" class="dashboard-card" fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center" fxLayoutGap="2%">
    <div class="card-container" *ngFor="let data of cardDetails;let id=index" fxLayoutAlign="end center" fxFlex.lt-md="80%" fxFlex.lt-sm="100%" fxFlex="48%" fxLayout="row">
      <div class="contentCardContainer mat-elevation-z6" fxLayoutAlign="center center" fxLayout="row" [ngStyle]="{'background-color':data?.contentCardColor}" [ngStyle.lt-sm]="{'min-height':'200px','background-color':data?.contentCardColor}">
        <div fxLayout="column" fxLayoutAlign="center center" class="content-container">
          <div *ngIf="data?.headingEnable" class="card-heading" [ngStyle.lt-sm]="{'text-align':'center'}" [attr.data-control]="'dashboard-card-heading'+id">
            {{data?.heading}}
          </div>
          <div class="card-text" [ngStyle.lt-sm]="{'font-size':'15px'}" [attr.data-control]="'dashboard-card-content'+id">
            {{data?.text}}
          </div>
          <div *ngIf="data?.buttonEnable">
            <button mat-button class="card-button" [attr.data-control]="'dashboard-card-button'+id" [ngStyle]="{'background-color':data?.buttonColor,'color':data?.buttonTextColor}">{{data?.buttonText}}</button>
          </div>
        </div>
        <div fxLayoutAlign="center center" class="cardImageContainer mat-elevation-z6" [ngStyle]="{'background-color':data?.imageCardColor}">
          <img  class="cardImage" [attr.data-control]="'dashboard-card-image'+id"  [src]="data?.imageUrl">
        </div>
      </div>
    </div>
  </div>
</div> 
