import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { GetApplicationList, GetUserListResponse, Applications, UserListData, NullFilter } from 'src/app/customers/models/customers.model';
import { CustomersService } from 'src/app/customers/services/customers.service';
import { ColumnType, FilterType, TEMPLATE_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { DIALOG_TYPE, DIALOG_HEADER, CONFIRM_MESSAGES } from 'src/app/shared/constants/common-dialog-data.constants';
import { columnDef, TableAction, SearchSetting, PaginationData, OptionalEvent, FilterData, SelectOptions, ActionClicked, DynamicDataSource } from 'src/app/shared/models/common-card-data.model';
import { DialogType, DialogHeader } from 'src/app/shared/models/common-dialog-data.model';
import { EmittedData } from 'src/app/shared/models/common-filter.model';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { ConfigurationService } from '../../services/configuration.service';
import { ConfigurationDeleteDialogParams, ConfigurationFilterValue, ConfigurationListParameters, Configurations, DeleteConfigurationDetail, GetConfigurationData } from '../../models/configuration.model';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { CONFIGURATION_SNACKBAR_MESSAGES } from '../../constants/configuration.constant';
import { SNACKBAR_HEADING, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { Heading, ButtonInfo } from 'src/app/shared/constants/common-header.constants';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-configuration-list',
  templateUrl: './configuration-list.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./configuration-list.component.scss']
})
export class ConfigurationListComponent {

  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Configurations",
    description : ' Manage mail delivery settings with options to create, edit, and delete configurations.',
    fontWeight : '500'
  }

  /**
   * Variable has button data to be displayed on commen header.
   * @type {Heading}
   */
  button:ButtonInfo[] = [{
    color : '#ffffff',    
    name : 'Create',
    method : 'onCreateClick',
    disabled : false,
    iconClass : 'outline',
    iconToolTip : 'btn',
    class : 'primary-button'
  }]

  /**
  * Variable used to store the table column definition.
  * @type {Array<columnDef>}
  */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox ,columnWidth: '7%',columnAlign:'start'},
    { field: 'server', header: 'Server', type: ColumnType.Text,columnAlign:'center', columnWidth: '15%', isDefault: true},
    { field: 'userMail', header: 'User Name', type: ColumnType.Text,columnAlign:'start' },
    { field: 'from', header: 'Email', type: ColumnType.Text,columnAlign:'start'},
    { field: 'portNumber', header: 'Port', type: ColumnType.Chip, columnWidth: '20%' },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '15%', color: 'black' },
  ];
  /**
   * Variable used to display the configuration data.
   * @type {Configurations[]}
   */
  data!: Configurations[];
  /**
  * Variable used to store the table actions data.
  * @type {Array<TableAction>}
  */
  actions: Array<TableAction> = [
    { icon: 'edit', tooltip: 'Edit Configuration', method: 'onEdit', color: '#00b907' },
    { icon: 'delete', tooltip: 'Delete Configuration', method: 'onDelete', color: 'red' }
  ];
  /**
  * Variable used to store search settings data.
  * @type {SearchSetting}
  */
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'User name',
    appearance: 'outline'
  }
  /**
 * Variable used to store the table Pagination data.
 * @type {PaginationData}
 */
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
  }
  /**
 * Variable used to store the observables.
 * @type {Subscription}
 */
  subscriptionObject: Subscription = new Subscription();
  /**
  * Flag Variable used to indicate whether enable loader or not.
  * @type {boolean}
  */
  configirationListLoader: boolean = true;
  /**
    * Variable used to store the table filter data of optional event.
    * @type {Array<OptionalEvent>}
    */
  optionalEvent: Array<OptionalEvent> = [
    { eventName: 'onFilter', icon: 'filter_list', toolTip: 'Filter', class: 'filter', color: 'white' }
  ]
  /**
  * Variable used to store the searching text.
  * @type {string}
  */
  searchData: string = '';
  /**
  * Variable used to store the table filter data.
  * @type {ConfigurationFilterValue}
  */
  filterValue: ConfigurationFilterValue = {};
  /**
  * Variable used to store the details for filter.
  * @type {FilterData[]}
  */
  filterDetails!: FilterData[];
  /**
  * Variable used to show application filter options.
  * @type {SelectOptions[]}
  */
  filterApplicationOption: SelectOptions[] = [{}]
  /**
  * Variable used to show user filter options.
  * @type {SelectOptions[]}
  */
  filterUserOption: SelectOptions[] = [{}]
  /**
  * Flag Variable used to indicate whether enable filter or not.
  * @type {boolean}
  */
  isFilter: boolean = false;
  /**
   * @type {DialogType}
   * Assigning the constant DIALOG_TYPE to the dialogType variable.
   */
  dialogType: DialogType = DIALOG_TYPE;
  /**
   * @type {DialogHeader}
   * Assigning the constant DIALOG_HEADER to the dialogHeader variable.
   */
  dialogHeader: DialogHeader = DIALOG_HEADER;
  /**
   * Assigning the constant CONFIRM_MESSAGES to the dialogConfirmMessages variable.
   */
  dialogConfirmMessages = CONFIRM_MESSAGES;
  /**
  * Variable used to show primary user filter options.
  * @type {FilterData[]}
  */
  primaryFilterOptions?: FilterData;
  /**
   * Variable used to store the filter data.
   * @type {string}
   */
  primaryFilterData: string = '';
  
  isPrimary: boolean = false;
    /**
  * Variable used to store the isFilterValue.
  * @type {boolean}
  */
  isFilterValue:boolean=false;

  /**
   * Variable used to store no data message.
   * @type {string}
   */
  noDataMessage : string = TEMPLATE_MESSAGES.NO_RECORD_CONFIGURATION; 
    /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
    initialLoader:boolean=false;
    
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number = -1;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;

  /**
    * component constructor which is used to inject the required services.
    * @param customerService To refer to the CustomersService to get http response.
    * @param configurationService To refer to the ConfigurationService to get http response.
    * @param auth To refer to the AuthService to access the region subscription.
    * @param dialogService To refer to the CommonDialogService to get the dialog box for delete action.
    */
  constructor(private configurationService: ConfigurationService, private auth: AuthService, 
    private dialogService: CommonDialogService, private customerService: CustomersService, 
    private router: Router, private commonService: CommonService, private snackbar: CommonSnackbarService) { }

  /**
  * Sets the loader to true for the initial loading and makes the necessary API calls for the configuration list page.
  * Angular life cycle hook.
  * @returns {void}
  */
  ngOnInit(): void {
    this.subscriptionObject = this.auth && this.auth.region$
      .subscribe(() => {
        this.initialLoader=true;
        this.configirationListLoader = true;
        this.gettingUserList();
        this.getConfigurationList({ offset: 0, limit: 5 })
        this.gettingApplicaitonList();
        this.appliedFilters = -1;
        this.isFilter = false;
      });
    this.commonService.isFilterClicked.subscribe(res =>{
      this.isFilterClicked = res;
    })
  }

  /**
  * Method used to get the list of all configurations.
  * @param {ConfigurationListParameters} params - Parameters for pagination, sorting, searching, and filtering.
  * @returns {void}
  */
  getConfigurationList(params: ConfigurationListParameters): void {
    this.subscriptionObject = this.configurationService && this.configurationService.getConfigurationList(params).subscribe({
      next: (res: GetConfigurationData) => {
        if(res) {
          this.configirationListLoader = false;
          this.data = res && res.data && res.data.rows;
          this.paginationData.count =  res && res.data && res.data.count;
          this.initialLoader=false;
        }
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationFetchFailed, heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching configuration list', err);
        this.initialLoader=false;
        this.configirationListLoader = false;
      }
    });
  }

  /**
  * Method used to get the applications list and set filter options.
  * @returns {void}
  */
  gettingApplicaitonList(): void {
    this.subscriptionObject = this.customerService && this.customerService.getApplicationList({}).subscribe({
      next: (res: GetApplicationList) => {
        this.configirationListLoader = false;
        this.setFilterOptionApplication(res && res.applicationData && res.applicationData.rows);
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationApplicationFetchFailed, heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching applications list', err);
        this.configirationListLoader = false;
      }
    })
  }

  /**
  * Method which is used retrive application details from backend.
  */
  gettingUserList(): void {
    this.subscriptionObject = this.customerService && this.customerService.getUserList({}).subscribe({
      next:(res: GetUserListResponse) => {
        this.setFilterOptionUser(res && res.response && res.response.rows);
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationuUserFetchFailed, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
          console.error('Error fetching user list', err);
      }
    })
  }

  /**
  * Method used to handle action events triggered from the UI.
  * @param {ActionClicked<DynamicDataSource>} event - The event object containing the method name and data 
  * associated with the action.
  * @returns {void}
  */
  onActionClicked(event: ActionClicked<DynamicDataSource>): void {
    (this as DynamicDataSource)[event.method](event);
  }

  /**
  * Method which is used to delete user data.
  * @param { ConfigurationDeleteDialogParams } dataToBeDeleted It has the user data that have to be deleted.
  * @returns {void}
  */
  onDelete(dataToBeDeleted : ConfigurationDeleteDialogParams): void{
    this.setDialogData( this.dialogHeader && this.dialogHeader.confirmation, this.dialogType && this.dialogType.confirmation, 
      { left: 'No', right: 'Yes, Delete' }, this.dialogConfirmMessages && this.dialogConfirmMessages.deleteConfiguration).subscribe(( dialogResult: boolean | string ) => {
      if(dialogResult){
        this.configurationService && this.configurationService.deleteConfiguration(dataToBeDeleted && dataToBeDeleted.data && dataToBeDeleted.data.id).subscribe({
          next: (deleteConfigurationResponse: DeleteConfigurationDetail) => {
          if(deleteConfigurationResponse && deleteConfigurationResponse.success) {
            this.setDialogData( this.dialogHeader && this.dialogHeader.success , this.dialogType && this.dialogType.success , { right: 'Okay' } , '' , deleteConfigurationResponse );
          }
          else {
            this.setDialogData(this.dialogHeader && this.dialogHeader.failure , this.dialogType && this.dialogType.failure , { right: 'Okay'} , '' , deleteConfigurationResponse);
          }
        },
        error: (err: string) => {
          console.error(err);
          this.snackbar && this.snackbar.OpenSnackBar({ message : CONFIGURATION_SNACKBAR_MESSAGES.configurationFailedToDelete, 
            heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        },
        complete : () => {
          const param = this.paramAssign();
          param.offset = 0;
          this.getConfigurationList(param);
        }
      })
      }
    })
  }

  
  /**
  * Method used to set data for the dialog box.
  * @param {string} header - The header text for the dialog box.
  * @param {string} action - The method name that needs to be executed.
  * @param {Object} buttonData - Text for the buttons in the dialog box.
  * @param {string} [buttonData.right] - Text for the right button.
  * @param {string} [buttonData.left] - Text color for the left button.
  * @param {string} [messageData] - Optional custom message data.
  * @param {DeleteConfigurationDetail} [deleteConfigurationResponse] - The API response data for deleting configuration details.
  * @returns {void}
  */
  setDialogData(header : string, action : string, buttonData : {right ?: string; left?: string}, messageData ?: string, deleteConfigurationResponse?: DeleteConfigurationDetail){
    return this.dialogService && this.dialogService.openDialog({
      header: header,
      message: (deleteConfigurationResponse && deleteConfigurationResponse.success) ? (deleteConfigurationResponse.message) 
        : (deleteConfigurationResponse && deleteConfigurationResponse.error && deleteConfigurationResponse.error.message)  || messageData,
      actionType: action,
      button: buttonData
    })
  }

  onEdit(event:any){
    this.router && this.router.navigate(['/app/mail/configuration/', 'edit', this.commonService && this.commonService.setParamsObj(event && event.data && event.data.id)]);
  }

  onCreateClick() {
    this.router && this.router.navigate(['/app/mail/configuration/' , 'create']);
  }

  /**
  * Method used to set filter options based on the application list data.
  * @param {applications[]} applicationListData - The list of application data.
  * @type {void}
  */
  setFilterOptionApplication(applicationListData: Applications[]): void {
    let primaryFilterOptions: SelectOptions[] = [{name: 'All', id: 0}];
    applicationListData && applicationListData.map((applicationData: Applications, index: number) => {
      primaryFilterOptions[index + 1] = {'name': applicationData && applicationData.applicationName, 'id': applicationData && applicationData.id}
    })
    if(applicationListData && applicationListData.length){
      this.primaryFilterOptions = { title: 'Application', type: FilterType.Select , options: primaryFilterOptions, field: 'applicationId', displayName: 'name', valueName: 'id' };
      this.isPrimary = true;
    }
  }

  /**
  * Method use to set filterOption value from backend for user list data
  * @param { UserListData[] } userListData has user list data in a array format .
  */
  setFilterOptionUser(userListData: UserListData[]) : void{
    userListData && userListData.forEach((userData: UserListData, index : number) => {
      this.filterUserOption[index] = {'name' : userData && userData.firstName + userData && userData.lastName , 'value' : userData && userData.id}
    })
  }

  /**
  * Method which is used to emit value while using filter.
  * @param {EmittedData} event It has the emitted value from the filter.
  * @returns {void}
  */
  emittedEvent(event: EmittedData): void {
    this.configirationListLoader = true;
    this.filterValue = event;
    const data = this.paramAssign();
    data.offset = 0;
    this.paginationData = {
      ...this.paginationData,
      offset:  0
    };
    this.getConfigurationList(data)
  }
  
  /**
   * Method used to assign total applied filters as number.
   * @param { number } totalAppliedFilters 
   */
  appliedFiltersLength(totalAppliedFilters: number): void {
    this.appliedFilters = totalAppliedFilters;
  }
  

  /**
  * Method used to assign parameters for the configuration list response.
  * @returns {ConfigurationListParameters} - The parameters for the configuration list API call.
  * @description This method cleans the filter values by removing null values, 
  * and constructs the parameters object with pagination, sorting, and filter data. 
  * If search data is present, it adds it to the parameters.
  */
  paramAssign(): ConfigurationListParameters {
    let data: ConfigurationListParameters = {
      offset: this.paginationData && this.paginationData.offset,
      limit: this.paginationData && this.paginationData.limit
    }
    let cleanedFilterValue = {};
    let combinedFilterValue = {};
    if (this.isFilter) {
      cleanedFilterValue = this.removeNullValues(this.filterValue);
    }
    if (this.primaryFilterData) {
      const existingFilterData = JSON.parse(this.primaryFilterData);
      combinedFilterValue = { ...existingFilterData, ...cleanedFilterValue };
    } else {
      combinedFilterValue = cleanedFilterValue;
    }
    if (this.searchData) {
      data.searchText = this.searchData;
    }
    if (Object.keys(combinedFilterValue) && Object.keys(combinedFilterValue).length > 0) {
      data.filterData = JSON.stringify(combinedFilterValue);
    }
    return data;
  }

  /**
  * This method removes any null or undefined objects in the filterValue.
  * @param { NullFilter } filterValue 
  * @returns { NullFilter }
  */
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue) && Object.keys(filterValue)
    .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
    .reduce((acc: NullFilter, key: string) => {
      if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
        const cleanedValue = this.removeNullValues(filterValue[key]);
        if (Object.keys(cleanedValue) && Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else {
        acc[key] = filterValue[key];
      }
      return acc;
    }, {});
  }

  /**
  * Method which is used for table Pagination.
  * @param event It has the Pagination value which is emitted.
  */
  handlePaginationChange(event: PaginationData): void {
    this.configirationListLoader = true;
    this.paginationData.limit = event && event.limit;
    this.paginationData.offset = event && event.offset;
    const data = this.paramAssign();
    this.getConfigurationList(data);
  }

  /**
  * Method which is used for searching the table.
  * @param event It has the searched string which is emitted.
  */
  handleSearchChange(event: string): void {
    this.configirationListLoader = true;
    this.searchData = event;
    const data = this.paramAssign();
    data.offset = 0
    this.getConfigurationList(data);
  }

  /**
  * Method which is used to perform filtering in the table.
  * @param event It has the event of filter in the table.
  */
  handleOptionalEvent(event: OptionalEvent): void {
    if (event && event.eventName === 'onFilter' && this.isFilterClicked) {
      this.onFilter();
    }
  }

  /**
  * Method which is used to assign the filter data to the table.
  */
  onFilter(): void {
    this.filterDetails = [
      { title: 'User Name', type: FilterType.Select, options: this.filterUserOption, field: 'userId', displayName: 'name', valueName: 'value' },
      { title: 'Date', type: FilterType.Date, field: 'customDateRange', valueName: 'value'}
    ]
    if (this.data && this.data.length) {
      this.isFilterValue = true;
      this.isFilter= true;
    }
  }

  /**
  * Method used to close the filter tab and reset any active filters.
  * @type {void}
  */
  closeFilter(): void{
    this.isFilterValue = !this.isFilterValue;
    setTimeout(() => {
     this.isFilter = !this.isFilter;
     const data = this.paramAssign();  
     data.offset = 0;
     this.getConfigurationList(data);
    }, 300); 
   }

  /**
   * Handles the event when a primary ID is selected from the dropdown.
   * @param { SelectOptions } userId 
   * @returns { void }
   */
  primaryDropdownEvent(userId: SelectOptions): void{
    this.configirationListLoader = true;
    this.primaryFilterData = JSON.stringify(this.removeNullValues(userId));
    const data = this.paramAssign();
    data.offset = 0;
    this.getConfigurationList(data);
  }

  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }

  /**
  * Angular life cycle hook.
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }
}
