<div fxLayout="column" style="margin-left: 20px;">
<mat-card class="skeleton-header" fxFlex="100%" fxLayoutAlign="space-between" >
  <div fxLayout="row"  fxFlex="100%">
    <div fxLayout="column" fxFlex="50%" fxLayoutGap="3%">
      <div class="skeleton-header-label1"></div>
      <div class="skeleton-header-label2"></div>
    </div>
    <div fxFlex="50%" fxLayoutAlign="end center">
      <div class="skeleton-header-button1"></div>
    </div>
  </div>
</mat-card>

<mat-card class="skeleton-list" *ngIf="!formLoader">
  <div fxLayout="row" fxLayoutGap="3%">
    <div fxFlex="30%" fxLayoutAlign="start start">
    <div class="skeleton-avatar0" ></div>
  </div>
  <div fxFlex="70%" fxLayoutAlign="end end">
    <div class="skeleton-avatar6"></div>
  </div>
  </div>
  <div fxLayout="row" fxLayoutGap="2%">
    <div class="skeleton-avatar5"></div>
  </div>
  <div class="skeleton-item" *ngFor="let item of items">
    <div fxLayout="row" fxFlex="100%" fxLayoutGap="7%"  fxLayout.xs="column" fxLayoutAlign.xs="center center" fxLayoutGap.xs="7%" [ngStyle]="{'padding-top':'8px','padding-bottom':'8px'}">
      <div class="skeleton-avatar1" [ngStyle.xs]="{'height':'100px','width':'10%'}"></div>
      <div class="skeleton-avatar2" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
      <div class="skeleton-avatar3" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
      <div class="skeleton-avatar4" fxFlex.xs="100%" [ngStyle.xs]="{'height':'60px','width':'70%'}"></div>
    </div>
  </div>
</mat-card>
  <mat-card *ngIf="formLoader" class="skeleton-form" fxLayout="column">
    <div class="skeleton-form-header"></div>
    <div fxLayout="row" fxLayoutGap="4%" fxFlex="100%" [ngStyle]="{'padding-top':'50px','padding-left':'30px'}" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
      <div class="skeleton-form-header1" fxFlex="30%"></div>
      <div fxFlex="60%" fxLayoutGap="4%" fxLayoutAlign="end center">
        <div class="skeleton-form-button1"></div>
        <div class="skeleton-form-button2"></div>
      </div>
    </div>
    <div [ngStyle]="{'padding-top':'50px','padding-left':'30px'}" [ngStyle.lt-sm]="{'padding-top':'30px','padding-left':'1px'}">
      <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="5%" [ngStyle]="{'row-gap':'30px'}">
        <div *ngFor="let item of items_form" fxFlex="45%" fxFlex.lt-sm="100%">
          <div fxLayout="column" fxLayoutGap="2%" fxFlex="100%">
            <div class="skeleton-form-label1"></div>
            <div class="skeleton-form-field2"></div>
          </div>
        </div>
      </div>
    </div>
</mat-card>
</div>