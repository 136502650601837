import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import { AddEditCustomers } from './components/add-edit-customers/add-edit-customers.component';


@NgModule({
  declarations: [
    AddEditCustomers
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    HttpClientModule
  ],
  providers : [
    SharedModule
  ]
})
export class CustomersModule { }
