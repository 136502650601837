import { Component, Input } from '@angular/core';
import { ApplicationListParams, applicationFilterData, applicationsTableData, deleteApplicationResponseStructure, getApplicationResponse, getUserListResponse } from 'src/app/zen-mail/Applications/models/applications.model';
import { ColumnType, TEMPLATE_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { EmittedData, FilterData, SelectOptions } from 'src/app/shared/models/common-filter.model';
import { FilterType } from 'src/app/shared/constants/common-filter-data.contants';
import { Subscription } from 'rxjs';
import { ActionClicked, DynamicDataSource, OptionalEvent, PaginationData, SearchSetting, TableAction, columnDef } from 'src/app/shared/models/common-card-data.model';
import { AuthService } from 'src/app/auth/service/auth.service';
import { userListData } from 'src/app/zen-mail/Applications/models/applications.model';
import { CommonDialogService } from 'src/app/shared/service/common-dialog.service';
import { CONFIRM_MESSAGES, DIALOG_HEADER } from 'src/app/shared/constants/common-dialog-data.constants';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { snackber_message } from 'src/app/zen-mail/Applications/constants/application.constants';
import { NullFilter } from 'src/app/zen-mail/Reports/models/reports.model';
import { ButtonInfo, Heading } from 'src/app/shared/constants/common-header.constants';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ApplicationsService } from '../../service/applications.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent {
  // Flag indicating whether to show the search field
  hasSearchField: boolean = true;

  /**
   * Variable used to store the table data.
   * @type {applicationsTableData[]}
   */
  data!: applicationsTableData[];

  /**
   * Variable used to store the searching text.
   * @type {string}
   */
  searchData: string = '';

  /**
   * Variable used to store the filter data.
   * @type {string}
   */
  filterData: string = '';

  /**
   * Variable used to store the details for filters.
   * @type {FilterData[]}
   */
  filterDetails!: FilterData[];

  /**
   * Flag indicating whether filtering is enabled.
   * @type {boolean}
   */
  isFilter: boolean = false;

  /**
   * Subscription object to manage observables.
   * @type {Subscription}
   */
  SubscriptionObject: Subscription = new Subscription();

  /**
   * Flag indicating whether to show loader for applications list.
   * @type {boolean}
   */
  applicationsListLoader: boolean = true;

  /**
   * Primary filter data for the table.
   * @type {FilterData}
   */
  primaryFilterData!: FilterData;

  /**
   * Actions available for each row in the table.
   * @type {Array<TableAction>}
   */
  actions: Array<TableAction> = [
    { icon: 'visibility', tooltip: 'View', method: 'viewData', color: '#0068b5' },
    { icon: 'edit', tooltip: 'Edit', method: 'onEdit', color: '#00b907' },
    { icon: 'delete', tooltip: 'Delete', method: 'onDelete', color: 'red' }
  ];

  /**
   * Optional events available for the table.
   * @type {Array<OptionalEvent>}
   */
  optionalEvent: Array<OptionalEvent> = [
    { eventName: 'onFilter', icon: 'filter_list', toolTip: 'Filter', class: 'filter', color: 'white' }
  ]

  /**
   * Settings for the search field.
   * @type {SearchSetting}
   */
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'Search Application',
    appearance: 'outline'
  };

  /**
   * Flag indicating whether the filter is active.
   * @type {boolean}
   */
  isFilterValue: boolean = false;

  /**
   * Filter values for the table.
   * @type {applicationFilterData}
   */
  filterValue: applicationFilterData = { 'id': '' };

  /**
   * Pagination settings for the table.
   * @type {PaginationData}
   */
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
  };

  /**
   * Select options for the filter.
   * @type {SelectOptions[]}
   */
  filterOption: SelectOptions[] = [];

  /**
   * Column definitions for the table.
   * @type {Array<columnDef>}
   */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox, columnWidth: '10%', columnAlign: 'start' },
    { field: 'applicationName', header: 'Application Name', type: ColumnType.Text, columnAlign: 'start' },
    { field: 'description', header: 'Description', type: ColumnType.Text, columnAlign: 'start' },
    { field: 'createdAt', header: 'Created On', type: ColumnType.Date, format: "dd MMM, yyyy" },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '15%', color: 'black' },
  ];

  /**
   * Message to display when no data is available.
   * @type {string}
   */
  noDataMessage: string = TEMPLATE_MESSAGES.NO_RECORD_APPLICATION;

  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Applications",
    description : 'Manage applications with options to create, edit, view, and delete efficiently.',
    fontWeight : '500'
  }

  /**
   * Variable has button data to be displayed on commen header.
   * @type {Heading}
   */
  button:ButtonInfo[] = [{
    color : '#ffffff',    
    name : 'Create',
    method : 'onCreate',
    disabled : false,
    iconClass : 'outline',
    iconToolTip : 'btn',
    class : 'primary-button'
  }]
  /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;
  
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number = -1;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;

  /**
   * Component constructor to inject required services.
   * @param snackbar Snackbar service for displaying notifications.
   * @param commonService Common service for utility functions.
   * @param route Router service for navigation.
   * @param dialogService Dialog service for displaying dialogs.
   * @param applicationList Application service for API calls related to applications.
   * @param auth Authentication service for user authentication.
   */
  constructor(
    private snackbar: CommonSnackbarService,
    private commonService: CommonService,
    private route: Router,
    private dialogService: CommonDialogService,
    private applicationList: ApplicationsService,
    private auth: AuthService
  ) {}

  /**
   * Angular lifecycle hook called after component initialization.
   * Subscribes to region changes and fetches initial data.
   */
  ngAfterViewInit(){
    this.auth.region$.subscribe(() => {
      this.initialLoader=true;
      this.applicationsListLoader = true;
      this.gettingUserList();
      this.getApplicationList({});
      this.appliedFilters = -1;
      this.isFilter = false;
    });
    this.commonService.isFilterClicked.subscribe(res =>{
      this.isFilterClicked = res;
    })
  }
  /**
   * Method to fetch application list from backend.
   * @param params Pagination and search parameters.
   */
  getApplicationList(params: ApplicationListParams): void {
    this.SubscriptionObject = this.applicationList.getApplicationsList(params).subscribe({
      next: (res: getApplicationResponse) => {
        this.applicationsListLoader = false;
        this.data = res?.applicationData?.rows;
        this.paginationData.count = res?.applicationData?.count;
        this.initialLoader=false;
      },
      error: (err) => {
        this.snackbar.OpenSnackBar({ message: snackber_message.APPLICATION_FETCH_FAILED, actionType: 'failure' });
        console.error('Error fetching application list', err);
        this.initialLoader=false;
        this.applicationsListLoader = false;
      }
    });
  }

/**
 * Fetches the user list from the backend and updates the filter options.
 * Displays an error message if the fetch fails.
 */
  gettingUserList(): void {
    this.SubscriptionObject = this.applicationList.getUserList({}).subscribe({
      next: (res: getUserListResponse) => {
        this.setFilterOption(res?.response?.rows);
        this.applicationsListLoader = false;
      },
      error: (err) => {
        this.snackbar.OpenSnackBar({ message: snackber_message.USER_FETCH_FAILED, actionType: 'failure' });
        console.error('Error fetching user list', err);
        this.applicationsListLoader = false;
      }
    });
  }

  /**
   * Method to set filter options based on user list data.
   * @param userListData User list data from backend.
   */
  setFilterOption(userListData: userListData[]): void {
    userListData.forEach((userData: userListData, index: number) => {
      this.filterOption[index] = ({ name: userData?.firstName + userData?.lastName, id: userData?.id });
    });
  }

  /**
   * Handles pagination changes.
   * @param event - Pagination data.
   */
  handlePaginationChange(event: PaginationData): void {
    this.applicationsListLoader = true;
    this.paginationData.limit = event.limit;
    this.paginationData.offset = event.offset;
    const data = this.paramAssign();
    this.getApplicationList(data);
  }

  /**
   * Method to handle search input changes.
   * @param event Searched string.
   */
  handleSearchChange(event: string): void {
    this.applicationsListLoader = true;
    this.searchData = event;
    const data = this.paramAssign();
    data.offset = 0;
    this.getApplicationList(data);
  }

  /**
   * Method to handle optional events like filtering.
   * @param event Optional event emitted.
   */
  handleOptionalEvent(event: OptionalEvent): void {
    if (event && event.eventName === 'onFilter' && this.isFilterClicked) {
      this.onFilter();
    }
  }

  /**
   * Method to toggle the filter panel.
   */
  onFilter(): void {
    this.filterDetails = [{ title: 'User', type: FilterType.Select, options: this.filterOption, field: 'id', displayName: 'name', valueName: 'id' }];
    if (this.data.length) {
      if (this.data.length) {
        this.isFilterValue = true;
        this.isFilter= true;
      }
    }
  }

  /**
   * Method to close the filter panel.
   */
  closeFilter(): void {
    this.isFilterValue = !this.isFilterValue;
    setTimeout(() => {
      this.applicationsListLoader = true;
      this.isFilter = !this.isFilter
      const data = this.paramAssign();
      data.offset = 0;
      this.getApplicationList(data);
    }, 300)
  }

  /**
   * Method called when a filter value is emitted.
   * @param event Emitted filter data.
   */
  emittedEvent(event: EmittedData): void {
    this.applicationsListLoader= true;
    this.filterValue = event;
    const data = this.paramAssign();
    data.offset = 0;
    this.paginationData = {
      ...this.paginationData,
      offset:  0
    };
    if (this.filterValue.id) {
      data.userId = this.filterValue.id;
    }
    this.getApplicationList(data);

  }
  
  /**
   * Method used to assign total applied filters as number.
   * @param { number } totalAppliedFilters 
   */
  appliedFiltersLength(totalAppliedFilters: number): void {
    this.appliedFilters = totalAppliedFilters;
  }

  /**
   * Method to open a dialog based on user action.
   * @param event Action clicked and related data.
   */
  openDialog(event: ActionClicked<DynamicDataSource>): void {
    (this as DynamicDataSource)[event.method](event.data);
  }

  /**
   * Method to view application details.
   * @param data Application data to view.
   */
  viewData(data: DynamicDataSource): void {
    this.route.navigate(['/app/mail/editApplication', this.commonService.setParamsObj(data['id']), 'view']);
  }

  /**
   * Method to edit application details.
   * @param data Application data to edit.
   */
  onEdit(data: DynamicDataSource): void {
    this.route.navigate(['/app/mail/editApplication', this.commonService.setParamsObj(data['id']), 'edit']);
  }

  /**
   * Method to navigate to create new application.
   */
  onCreate(): void {
    this.route.navigate(['/app/mail/editApplication', 'create']);
  }

  /**
   * Method to delete an application.
   * @param dataToBeDeleted Application data to delete.
   */
  onDelete(dataToBeDeleted: applicationsTableData): void {
    this.setDialogData(DIALOG_HEADER.confirmation, 'verification', { left: 'No', right: 'Yes, Delete' }, CONFIRM_MESSAGES?.deleteApplication).subscribe((dialogResult: boolean | String) => {
      if (dialogResult) {
        this.applicationList?.deleteApplication(dataToBeDeleted.id, '' + dialogResult)?.subscribe({
          next: (deleteApplicationResponse: deleteApplicationResponseStructure) => {
            if (deleteApplicationResponse.success) {
              this.setDialogData(DIALOG_HEADER.success, 'success', { right: 'Okay' }, '', deleteApplicationResponse);
            } else {
              this.setDialogData(DIALOG_HEADER.failure, 'failure', { right: 'Okay' }, '', deleteApplicationResponse);
            }
          },
          error: (err) => {
            this.snackbar.OpenSnackBar({ message: snackber_message.APPLICATION_DELETION_FAILED, actionType: 'failure' });
            console.error(err);
          },
          complete: () => {
            this.getApplicationList({});
          }
        });
      }
    });
  }

  /**
   * Method to set dialog data for confirmation.
   * @param header Header of the dialog.
   * @param action Action type of the dialog.
   * @param buttonData Buttons for the dialog.
   * @param messageData Message to display in the dialog.
   * @param deleteApplicationResponse API response data.
   */
  setDialogData(header: string, action: string, buttonData: { right?: string; left?: string }, messageData?: string, deleteApplicationResponse?: deleteApplicationResponseStructure) {
    return this.dialogService?.openDialog({
      header: header,
      message: (deleteApplicationResponse?.success) ? deleteApplicationResponse?.message : deleteApplicationResponse?.error?.message || messageData,
      actionType: action,
      button: buttonData
    });
  }

  paramAssign(): ApplicationListParams {
    let data: ApplicationListParams = {
      offset: this.paginationData.offset,
      limit: this.paginationData.limit
    }
    let cleanedFilterValue = {};
    if (this.isFilter) {
      cleanedFilterValue = this.removeNullValues(this.filterValue);
    }
    if (this.searchData) {
      data.searchData = this.searchData;
    }
    if (Object.keys(cleanedFilterValue).length > 0) {
      data.filterData = JSON.stringify(cleanedFilterValue);
    }
    return data;
  }

  /**
  * This method removes any null or undefined objects in the filterValue.
  * @param { NullFilter } filterValue 
  * @returns { NullFilter }
  */  
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue)
    .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
    .reduce((acc: NullFilter, key: string) => {
      if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
        const cleanedValue = this.removeNullValues(filterValue[key]);
        if (Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else {
        acc[key] = filterValue[key];
      }
      return acc;
    }, {});
  }

  /**
   * Angular lifecycle hook called when component is destroyed.
   * Unsubscribes from subscriptions to avoid memory leaks.
   */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
  headerEvent(evnt : any){
    (this as DynamicDataSource)[evnt]();
  }
}
