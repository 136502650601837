
<div>
  <mat-card class="mat-card">
<div class="common-card-container" >
  <div class="common-card-filter-container" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="20px">
    <div fxLayout="row" fxFlex="50%" fxLayoutAlign="start start">
    <ng-container *ngIf="hasSearchField">
      <form class="form">
        <mat-form-field class="search-box" [@openClose]="isSearch ? 'open' : 'closed'"
        [appearance]="(searchSetting && searchSetting.appearance) || 'outline'">
          <mat-label>Search</mat-label>
          <input type="text" matInput [formControl]="searchQuery"  data-control="searchInput"
            [placeholder]="(searchSetting && searchSetting.placeHolder) || ''">
            <mat-icon class="search-icon" data-control="searchIcon" matSuffix *ngIf="isSearch" (click)="openSearch()">search</mat-icon>
        </mat-form-field>
        <div *ngIf="!isSearch" class="search-mat-icon">
        <mat-icon class="search-icon" data-control="searchIcon" matSuffix  [matTooltip]="'Search'" (click)="openSearch()">search</mat-icon>
      </div>
      </form>
    </ng-container>
    </div>
    <div fxLayout="row" fxFlex="50%" fxLayoutGap="3%" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="start start">
    <form  [formGroup]="filterForm" *ngIf="hasPrimaryFilter && primaryFilterData" fxFlex.lt-sm="65%">
      <ng-container>
      <mat-form-field [ngClass]="isPrimaryFilterDisabled ? 'disabled' : '' " appearance="outline" class="control-container">
        <mat-select (selectionChange)="onSelect()" [placeholder]="primaryFilterData.title" [formControlName]="primaryFilterData.field" (closed)="onSelectorSearchFilterClose()">
          <!-- Container for search input for list of options-->
          <mat-form-field appearance="fill" class="select-input-container">
            <input  matInput placeholder="Search" [formControl]="selectorSearchFilter"
              data-control="filterSearchInput"
              (keyup)="onSelectorSearchFilter($event, primaryFilterData)"
              (keydown.space)="$event.stopPropagation();">
          </mat-form-field>
          <!-- Container for options to search input's value -->
          <ng-container *ngIf="selectionOptionValue.length >= 0 && isSelectorOptions">
            <mat-option *ngFor="let select of selectionOptionValue; let id = index" fxLayout="row"
              [value]="primaryFilterData.valueName ? select[primaryFilterData.valueName] : select"
              [attr.data-control]="primaryFilterData.field + 'matOption' + id">
              {{ selectionOptionValue && selectionOptionValue.length && primaryFilterData.displayName ?
              select[primaryFilterData.displayName] : select }}
            </mat-option>
            <div *ngIf="isSelectorOptions && selectionOptionValue.length <= 0" class="no-records-primary-dropdown"
            data-control="no-records-primary-dropdown">
            {{filterInformation.NO_RECORD}}
          </div>
          </ng-container>
          <!-- Container for normal options -->
          <ng-container *ngIf="!isSelectorOptions">
            <mat-option *ngFor="let select of primaryFilterData.options; let id = index" fxLayout="row"
              [value]="primaryFilterData.valueName ? select[primaryFilterData.valueName] : select"
              [attr.data-control]="primaryFilterData.field + 'matOption' + id">
              {{ primaryFilterData.displayName ? select[primaryFilterData.displayName] : select }}
            </mat-option>
            <div *ngIf="isSelectorOptions && selectionOptionValue.length <= 0" class="no-records-primary-dropdown"
              data-control="no-records-primary-dropdown">
              {{filterInformation.NO_RECORD}}
            </div>
          </ng-container>
        </mat-select>
      </mat-form-field>
      </ng-container>    
    </form>
    <div *ngIf="sortSetting && sortSetting.length > 0">
      <mat-icon class="sort-icon"  [matTooltip]="'sort'"  [matMenuTriggerFor]="menu" data-control="sortIcon" matSuffix>swap_vert</mat-icon>
      <mat-menu #menu="matMenu">
        <ng-container >
          <ng-container *ngFor="let sortData of sortSetting">
            <button (click)="onSortChange(sortData)" mat-menu-item
              [ngClass]="sortData.label === (selectedSort && selectedSort.label) ? 'selectedSort':''">{{sortData.label}}</button>
          </ng-container>
        </ng-container>
      </mat-menu>
    </div>
    <ng-container *ngIf="optionalEvent && optionalEvent.length>0">
      <ng-container *ngFor="let item of optionalEvent;let i = index">
        <ng-container *ngIf="item?.eventName == 'onFilter', else optionalEvent">
        <ng-container *ngIf="appliedFilters >= 0 || (dataSource && dataSource.length > 0)">
          <div class="fitler-container" [matBadge]="appliedFilters > 0 ? appliedFilters : ''" matBadgeColor="accent" 
            (click)="onFilterClick(item)" fxLayout="row" [ngClass]="hasFilterAppeared ? 'initial' : isFilterClicked ? 'active' : 'inActive'" fxLayoutAlign="center center">
            <mat-icon class="fitler-icon" [ngClass]="item?.class" [style]="{'color':item?.color ? item?.color : 'var(--primary-color)'}" [attr.data-control]="item.eventName + i">{{item.icon}}</mat-icon>
            <div *ngIf="!isFilterClicked" class="filter-text">Filters</div>
          </div>
        </ng-container>
        </ng-container>
        <ng-template #optionalEvent>
          <mat-icon class="optional-event" (click)="optionalEventOccurred.emit(item)" [ngClass]="item?.class" [style]="{'color':item?.color ? item?.color : '#3e32f7'}" [matTooltip]="item?.toolTip ? item.toolTip :''"   [attr.data-control]="item.eventName + i">{{item.icon}}</mat-icon>
        </ng-template>
      </ng-container>
    </ng-container>
    </div>
  </div>
<div *ngIf="dataSource && dataSource.length > 0 ; else noRecordsFound">
  <div class="table-container">
    <div class="table-header zen-mail-default-card-colors" fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column" fxLayoutAlign="center center"
      [style]="typography.heading"
      [ngStyle]="{'color':colorSchema.headerColor,'background-color':colorSchema.headerBackground,'text-align':'center'}">
      <div *ngFor="let column of columns;let i = index" [attr.data-control]="'header' +i " [fxFlex]="column?.columnWidth ? column?.columnWidth : (100/flexibleColumnCount) + '%'" fxFlex.xs="100%">
        <div *ngIf="column.type == 'checkbox'" [ngStyle]="{'text-align':column.columnAlign??'center'}">
          <mat-checkbox (change)="masterCheckbox($event)" [disabled]="column?.disable" [aria-label]="column.field"
          [attr.data-control]="column.field +i" [checked]="isMasterCheckboxChecked"></mat-checkbox></div>
       <div [ngStyle]="{'text-align':column.columnAlign??'center'}">{{ column.header }}</div>
      </div>
    </div>
    <ng-container>
      <ng-container >
        <div class="table-row" *ngFor="let item of displayData;let itemIndex =index"
          [style.color]="itemIndex % 2==0?colorSchema.rowColor : colorSchema.evenRowColor"
          [style.backgroundColor]="itemIndex % 2==0?colorSchema.rowBackground :colorSchema.evenRowBackground"
          fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutAlign="center center" fxLayoutAlign.xs=""
          [ngStyle]="typography.row">
          <div *ngFor="let column of columns;let i = index" class="table-cell" [fxFlex]="column?.columnWidth ? column?.columnWidth : (100/flexibleColumnCount) + '%'" fxFlex.xs="100%"
            [style]="{'color':column?.color,'text-align':column.columnAlign??'center','display':'flex','padding': column.isDefault == true && item.id == 0 ? '5px 0px': '','place-content':column.columnAlign??'center'}" [ngStyle.xs]="{'color':column?.color,'text-align':'center','display':'flex', 'place-content':'center'}" [ngClass]="column?.class ? column?.class :''">
            <ng-container [ngSwitch]="column.type">
              <!-- Text type -->
              <ng-container *ngSwitchCase="columnType.Text">
                <div fxLayout="column" fxLayoutAlign="center center">
                  <div *ngIf="!loading; else loader" [matTooltip]="item[column.field]|removeUnderscore:(column.removeUnderscore)" [attr.data-control]="column.field +i "
                    [matTooltipDisabled]=" item[column.field] | toolTipDisable :20" class="line-display">
                    {{ item[column.field] || '-' |removeUnderscore:(column.removeUnderscore)}}
                  </div>
                  <div *ngIf="item.id == 0 && column.isDefault"> 
                    <div class="default-chip">Default</div>
                  </div>
                </div>
              </ng-container>
              <!-- Date type -->
              <ng-container *ngSwitchCase="columnType.Date">
                <div *ngIf="!loading; else loader" [matTooltip]="item[column.field]"
                  [attr.data-control]="column.field +i ">
                  {{ item[column.field] ? (item[column.field] | date: (column.format ? column.format : dateFormat)) : '-' }}
                </div>
              </ng-container>
              <!-- Image type -->
              <ng-container *ngSwitchCase="columnType.Image">
                <img *ngIf="!loading; else loader" [src]="item[column.field] ? item[column.field]: './assets/image-not-found.jpg'"
                  ngClass="table-cell__image-type" alt="no records found" [attr.data-control]="column.field +i "
                  onerror="this.src='./assets/image-not-found.jpg'" />
              </ng-container>
              <!-- Checkbox type -->
              <ng-container *ngSwitchCase="columnType.Checkbox">
                <mat-checkbox *ngIf="!loading; else loader" (click)="$event.stopPropagation()" (change)="singleCheckBoxClick($event,item)"
                  [checked]="isSingleCheckboxChecked[itemIndex]" [disabled]="column?.disable" [aria-label]="item[column.field]"
                  [attr.data-control]="column.field +i ">
                </mat-checkbox>
              </ng-container>
                 <!-- toggle type -->
                 <ng-container *ngSwitchCase="columnType.Toggle">
                  <mat-slide-toggle  *ngIf="!loading; else loader" [ngStyle]="{'text-align':!loading?'':'center'}" [checked]="item && item[column.toggleCondition]"  [disabled]="isDisableFunction(column.disable) ? !column.disable(item) : column.disable"
                  (change)="onToggleChange($event,item,column)"  [attr.data-control]="column.field +i ">
                  </mat-slide-toggle>
                </ng-container>
                 <!-- dropdown type -->
                <ng-container *ngSwitchCase="columnType.DropDown">
                  <div *ngIf="!loading; else loader" [ngStyle]="{'width':'auto','padding':'10px','background-color':dropDownSelectedColor(item, column).backgroundColor,'height':'2px','display':'flex','place-content':'center','align-items':'center','border-radius':'5px'}">                  
                    <mat-select placeholder="Choose one" [ngStyle]="{'color':dropDownSelectedColor(item, column).textColor ,'font-size':'12px'}" [value]="item && item[column.dropDownCondition]" (selectionChange)="onDropdownChange($event,item,column)">
                    <mat-option *ngFor="let option of column.dropDownOption" [value]="option.id" [ngStyle]="{'font-size':'12px'}">{{ option.name }}</mat-option>
                  </mat-select>
                </div>
                </ng-container>
              <!-- Chip type -->
              <ng-container *ngSwitchCase="columnType.Chip">
                <div *ngIf="!(item[column.field] == (null || undefined))" style="display: flex; justify-content: center; align-items: center;">
                  <mat-chip *ngIf="!loading; else loader" [disabled]="column?.disable" [aria-label]="item[column.field]" [attr.data-control]="column.field +i "
                    [ngStyle]="{'background-color': (column.displayBackgroundColor || column.backgroundColor) ?  getChipProperties(column.displayBackgroundColor ?? {}, item[column.field], column.backgroundColor) : '#3e32f7', 
                    '--mdc-chip-label-text-color':  (column.displayColor || column.color) ? getChipProperties(column.displayColor ?? {}, item[column.field], column.color) : '#FFF','height':'23px'}">
                      {{ column.displayText ? getChipProperties(column.displayText, item[column.field], item[column.field]) : item[column.field] }}
                  </mat-chip>
                </div>
                <div *ngIf="(item[column.field] == (null || undefined))">{{'-'}}</div>
              </ng-container>
              <!-- Action type -->
              <ng-container *ngSwitchCase="columnType.Action">
              <ng-container *ngIf="!loading && actionSetting && actionSetting.length > 0; else loader">
                <button *ngFor="let action of actionSetting" [attr.data-control]="column.field +i " class="action-button" [matTooltip]="action.tooltip" [ngStyle]="{'color':action?.color?action?.color:'#3498DB'}"
                (click)="onAction(action.method,item)">
                <i class="material-icons" [ngStyle]="{'font-size':'22px'}" *ngIf="!(action.icon == 'delete' && item.id == 0)">{{ action.icon }}</i>
              </button>
              </ng-container>
              </ng-container>
            </ng-container>
            <ng-template #loader>
              <div class="skeleton-list" [ngStyle]="{'width':column.type==='checkbox'?'40%':'100%'}" [ngStyle.xs]="{'padding-top':'6px','padding-bottom':'6px'}">
                <div class="skeleton-avatar2" [ngStyle]="{'margin-left':column.type==='checkbox'?'10px':''}" [ngStyle.xs]="{'height':'25px'}"></div>
              </div>             
            </ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="pagination_container">
    <ng-container *ngIf="hasPagination">
      <div class="paginator">
      <mat-paginator data-control="paginator" [length]="paginationData.count" [pageSize]="paginationData.limit" showFirstLastButtons
        [pageSizeOptions]="[5, 10, 25, 50]" aria-label="Select page" (page)="onPageChange($event)">
      </mat-paginator>
    </div>
    </ng-container>
  </div>
</div>
</div>
<ng-template #noRecordsFound>
  <div class="no-record-found" data-control="noRecordsFound" fxLayout="column" fxLayoutAlign="center center">
    <img src="../../../../assets/no_record_found.png" alt="noRecordsFound">
    <div class="no-record-found__text">{{dataNotFoundMessage}}</div>
  </div>
</ng-template>
</mat-card>
</div>
