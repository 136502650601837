import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { GetCustomersList, GetApplicationList, Applications, GetUserListResponse, Customers, UserListData, NullFilter } from 'src/app/customers/models/customers.model';
import { ColumnType, TEMPLATE_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { FilterType } from 'src/app/shared/constants/common-filter-data.contants';
import { ActionClicked, DynamicDataSource, OptionalEvent, PaginationData, SearchSetting, TableAction, columnDef } from 'src/app/shared/models/common-card-data.model';
import { FilterData, SelectOptions, EmittedData } from 'src/app/shared/models/common-filter.model';
import { LogService } from '../../service/log.service';
import { Router } from '@angular/router';
import { GetLogsList, LogsFilterData, LogsListParameters, LogsTableData } from '../../models/logs.model';
import { Category, CategoryResponse } from 'src/app/zen-mail/Categories/models/categories.model';
import { CommonSnackbarService } from 'src/app/shared/service/common-snackbar.service';
import { LOG_SNACKBAR_MESSAGES } from '../../constants/log-constant';
import { SNACKBAR_HEADING, SNACKBAR_TYPE } from 'src/app/shared/constants/common-snackbar-data.constants';
import { Heading } from 'src/app/shared/constants/common-header.constants';
import { CommonService } from 'src/app/shared/service/common.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  standalone:true,
  imports: [SharedModule,CommonModule],
  styleUrls: ['./log-list.component.scss']
})
export class LogListComponent {
  /**
  * Variable used to store the table column definition.
  * @type { Array<columnDef> }
  */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox ,columnWidth: '10%',columnAlign:'start'},
    { field: 'from', header: 'From', type: ColumnType.Text,columnAlign:'start' },
    { field: 'to', header: 'To', type: ColumnType.Text,columnAlign:'start'},
    { field: 'isSuccess', header: 'Status', columnWidth: '12%', type: ColumnType.Chip,
      displayBackgroundColor: { true: 'green', false: 'red' },
      displayText: { true: 'Success', false: 'Failed'},
      displayColor: { true: 'white', false: 'white'}},
    { field: 'createdAt', header: 'Created', type: ColumnType.Date, columnWidth: '13%', format: "dd MMM, yyyy" },
    { field: 'createdAt', header: 'Time', type: ColumnType.Date, columnWidth: '13%', format: "h:mm:ss a" },
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '10%', color: 'black' },
  ];
  /**
  * Variable used to display the table data.
  * @type { logsTableData[] }
  */
  data!: LogsTableData[];
  /**
  * Variable used to store the table actions data.
  * @type { Array<TableAction> }
  */
  actions: Array<TableAction> = [
    { icon: 'visibility', tooltip: 'View Mail Log', method: 'onView', color: '#0068b5'}
  ];
  /**
  * Variable used to store search settings data.
  * @type { SearchSetting }
  */
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'To Mail Address',
    appearance: 'outline'
  }
  /**
 * Variable used to store the table Pagination data.
 * @type { PaginationData }
 */
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
  }
  /**
 * Variable used to store the observables.
 * @type { Subscription }
 */
  subscriptionObject: Subscription = new Subscription();
  /**
  * Flag Variable used to indicate whether enable loader or not.
  * @type { boolean }
  */
  logsListLoader: boolean = true;
  /**
  * Variable used to store the table filter data of optional event.
  * @type { Array<OptionalEvent> }
  */
  optionalEvent: Array<OptionalEvent> = [
    { eventName: 'onFilter', icon: 'filter_list', toolTip: 'Filter', class: 'filter', color: 'white' }
  ]
  /**
  * Variable used to store the searching text.
  * @type { string }
  */
  searchData: string = '';
  /**
  * Variable used to store the table filter data.
  * @type { LogsFilterData }
  */
  filterValue: LogsFilterData = {};
  /**
  * Variable used to store the details for filter.
  * @type { FilterData[] }
  */
  filterDetails!: FilterData[];
  /**
  * Variable used to show filter select options for users.
  * @type { SelectOptions[] }
  */
  filterUserOption: SelectOptions[] = [{}]
  /**
  * Variable used to show filter select options for customers.
  * @type { SelectOptions[] }
  */
  filterCustomerOption: SelectOptions[] = [{}]
  /**
  * Variable used to show filter select options for categories.
  * @type { SelectOptions[] }
  */
  filterCategoryOption: SelectOptions[] = [{}]
  /**
  * Variable used to show filter select options for status.
  * @type { SelectOptions[] }
  */
  filterStatusOption: SelectOptions[] = [
    { name: "Success", value: "true" }, { name: "Failed", value: "false" }
  ]
  /**
  * Variable used to show filter options for application.
  * @type { SelectOptions[] }
  */
  filterApplicationOption: SelectOptions[] = [{}]
  /**
  * Flag Variable used to indicate whether enable filter or not.
  * @type { boolean }
  */
  isFilter: boolean = false;
  /**
   * Variable used to store the filter data.
   * @type { string }
   */
  primaryFilterData: string = '';
  /**
  * Variable used to show primary user filter options.
  * @type { FilterData }
  */
  primaryFilterOptions?: FilterData;
  /**
   * @type {string}
   * Stringified JSON representing table sorting with the customerName column in ascending order.
   */
  orderString: string = JSON.stringify([['createdAt', 'DESC']]);
    /**
  * Variable used to store the isFilterValue.
  * @type {boolean}
  */
    isFilterValue:boolean=false;

  /**
   * Variable used to store no data message.
   * @type {string}
   */
  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Logs",
    description : 'View recent mail logs to track performance and enhance deliverability.',
    fontWeight : '500'
  }
  noDataMessage : string = TEMPLATE_MESSAGES.NO_RECORD_LOGS;  
  /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;
  
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number = -1;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;

  /**
    * component constructor which is used to inject the required services.
    * @param customerService To refer to the CustomersService to get http response.
    * @param auth To refer to the AuthService to access the region subscription.
    * @param dialogService To refer to the CommonDialogService to get the dialog box for delete action.
    */
  constructor(private logService: LogService, private auth: AuthService, private router: Router, private snackbar: CommonSnackbarService, private commonService: CommonService) { }

  ngOnInit(): void {
    this.auth && this.auth.region$ && this.auth.region$.subscribe(() => {
      this.initialLoader=true;
      this.logsListLoader = true;
      this.getMailLogList({offset: 0, limit: 5, order: this.orderString})
      this.gettingUserList();
      this.gettingApplicaitonList();
      this.getCustomerList();
      this.getCategoryList();
      this.appliedFilters = -1;
      this.isFilter = false;
    });
    this.commonService.isFilterClicked.subscribe(res =>{
      this.isFilterClicked = res;
    })
  }

  /**
  * Method used to get the list of logs details based on pagination, searching and filtering.
  * @param { LogsListParameters } params - Parameters for pagination, sorting, searching, and filtering.
  * @returns { void }
  */
  getMailLogList(params: LogsListParameters): void {
    this.subscriptionObject = this.logService && this.logService.getMailLogList(params).subscribe({
      next: (res: GetLogsList) => {
        this.initialLoader=false;
        this.logsListLoader = false;
        this.data = res && res.logs && res.logs.rows
        if(this.paginationData) {
          this.paginationData.count =  res && res.logs && res.logs.count;
        }
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : LOG_SNACKBAR_MESSAGES.logFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        this.initialLoader=false;
        this.logsListLoader = false;
      }
    });
  }

  /**
  * Method used to get the applications list and set filter options.
  * @returns {void}
  */
  gettingApplicaitonList(): void {
    this.subscriptionObject = this.logService && this.logService.getApplicationList({}).subscribe({
      next: (res: GetApplicationList) => {
        this.logsListLoader = false;
        this.setApplicationFilterOption(res && res.applicationData && res.applicationData.rows);
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : LOG_SNACKBAR_MESSAGES.logApplicationFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching application list', err);
        this.logsListLoader = false;
      }
    })
  }
  /**
  * Method used to get the list of customers data.
  * @returns { void }
  */
  getCustomerList(): void {
    this.subscriptionObject = this.logService && this.logService.getCustomersList({}).subscribe({
      next: (res: GetCustomersList) => {
        this.logsListLoader = false;
        this.setCustomerFilterOption(res && res.customer && res.customer.rows);
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : LOG_SNACKBAR_MESSAGES.logCustomerFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching Customer list', err);
        this.logsListLoader = false;
      }
    })
  }

  /**
  * Method used to get the list of categories.
  * @returns { void }
  */
  getCategoryList(): void {
    this.subscriptionObject = this.logService && this.logService.getCategoriesList({}).subscribe({
      next: (res: CategoryResponse) => {
        this.logsListLoader = false;
        this.setCategoryFilterOption(res && res.category && res.category.rows);
      },
      error: (err: string) => {
        this.snackbar && this.snackbar.OpenSnackBar({ message : LOG_SNACKBAR_MESSAGES.logCustomerFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching Category list', err);
        this.logsListLoader = false;
      }
    })
  }

  /**
  * Method used to get the list of users.
  * @returns { void }
  */
  gettingUserList(): void { 
    this.subscriptionObject = this.logService && this.logService.getUserList({}).subscribe({
      next:(res: GetUserListResponse) => {
        this.setFilterOptionUserData(res?.response?.rows);
      },
      error: (err: string) => { 
        this.snackbar && this.snackbar.OpenSnackBar({ message : LOG_SNACKBAR_MESSAGES.logUserFetchError, 
          heading: SNACKBAR_HEADING.failure, actionType : SNACKBAR_TYPE.failure });
        console.error('Error fetching User list', err);
      }
    })
  }

  /**
  * Method used to set filter options based on the application list data.
  * @param {applications[]} applicationListData - The list of application data.
  * @returns { void }
  */
  setApplicationFilterOption(applicationListData: Applications[]): void {
    applicationListData && applicationListData.map((applicationData: Applications, index: number) => {
      this.filterApplicationOption[index] = {'name': applicationData && applicationData.applicationName, 'id': applicationData && applicationData.id}
    })
  }
  
  /**
  * Method used to set filter options based on the customers list data.
  * @param {customers[]} customerListData - The list of customers data.
  * @returns { void }
  */
  setCustomerFilterOption(customerListData: Customers[]): void {
    customerListData && customerListData.map((customerData: Customers, index: number) => {
      this.filterCustomerOption[index] = {'name': customerData && customerData.customerName, 'id': customerData && customerData.id}
    })
  }
  /**
  * Method used to set filter options based on the categories list data.
  * @param { Category[] } categoryListData - The list of category data.
  * @returns { void }
  */
  setCategoryFilterOption(categoryListData: Category[]): void {
    categoryListData && categoryListData.map((categoryData: Category, index: number) => {
      this.filterCategoryOption[index] = {'name': categoryData && categoryData.categoryName, 'id': categoryData && categoryData.id}
    })
  }

  /**
  * Method used to set filter options based on the user list data.
  * @param { UserListData[] } userListData - The list of category data.
  * @returns { void }
  */
  setFilterOptionUserData(userListData: UserListData[]): void {
    let primaryFilterOptions: SelectOptions[] = [];
    userListData.map((userData: UserListData, index: number) => {
      primaryFilterOptions[index] = ({"name" : userData && userData.firstName + userData && userData.lastName , "id" : userData && userData.id});
    })
   if(userListData && userListData.length){
     this.primaryFilterOptions = { title: 'User', type: FilterType.Select , options: primaryFilterOptions, field: 'userId', displayName: 'name', valueName: 'id' };
   } 
  }

  /**
  * Method used to handle action events triggered from the UI.
  * @param { ActionClicked<DynamicDataSource >} event - The event object containing the method name and data 
  * associated with the action.
  * @returns { void }
  */
  onActionClicked(event: ActionClicked<DynamicDataSource>): void {
    (this as DynamicDataSource)[event.method](event);
  }

  onView(event: any){
    const id = event.data.id;
    this.router && this.router.navigate(['/app/mail/view-templates/' + id]);
  }

  /**
  * Method which is used to emit value while using filter.
  * @param { EmittedData } event It has the emitted value from the filter.
  * @returns { void }
  */
  emittedEvent(event: EmittedData): void {
    this.logsListLoader = true;
    this.filterValue = event;
    const data = this.paramAssign();
    if(data) { 
      data.offset = 0;
      this.paginationData = {
        ...this.paginationData,
        offset:  0
      };
    }
    this.getMailLogList(data);
  }
  
  /**
   * Method used to assign total applied filters as number.
   * @param { number } totalAppliedFilters 
   */
  appliedFiltersLength(totalAppliedFilters: number): void {
    this.appliedFilters = totalAppliedFilters;
  }
  
  /**
  * Method used to assign parameters for the logs list response.
  * @returns { LogsListParameters } - The parameters for the log list API call.
  * @description This method cleans the filter values by removing null values, 
  * and constructs the parameters object with pagination, sorting, and filter data. 
  * If search data is present, it adds it to the parameters.
  */
  paramAssign(): LogsListParameters {
    let data: LogsListParameters = {
      offset: this.paginationData && this.paginationData.offset,
      limit: this.paginationData && this.paginationData.limit,
      order: this.orderString
    }
    let cleanedFilterValue = {};
    let combinedFilterValue = {};
    if (this.isFilter) {
      cleanedFilterValue = this.removeNullValues(this.filterValue);
    }
    if (this.primaryFilterData) {
      const existingFilterData = JSON.parse(this.primaryFilterData);
      combinedFilterValue = { ...existingFilterData, ...cleanedFilterValue };
    } else {
      combinedFilterValue = cleanedFilterValue;
    }
    if (this.searchData && data) {
      data.searchText = this.searchData;
    }
    if (Object.keys(combinedFilterValue) && Object.keys(combinedFilterValue).length > 0 && data) {
      data.filterData = JSON.stringify(combinedFilterValue);
    }
    return data;
  }

  /**
  * This method removes any null or undefined objects in the filterValue.
  * @param { NullFilter } filterValue 
  * @returns { NullFilter }
  */
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue) && Object.keys(filterValue)
    .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
    .reduce((acc: NullFilter, key: string) => {
      if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
        const cleanedValue = this.removeNullValues(filterValue[key]);
        if (Object.keys(cleanedValue) && Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else {
        acc[key] = filterValue[key];
      }
      return acc;
    }, {});
  }

  /**
  * Method which is used for table Pagination.
  * @param { PaginationData } event It has the Pagination value which is emitted.
  * @returns { void }
  */
  handlePaginationChange(event: PaginationData): void {
    this.logsListLoader = true;
    if(this.paginationData) {
      this.paginationData.limit = event.limit;
      this.paginationData.offset = event.offset;
    }
    const data = this.paramAssign();
    this.getMailLogList(data);
  }

  /**
  * Method which is used for searching the table.
  * @param { string } event It has the searched string which is emitted.
  * @returns { void }
  */
  handleSearchChange(event: string): void {
    this.logsListLoader = true;
    this.searchData = event;
    const data = this.paramAssign();
    if(data) 
      data.offset = 0;
    this.getMailLogList(data);
  }

  /**
  * Method which is used to perform filtering in the table.
  * @param { OptionalEvent } event It has the event of filter in the table.
  * @returns { void }
  */
  handleOptionalEvent(event: OptionalEvent): void {
    if (event && event.eventName === 'onFilter' && this.isFilterClicked) {
      this.onFilter();
    }
  }

  /**
  * Handles the event when a primary ID is selected from the dropdown.
  * @param { SelectOptions } userId 
  * @returns { void }
  */
  primaryDropdownEvent(userId: SelectOptions): void {
    this.logsListLoader = true;
    this.primaryFilterData = JSON.stringify(this.removeNullValues(userId));
    const data = this.paramAssign();
    if(data)
      data.offset = 0;
    this.getMailLogList(data);
  }

  /**
  * Method which is used to assign the filter data to the table.
  * @returns { void }
  */
  onFilter(): void {
    this.filterDetails = [
      { title: 'Application Name', type: FilterType.Select, options: this.filterApplicationOption, field: 'applicationId', displayName: 'name', valueName: 'id' },
      { title: 'Customer Name', type: FilterType.Select, options: this.filterCustomerOption, field: 'customerId', displayName: 'name', valueName: 'id' },
      { title: 'Category Name', type: FilterType.Select, options: this.filterCategoryOption, field: 'categoryId', displayName: 'name', valueName: 'id' },
      { title: 'Status', type: FilterType.Select, options: this.filterStatusOption, field: 'isSuccess', displayName: 'name', valueName: 'value' },
      { title: 'Date', type: FilterType.Date, field: 'customDateRange', valueName: 'value', format: 'yyyy-MM-dd' }
    ]
    if (this.data && this.data.length) {
      this.isFilterValue = true;
      this.isFilter= true;
    }
  }

  /**
  * Method used to close the filter tab and reset any active filters.
  * @returns { void }
  */
  closeFilter(): void{
    this.isFilterValue = !this.isFilterValue;
    setTimeout(() => {
      this.isFilter = !this.isFilter;
      const data = this.paramAssign();  
      if(data)
        data.offset = 0;
      this.getMailLogList(data);
    }, 300); 
  }

  /**
  * Angular life cycle hook.
  * @type {void}
  */
  ngOnDestroy(): void {
    if (this.subscriptionObject) {
      this.subscriptionObject.unsubscribe();
    }
  }
}
