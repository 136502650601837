import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { HttpParams, GetCustomersList, GetApplicationList, UserListData, GetUserListResponse } from 'src/app/customers/models/customers.model';
import { GetLogsList } from '../models/logs.model';
import { CategoryResponse } from 'src/app/zen-mail/Categories/models/categories.model';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }

  /**
   * Method which is used to get mail list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @type { Observable<GetLogsList> }
   */
  getMailLogList(paramData: HttpParams): Observable<GetLogsList> {
    return this.httpClient.getRequest('microservice/mail/logs?', paramData);  
  }

  /**
   * Method which is used to get application list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @type { Observable<getApplicationList> }
   */
  getApplicationList(paramData: HttpParams): Observable<GetApplicationList> {
    return this.httpClient.getRequest('microservice/mail/application?', paramData);  
  }

  /**
   * Method which is used to get customers list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @type { Observable<GetCustomersList> }
   */
  getCustomersList(paramData: HttpParams): Observable<GetCustomersList> {
    return this.httpClient.getRequest('microservice/mail/customer?', paramData);  
  }

  /**
   * Method which is used to get category list data.
   * @param { HttpParams } paramData It has the query params of the Url.
   * @type { Observable<CategoryResponse> }
   */
  getCategoriesList(paramData: HttpParams): Observable<CategoryResponse> {
    return this.httpClient.getRequest('microservice/mail/category?', paramData);  
  }

  /**
  * Method which is used to get user list data from backend.
  * @param { HttpParams } paramData It has the query params of the Url.
  * @type { Observable<GetUserListResponse>}
  */
  getUserList(paramData: HttpParams) : Observable<GetUserListResponse> {
    return this.httpClient.getRequest('microservice/mail/user?', paramData);  
  }

  /**
   * Method which is used to get single log data.
   * @param { string } id
   */
  getViewMailLogs(id: string) {
    return this.httpClient.getRequest("microservice/mail/logs/"+id+"?isZenMail=true");
  }
}
