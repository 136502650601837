<div *ngIf="filterData && filterData.length > 0" class="back-drop-container" [ngClass]="{'active': isFilterClicked}" (click)="toggleFilterStatus()"></div>
<mat-drawer *ngIf="filterData && filterData.length > 0" class="filter-mat-drawer-container" [ngClass]="isFilterClicked?'animation':'reverseAnimation'" mode="side" position="end" opened="true">
  <div class="filter" fxFlex="100%" fxLayout="column">
    <!-- Header with filter title and dropdown icon -->
    <div class="filter-header zen-mail-default-card-colors" fxLayoutAlign="center center"
      [style.class]="filterDesign && filterDesign.class ? filterDesign.class : defaultFilterDesign.class">
      <mat-icon class="filter-close-icon" data-control="filterTitleArrowIcon" (click)="onReset()"> {{filterInformation.CLOSE}}</mat-icon>
      <span data-control="filterTitle" class="filter-title"
        [style.fontSize]="filterDesign && filterDesign.titleTextFontSize ? filterDesign.titleTextFontSize+'px' : defaultFilterDesign.titleTextFontSize+'px'"
        [style.fontWeight]="filterDesign && filterDesign.titleTextFontWeight ? filterDesign.titleTextFontWeight : defaultFilterDesign.titleTextFontWeight">Filters</span>
    </div>
    <!-- Description of filter controls -->
      <form [formGroup]="filterForm" class="form" fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between">
        <div fxLayout="column" fxLayoutAlign="center none">
          <div *ngFor="let filterInfo of filterData; let i = index" fxLayout="column" fxFlex="100%">
            <!-- Common container for rendering label in each control  -->
             <div fxLayout="row" fxLayoutAlign="space-between">
              <span class="control-label" data-control="filterControlLabel" [style.color]="filterDesign && filterDesign.controlLabelColor ? filterDesign.controlLabelColor:defaultFilterDesign.controlLabelColor">{{filterInfo?.title}}</span>
              <div class="mat-reset-icon-container" fxLayoutAlign="center center">
                <mat-icon *ngIf="findSelectedFilter(i)!== -1 && findAppliedFilter(i)!== -1" (click)="clearOneFilter(i, filterInfo?.field)" matTooltip="Reset" 
                  class="mat-reset-icon" aria-hidden="true" [attr.data-control]="filterInfo?.field + 'Reset'">
                  refresh
                </mat-icon>
              </div>
            </div>
            <ng-container [ngSwitch]="filterInfo?.type">
              <!-- Conditionally renders a select input field inside a mat-form-field -->
              <ng-container *ngSwitchCase="'select'">
                <mat-form-field appearance="outline" class="control-container">
                  <mat-select [placeholder]="filterInfo.title" [formControlName]="filterInfo.field"
                    (closed)="onSelectorSearchFilterClose()" (selectionChange)="onFilterChange(i)">
                    <!-- Container for search input for list of options-->
                    <mat-form-field appearance="fill" class="select-input-container">
                      <input matInput placeholder="Search" [formControl]="selectorSearchFilter"
                        data-control="filterSearchInput" (keyup)="onSelectorSearchFilter($event, filterInfo)"
                        (keydown.space)="$event.stopPropagation();">
                      <mat-icon *ngIf="selectorSearchFilter?.value" matSuffix class="filter-close-icon input-search-icon"
                        fxLayoutAlign="center center" (click)="onSelectorSearchFilterClose()"
                        data-control="filterTextInput">
                        {{filterInformation.CLOSE}}
                      </mat-icon>
                    </mat-form-field>
                    <!-- Container for options to search input's value -->
                    <ng-container *ngIf="selectionOptionValue.length >= 0 && isSelectorOptions">
                      <mat-option *ngFor="let select of selectionOptionValue; let id = index" fxLayout="row"
                        [value]="filterInfo.valueName ? select[filterInfo.valueName] : select"
                        [attr.data-control]="filterInfo.field + 'matOption' + id">
                        {{ selectionOptionValue && selectionOptionValue.length && filterInfo.displayName ?
                        select[filterInfo.displayName] : select }}
                      </mat-option>
                      <div *ngIf="isSelectorOptions && selectionOptionValue.length <= 0" class="no-record-found"
                        data-control="noRecordFound">
                        {{filterInformation.NO_RECORD}}
                      </div>
                    </ng-container>
                    <!-- Container for normal options -->
                    <ng-container *ngIf="!isSelectorOptions">
                      <mat-option *ngFor="let select of filterInfo.options; let id = index" fxLayout="row"
                        [value]="filterInfo.valueName ? select[filterInfo.valueName] : select"
                        [attr.data-control]="filterInfo.field + 'matOption' + id">
                        {{ filterInfo.displayName ? select[filterInfo.displayName] : select }}
                      </mat-option>
                      <div *ngIf="!isSelectorOptions && filterInfo.options && filterInfo.options.length <= 0"
                        data-control="noRecordFound" class="no-record-found">
                        {{filterInformation.NO_RECORD}}
                      </div>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </ng-container>
              <!-- Conditionally renders a date input field inside a mat-form-field -->
              <ng-container *ngSwitchCase="'date'">
                <mat-form-field appearance="outline" class="control-container">
                  <mat-date-range-input [rangePicker]="picker" [formGroupName]="filterInfo.field"
                    [max]="filterInfo?.isMaxDateToday ? today : null" [min]="filterInfo?.isMinDateToday ? today : null">
                    <input readonly matStartDate formControlName="begin" placeholder="Start"
                      [attr.data-control]="filterInfo.field + 'StartDate'">
                    <input readonly matEndDate formControlName="end" placeholder="End"
                      [attr.data-control]="filterInfo.field + 'EndDate'"
                      (dateChange)="setDateValue(i, filterInfo.type); onFilterChange(i)">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"
                    data-control="datePickerToggle"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>
                <mat-error class="error-message" *ngIf="filterInfo.error"
                  [attr.data-control]="filterInfo?.field +'Error'">
                  {{filterInformation.INVALID_DATE}}
                </mat-error>
              </ng-container>
              <!-- Conditionally renders a text input field inside a mat-form-field -->
              <ng-container *ngSwitchCase="'text'">
                <mat-form-field appearance="outline" class="control-container">
                  <input matInput type="text" (input)="onFilterChange(i)" [formControlName]="filterInfo.field" [placeholder]="filterInfo.title"
                    [attr.data-control]="filterInfo.field+'Text'">
                  <mat-icon matSuffix class="input-search-icon" fxLayoutAlign="center center"
                    (click)="onSelectorSearchFilterClose()" data-control="filterTextInput">
                    {{filterInformation.SEARCH}}
                  </mat-icon>
                </mat-form-field>
                <mat-error class="error-message" *ngIf="this.filterForm?.get(filterInfo.field)?.hasError('pattern')">
                  {{ filterInformation.SPACE_VALIDATION_ERROR }}
                </mat-error>
              </ng-container>
            </ng-container>
          </div>
          <!-- Container for action buttons performs event emitting and resetting -->
        </div>
        <div fxLayout="row" class="action-buttons" fxLayoutAlign="space-around">
          <button class="button-typography" [disabled]="appliedFilters.length == 0" data-control="resetButton" mat-stroked-button (click)="onReset()"
            [style.border]="(filterDesign && filterDesign.resetButtonBorderWidth ? 
            filterDesign.resetButtonBorderWidth : defaultFilterDesign.resetButtonBorderWidth)+'px solid '+
            (filterDesign && filterDesign.resetButtonBorderColor && appliedFilters.length > 0 ? filterDesign.resetButtonBorderColor : appliedFilters.length == 0 ? 'rgba(62, 50, 247, 0.5)' : defaultFilterDesign && defaultFilterDesign.resetButtonBorderColor)"
            [style.fontWeight]="filterDesign && filterDesign.buttonTextFontWeight ? filterDesign.buttonTextFontWeight : defaultFilterDesign.buttonTextFontWeight">
            {{filterInformation.RESET_TEXT}}</button>
          <button class="button-typography" data-control="applyButton" mat-stroked-button
            [ngClass]="filterDesign && filterDesign.class ? filterDesign.class: defaultFilterDesign.class"
            [style.color]="filterDesign && filterDesign.applyButtonTextColor ?filterDesign.applyButtonTextColor:defaultFilterDesign.applyButtonTextColor"
            (click)="onApply()">{{filterInformation.APPLY_TEXT}}</button>
        </div>
      </form>
  </div>
</mat-drawer>