<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <mat-card fxLayout="row" class="card-container" ngClass.lt-md="card-container-lt-md"
    ngClass.lt-sm="card-container-lt-sm">
    <div class="slider-container" [ngClass]="{'slideChanged':isSlideChange}" fxHide.lt-md fxFlex="50%" fxLayoutGap="5%"
      fxLayout="column" fxLayoutAlign="center center">
      <div [ngStyle]="{'padding-bottom':'10px'}">
        <h2 class="main-heading" [ngStyle]="{'margin-bottom':'8px'}">Welcome To Zen Mail</h2>
        <hr [ngStyle]="{'width':'35px','height':'1px','background':'var(--primary-color)'}">
      </div>
      <img [src]="featureDetails[selectedIndex].imageUrl" alt="" height="175px" width="175px">
      <h3 class="slider-title" [attr.data-control]="'title'+selectedIndex">{{featureDetails[selectedIndex].title}}</h3>
      <p class="slider-description" [attr.data-control]="'description'+selectedIndex">
        {{featureDetails[selectedIndex].description}}</p>
      <div fxLayout="row" fxLayoutGap="5px" class="slider-navigation">
        <div class="progress-icon" *ngFor="let data of featureDetails;let id=index"
          [ngClass]="(id===selectedIndex) ? 'active':''" (click)="slideChange(id)"
          [attr.data-control]="'progressIcon'+selectedIndex">
          <div></div>
        </div>
      </div>
    </div>
    <div class="form-container" fxLayout="row" fxFlex="50%" fxFlex.lt-md="100%" fxLayoutAlign="center none">
      <div fxFlex="75%" [ngClass]="{'slide-animation1':isSignUp || isForgotPassword,'slide-animation3':isSignIn}" class="sign-in">
        <form [formGroup]="loginForm">
          <div [ngStyle]="{'padding-bottom':'7px'}">
            <h2 class="main-heading" [ngStyle]="{'margin-bottom':'8px'}">Sign In</h2>
            <hr [ngStyle]="{'width':'35px','height':'1px','background':'var(--primary-color)'}">
            <div [ngStyle]="{'color':'red','padding-top':ApiFailed||inValidLogin?'':'12px'}" fxLayout="row"
              fxLayoutAlign="center none" fxLayoutGap="2%">
              <mat-icon *ngIf="ApiFailed|| inValidLogin">error_outline</mat-icon>
              <p [ngStyle]="{'padding-top':'3px'}">{{ApiFailed?'Something Went Wrong.':inValidLogin?'Invalid Email or Password':''}}</p>
            </div>
          </div>
          <div fxLayout="column" fxLayoutGap="2%">
            <div class="email-container" fxLayout="column">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="3%">
                  <input matInput placeholder="Email *" formControlName="email" [ngStyle]="{'width':'90%'}">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)'}"> mail_outline</mat-icon>
                </div>
                <mat-error data-control="fieldRequiredError" *ngIf=" loginForm?.get('email')?.hasError('required')">
                  <span>{{message['emailRequired']}}</span>
                </mat-error>
                <mat-error *ngIf="loginForm?.get('email')?.hasError('pattern')">
                  <span>{{message['invalidEmail']}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="password-conatiner" fxLayout="column">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="3%">
                  <input matInput placeholder="Password *" formControlName="password"
                    [type]="showPassword?.showCurrentPassword ? 'text' : 'password'" [ngStyle]="{'width':'90%'}">
                  <mat-icon *ngIf="!loginForm.controls['password'].value"
                    [ngStyle]="{'color':'var(--primary-color)'}">lock_outline</mat-icon>
                  <mat-icon *ngIf="loginForm.controls['password'].value"
                    [ngStyle]="{'color':'var(--primary-color)','cursor':'pointer'}"
                    (click)="showPasswordType('showCurrentPassword')"> {{showPassword?.showCurrentPassword ?
                    'visibility_outline'
                    :'visibility_off_outline'}}</mat-icon>
                </div>
                <mat-error data-control="fieldRequiredError" *ngIf="loginForm?.get('password')?.hasError('required')">
                  <span>{{message['passwordRequired']}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div [ngStyle]="{'text-align':'-webkit-center'}">
              <p class="forgot-password" data-control="gotoforgotpassword" (click)="navigateToFotgorPassword()">
                Forgot your password?</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button class="button-container" data-control="signInFunction" mat-raised-button (click)="onSignIn()"><span *ngIf="!isLoading">Sign In</span> <div class="dot-pulse" *ngIf="isLoading"></div></button>
            </div>
            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center center">
              <hr fxFlex="40%" [ngStyle]="{'background':'var(--primary-color)'}">
              <span fxFlex="5%">Or</span>
              <hr fxFlex="40%" [ngStyle]="{'background':'var(--primary-color)'}">
            </div>
            <div fxLayout="row" fxLayoutGap="3%" fxLayoutAlign="center center" [ngStyle]="{'padding-top':'10px'}">
              <div *ngFor="let image of socialMediaImg">
                <div class="social-media">
                  <a [href]="image?.link" target="_blank">
                  <img [src]="image?.src" height="18px" width="18px" [ngStyle]="{'cursor':'pointer','padding':'6px'}">
                  </a>
                </div>
              </div>
            </div>
            <div [ngStyle]="{'padding-top':'13px','text-align':'center'}">
              <span data-control="accountInformation">Don't have a <span [ngStyle]="{'font-weight':'bold'}">Zen
                  Mail</span> account?</span>
              <span class="sign-in-link" data-control="signUpInformation" (click)="navigateToSignUp()"> Sign up here
                !</span>
            </div>
          </div>
        </form>
      </div>
      <div fxFlex="75%" [ngClass]="{'slide-animation2':isSignUp,'slide-animation4':isSignIn}" class="sign-up">
        <form *ngIf="signUpFormGroup" [formGroup]="signUpFormGroup">
          <div [ngStyle]="{'padding-bottom':'10px'}">
            <h2 class="main-heading" [ngStyle]="{'margin-bottom':'8px'}">Sign Up</h2>
            <hr [ngStyle]="{'width':'35px','height':'1px','background':'var(--primary-color)'}">
            <div [ngStyle]="{'color':'red','padding-top':ApiFailed?'12px':''}" fxLayout="row" *ngIf="ApiFailed"
            fxLayoutAlign="center none" fxLayoutGap="2%">
            <mat-icon *ngIf="ApiFailed">error_outline</mat-icon>
            <p [ngStyle]="{'padding-top':'3px'}">{{'Something Went Wrong'}}</p>
          </div>
          </div>
          <div fxLayout="column" fxLayoutGap="2%" [ngStyle]="{'padding-top':ApiFailed?'7px':'20px'}">
            <div fxFlex="45%" fxLayoutGap="2%" fxLayout="column">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="3%">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)'}">person_outline</mat-icon>
                  <input matInput formControlName="firstName" placeholder="First Name *" data-control="firstName"
                    [ngStyle]="{'width':'98%'}">
                </div>
                <mat-error data-control="firstNameError"
                  *ngIf="signUpFormGroup?.get('firstName')?.hasError('required')">
                  {{message['firstNameRequired']}}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="45%" fxLayoutGap="2%" fxLayout="column">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="3%">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)'}">person_outline</mat-icon>
                  <input matInput formControlName="lastName" placeholder="Last Name " data-control="lastName"
                    [ngStyle]="{'width':'98%'}">
                </div>
                <mat-error data-control="lastNameError" *ngIf="signUpFormGroup?.get('lastName')?.hasError('required')">
                  {{message['lastNameRequired']}}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="45%" fxLayoutGap="-6%" fxLayout="column">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column" class="custom-field1">
                <div fxLayout="row" fxLayoutGap="3%">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)'}">mail_outline</mat-icon>
                  <input matInput formControlName="email" placeholder="Email *" data-control="email"
                    [ngStyle]="{'width':'98%'}">
                  <!-- <span *ngIf="signUpFormGroup?.get('email')?.value" [ngStyle]="{'color':'gray'}">{{'@centizen.com'}}</span> -->
                </div>
                <mat-error data-control="emailError" *ngIf="signUpFormGroup?.get('email')?.hasError('required')">
                  {{message['emailRequired']}}
                </mat-error>
                <mat-error data-control="emailError" *ngIf="signUpFormGroup?.get('email')?.hasError('email')">
                  {{message['invalidEmail']}}
                </mat-error>
              </mat-form-field>
              <!-- <div [ngStyle]="{'font-size':'12px','padding-left':'15px','color':'#f44336'}" *ngIf="signUpFormGroup.get('email')?.invalid && (signUpFormGroup.get('email')?.dirty || signUpFormGroup.get('email')?.touched)">
              <mat-error data-control="emailError" *ngIf="signUpFormGroup?.get('email')?.hasError('required')">{{message['emailRequired']}}
              </mat-error>
              <mat-error data-control="emailError" *ngIf="signUpFormGroup?.get('email')?.hasError('forbiddenEmail')">{{message['extensionMsg']}}
              </mat-error>
              <mat-error data-control="emailError" *ngIf="signUpFormGroup?.get('email')?.hasError('noSpaces')">{{message['noSpaces']}}
              </mat-error>
              </div> -->
            </div>
            <div fxLayout="row" fxLayoutGap="2%" >
              <mat-checkbox (change)="onCheckboxChange($event)" [checked]="isChecked">  
              </mat-checkbox>
              <p [ngStyle]="{'font-size':'12px'}">By continuing, you agree to the <span><a [href]="zenbasketUrls.termsOfService" class="privacy">Terms of
                Service</a></span> and <span ><a [href]="zenbasketUrls.privacyPolicy" class="privacy">Privacy
                Policy</a></span></p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button class="button-container" data-control="signInFunction" mat-raised-button  [ngClass]="{'disabled-select': !(isChecked && signUpFormGroup.valid)}" (click)="onSignUp()"><span *ngIf="!isLoading">Sign Up</span> <div class="dot-pulse" *ngIf="isLoading"></div></button>
            </div>
            <div [ngStyle]="{'text-align':'center'}">
              <span data-control="accountInformation" [ngStyle]="{'font-size':'14px'}">If you are already one of
                us,</span>
              <span class="sign-up-link" data-control="signInInformation" (click)="navigateToSignIn()"> Sign in here
                !</span>
            </div>
          </div>
        </form>
      </div>
      <div fxFlex="75%" [ngClass]="{'slide-animation5':isForgotPassword,'slide-animation4':isSignIn}" class="forgot-password-container">
        <form [formGroup]="forgotFormGroup" *ngIf="forgotFormGroup">
          <div [ngStyle]="{'padding-bottom':'25px'}">
            <h2 class="main-heading" [ngStyle]="{'margin-bottom':'8px'}">Can't Access Your Account?</h2>
            <hr [ngStyle]="{'width':'35px','height':'1px','background':'var(--primary-color)'}">
            <div [ngStyle]="{'color':'red','padding-top':ApiFailed||isUser?'12px':''}" fxLayout="row"
            fxLayoutAlign="center none" fxLayoutGap="2%">
            <mat-icon *ngIf="ApiFailed|| isUser">error_outline</mat-icon>
            <p [ngStyle]="{'padding-top':'3px'}">{{ApiFailed?'Something Went Wrong.':isUser?'User email not found':''}}</p>
          </div>
          </div>
            <div fxLayout="column" fxLayoutGap="2%">
            <div class="email-container" fxLayout="column">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="3%">
                  <input matInput placeholder="Email *" formControlName="email" [ngStyle]="{'width':'90%'}">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)'}"> mail_outline</mat-icon>
                </div>
                <mat-error data-control="fieldRequiredError" *ngIf=" forgotFormGroup?.get('email')?.hasError('required')">
                  <span>{{message['emailRequired']}}</span>
                </mat-error>
                <mat-error *ngIf="forgotFormGroup?.get('email')?.hasError('email')">
                  <span>{{message['invalidEmail']}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div [ngStyle]="{'text-align':'-webkit-center'}">
              <p class="forgot-password" data-control="gotoforgotpassword" (click)="navigateToSignIn()">
               Sign in now!</p>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
              <button class="button-container" data-control="signInFunction" mat-raised-button (click)="onForgot()"><span *ngIf="!isLoading">Continue</span> <div class="dot-pulse" *ngIf="isLoading"></div></button>
            </div>
            <div fxLayout="row" fxFlex="100%" fxLayoutAlign="center center" [ngStyle]="{'padding-top':'10px'}">
              <hr fxFlex="40%" [ngStyle]="{'background':'var(--primary-color)'}">
              <span fxFlex="5%">Or</span>
              <hr fxFlex="40%" [ngStyle]="{'background':'var(--primary-color)'}">
            </div>
            <div fxLayout="row" fxLayoutGap="3%" fxLayoutAlign="center center" [ngStyle]="{'padding-top':'10px'}">
              <div *ngFor="let image of socialMediaImg">
                <div class="social-media">
                  <img [src]="image?.src" height="18px" width="18px" [ngStyle]="{'cursor':'pointer','padding':'6px'}">
                </div>
              </div>
            </div>
            <div [ngStyle]="{'padding-top':'13px','text-align':'center'}">
              <span data-control="accountInformation">Don't have a <span [ngStyle]="{'font-weight':'bold'}">Zen
                  Mail</span> account?</span>
              <span class="sign-in-link" data-control="signUpInformation" (click)="navigateToSignUp()"> Sign up here
                !</span>
            </div>
          </div> 
        </form>
    </div>
    </div>
  </mat-card>
  <div [ngStyle]="{'padding-top':'20px','color':'#727272','font-size':'14px'}">
    <p [ngStyle]="{'text-align':'center'}">Copyrights 2024, ZenBasket Pvt. Ltd. All Rights Reserved.</p>
    <p [ngStyle]="{'text-align':'center'}">Version | 1.0.0</p>
  </div>
</div>