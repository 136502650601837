import { Component } from '@angular/core';
import { CustomersComponent } from 'libs/shared/src/public-api';

@Component({
  selector: 'app-customers-marketing',
  standalone: true,
  imports: [CustomersComponent],
  templateUrl: './customers-marketing.component.html',
  styleUrls: ['./customers-marketing.component.scss']
})
export class CustomersMarketingComponent {

}
