<div *ngIf="!loading" class="common-header">
  <app-common-header [heading]="dynamicTitle[action]" ></app-common-header>
</div>
<div  *ngIf="loading"  class="add-edit-container common-card-style">
  <app-skeleton-loader [formLoader]="true"></app-skeleton-loader>
</div>
<div *ngIf="!loading"  class="add-edit-container common-card-style">
  <div fxLayout="column" fxLayoutGap="1%" [ngStyle]="{'background-color':'white'}">
    <form [formGroup]="templateForm" >
      <div class="card-container" fxLayout="column" fxLayoutGap="2%" *ngIf="templateForm">
        <div class="container" fxLayout="column" fxLayoutGap="4%">
          <mat-card class="mat-cards" fxLayout="column" fxLayoutGap="4%">
            <div fxLayout="column" fxLayoutGap="2%">
              <div fxLayout="column" fxLayoutGap="2%" class="hr-tag">
                <div fxLayout="row" fxLayout.lt-sm="column">
                  <div fxLayoutAlign="start center" fxFlex="30%" [ngStyle]="{'padding-top':'20px'}">
                    <h4 style="font-weight: 500;text-align: center;">Essential Details </h4>
                  </div>
                  <div fxFlex="80%" fxFlex.lt-sm="100%" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="center center"
                    fxLayoutGap="3%">
                    <button mat-raised-button (click)="onBack()" matTooltip="Back To Template List"
                      class="button-border">
                      Cancel
                    </button>
                    <button class="mat-button" mat-raised-button (click)="onTestMail()"
                      [matTooltip]="'Test Version of an Email Template'">Beta Mail</button>
                    <button class="mat-button" mat-raised-button (click)="onSave()" *ngIf="!isView"
                      [matTooltip]="!isEdit?'Save Template':'Update Template'">
                      {{isEdit? 'Update':'Save'}}</button>
                  </div>
                </div>
                <div>
                  <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
                </div>
              </div>
              <div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="5%"
                [ngStyle]="{'padding-left':'35px','row-gap':'20px'}"
                [ngStyle.lt-sm]="{'padding-left':'0px','row-gap':'10px'}">
                <div fxFlex="45%" fxLayoutGap="2%" fxLayout="column">
                  <mat-label>Template Application </mat-label>
                  <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isEdit || isView}">
                    <mat-select placeholder="Select Application Name" formControlName="applicationId"
                      (selectionChange)="onApplicationSelectionChange($event)">
                      <mat-option *ngFor="let application of applicationDetails" [value]="application['id']">
                        {{application['name']}}
                      </mat-option>
                    </mat-select>
                    <mat-error data-control="applicationId"
                      *ngIf="templateForm?.get('applicationId')?.hasError('required')">
                      Application is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="45%" fxLayoutGap="2%" fxLayout="column">
                  <mat-label>Template Category </mat-label>
                  <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView || !isCategory}">
                    <mat-select [placeholder]="getPlaceholderText1()" formControlName="categoryId"
                      (selectionChange)="onCategorySelectionChange($event)">
                      <mat-option *ngFor="let category of categoryDetails"
                        [value]="category['id']">{{category['name']}}</mat-option>
                    </mat-select>
                    <mat-error data-control="categoryId" *ngIf="templateForm?.get('categoryId')?.hasError('required')">
                      Category is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="45%" fxLayoutGap="2%" fxLayout="column">
                  <mat-label>Template Sub Category </mat-label>
                  <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView || !isSubCategory}">
                    <mat-select [placeholder]="getPlaceholderText()" formControlName="subCategoryId">
                      <mat-option *ngFor="let subCategory of subCategoryDetails"
                        [value]="subCategory['id']">{{subCategory['name']}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div fxLayout="column" fxLayoutGap="2%">
              <div fxLayout="column" fxLayoutGap="1%" class="hr-tag">
                <div>
                  <div fxLayout="row" fxFlex="100%" fxLayout.lt-sm="column">
                    <div fxFlex="30%" fxLayoutAlign="start center" [ngStyle]="{'padding-top':'20px'}">
                      <h4 style="font-weight: 500;text-align: center;">Template Details </h4>
                    </div>
                    <div fxFlex="70%" fxLayout="row" fxLayoutGap="3%" fxLayoutAlign="end center">
                      <!-- <div>
                        <button class="mat-button" mat-raised-button (click)="onSaveTemplates()" *ngIf="!isView"
                      [matTooltip]="!isEdit?'Save Template':'Update Template'">
                      {{isEdit? 'Update':'Save'}}</button>
                      </div> -->
                      <div [ngStyle]="{'width':'320px'}">
                        <div fxLayout="row" fxLayoutGap="3%" fxFlex="100%" class="toggle-button"
                          [ngClass]="{'disabled-select': isView}">
                          <span
                            [ngClass]="{ 'span1': !isActivated && animate, 'span2': isActivated, 'span1NoAnim': !isActivated && !animate }"
                            [ngStyle.lt-sm]="{'font-size':'10px','line-height':'12px'}"
                            (click)="toggleCliked('false')">Deactivated</span>
                          <span
                            [ngClass]="{ 'span3': isActivated && animate, 'span2': !isActivated, 'span3NoAnim': isActivated && !animate }"
                            [ngStyle.lt-sm]="{'font-size':'10px','line-height':'12px'}"
                            (click)="toggleCliked('true')">Activated</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
                </div>
              </div>
              <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="5%"
                [ngStyle]="{'padding-left':'35px','row-gap':'20px'}"
                [ngStyle.lt-sm]="{'padding-left':'0px','row-gap':'10px'}">
                <div fxFlex="45%" fxLayoutGap="1%" fxLayout="column">
                  <mat-label>Template Name </mat-label>
                  <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView}">
                    <input matInput placeholder="Enter Template Name" formControlName="generateTemplateName">
                    <mat-error data-control="generateTemplateName"
                      *ngIf="templateForm?.get('generateTemplateName')?.hasError('required')">
                      Template Name is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="45%" fxLayoutGap="1%" fxLayout="column">
                  <mat-label>Template Code </mat-label>
                  <div class="generate-template" fxLayoutAlign="start center">
                    <input fxFlex="90%" class="generate-input" matInput placeholder="Template Code"
                      formControlName="templateName" #templateNameInput [ngClass]="{'disabled-select': isDisabledInput}"
                      [style.pointer-events]="isDisabledInput ? 'none' : 'auto'">
                    <mat-icon fxFlex="10%" fxLayoutAlign="center center" *ngIf="templateForm.controls['templateName'].value"
                      (click)="copyTemplateName(templateNameInput.value)" [matTooltip]="'Copy Template Name'"
                      class="copy-icon">file_copy</mat-icon>
                  </div>
                </div>
                <div fxFlex="75%" fxLayoutGap="1%" fxLayout="column">
                  <mat-label>Template Subject </mat-label>
                  <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView}">
                    <input matInput placeholder="Enter Subject" formControlName="subject">
                    <mat-error data-control="subject" *ngIf="templateForm?.get('subject')?.hasError('required')">
                      Subject is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="15%" fxLayoutGap="5%" fxLayout="column">
                  <mat-label>Template Language </mat-label>
                  <mat-form-field appearance="outline">
                    <mat-select placeholder="Select Language" formControlName="lanCode"
                      (selectionChange)="onLanguageSelectionChange($event)">
                      <mat-option *ngFor="let language of languageDetails"
                        [value]="language['id']">{{language['name']}}</mat-option>
                    </mat-select>
                    <mat-error data-control="lanCode" *ngIf="templateForm?.get('lanCode')?.hasError('required')">
                      Template language is required.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="94%" fxLayoutGap="1%" fxLayout="column">
                  <mat-label>Template Content </mat-label>
                  <editor apiKey="70hqq0nvwd7evkhi7ol944hby169iojs7r06lggpx9efy38v" [init]="init"
                    formControlName="content" />
                  <mat-error data-control="avaliability"
                    *ngIf="templateForm?.get('avaliability')?.hasError('required')">
                    Content is required.
                  </mat-error>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </form>
  </div>
  <ng-template #dialogDisplay>
    <div class="dialog-container" fxLayout="column">
        <h2 style="padding: 20px 20px 0px 20px">Try Out Email</h2>
        <div style="padding: 0px 20px 20px 20px">
          <form [formGroup]="emailForm">
                <div fxLayout="column" fxLayoutGap="3%" fxLayoutGap.lt-sm="7%">
                  <mat-label>Email </mat-label>
                  <mat-form-field appearance="outline" class="custom-field">
                    <mat-chip-grid #chipList>
                      <mat-chip-row *ngFor="let recipient of emailList; let i=index" [selectable]="true"
                        [removable]="removable" (removed)="removeEmail(recipient)">
                        {{ recipient }}
                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                      </mat-chip-row>
                      <input matInput data-control="recipient" formControlName="email" *ngIf="(emailList.length)<10"
                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                        (matChipInputTokenEnd)="add($event)" [matChipInputAddOnBlur]="'true'"
                        (keyup.BACKSPACE)="onBackSpace()" />
                    </mat-chip-grid>
                    <mat-hint data-control="emailHint">Email separated by "comma" / "enter" are only valid.</mat-hint>
                  </mat-form-field>
                  <span data-control="emailFormat" [ngStyle]="{'font-size':'12px','padding-left':'5%'}">
                    {{"Example :"+emailFormat}}</span>
                  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4%">
                    <button mat-raised-button (click)="closeDialog()">Cancel</button>
                    <button mat-raised-button (click)="sendMail()" [ngClass]="{'disabled-select': emailList.length<=0}">Send
                      Mail</button>
                  </div>
                </div>
          </form>
        </div>
      </div>
  </ng-template>
</div>