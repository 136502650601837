import { FeatureDetails, ValidationMessage, ValidationPattern } from "../models/auth.model";

/**
 * URL for the sign-in API endpoint.
 * @type {string}
 */
export const SIGNIN_API_URL = 'microservice/mail/auth/signin';

/**
 * URL for the sign-up API endpoint.
 * @type {string}
 */
export const SIGNUP_API_URL = 'microservice/mail/auth/signup';

/**
 * URL for the sign-up API endpoint.
 * @type {string}
 */
export const GET_USER_API_URL = 'microservice/mail/user/getuser';

/**
* URL for the view log API endpoint.
* @type {string}
*/
export const VIEWLOGS_API_URL = 'microservice/mail/logs/11?isZenMail=true';

/**
 * Array includes the features in our mail service.
 * @type {FeatureDetails[]}
 */
export const MAIL_FEATURES:FeatureDetails[]=[
    {
      imageUrl:'../../../../assets/mail-templates.png',
      title:'Customizable Email Templates',
      description:"Make your emails stand out with our selection of customizable templates. Personalize your messages to reflect your brand's identity and capture your recipients' attention."
    },
    {
      imageUrl:'../../../../assets/send-mail.png',
      title:'Send Emails with Ease',
      description:"Our intuitive interface lets you compose and send emails effortlessly. Whether it's a single email or a large bulk mailing, our platform ensures your messages reach their destination smoothly."
    },
     {
      imageUrl:'../../../../assets/bulk.png',
      title:'Bulk Emailing Made Simple',
      description:"Need to reach a wider audience? Our bulk email feature lets you efficiently send emails to your entire contact list, keeping your communication consistent and effective."
    },
    {
      imageUrl:'../../../../assets/logs-analytics.png',
      title:'Logs and Analytics at a Glance',
      description:"Gain insights into your email performance with easy-to-understand bar diagrams. Track delivery rates, open rates, and more to refine your email strategies effectively."
    },
    {
      imageUrl:'../../../../assets/config1.png',
      title:'Tailored Configurations',
      description:"Your email needs are unique, and we understand that. Our platform offers customizable configurations, allowing you to adapt the service to suit your specific requirements."
    }
  ]
export class AuthConstants{

  message:ValidationMessage = {
    emailRequired: "Email is required",
    invalidEmail: 'Please enter a valid email',
    passwordRequired:"Password is required",
    firstNameRequired:"First Name is required",
    lastNameRequired:"Last Name is required",
    newPasswordRequired:"New Password is required",
    confirmPasswordRequired:"Confirmation Password is required",
    newPasswordStrength:"Password must be at least 8 characters long, with at least one uppercase letter, one lowercase letter, one number, and one special character.",
    passwordsMustMatch:"Passwords do not match.",
    invalidPassword:"In Valid Password",
    oldPasswordRequired:"Old Password is required",
    extensionMsg:"Only email prefixes are allowed. Do not include '@'  or ' . ' or domain extensions.",
    noSpaces:"Email should not contain spaces"
  };

  validator:ValidationPattern = {
    emailValidationPattern: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
  };
}

