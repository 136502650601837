<div class="application-container">
<div *ngIf="initialLoader" class="common-card-style" [ngStyle]="{'margin':' 20px 20px 0px 20px'}" >
  <ng-container>
    <div>
      <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
    </div>
    </ng-container> 
</div>

<div class="common-header" *ngIf="!initialLoader">
  <app-common-header  (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" ></app-common-header>
</div>

<div fxLayout.lt-md="column" >
    <app-common-filter  *ngIf="isFilter" [filterData]="filterDetails" [isFilterStatus]="isFilterValue"
       (filterClose)="closeFilter()" (emittedData)="emittedEvent($event)" (AppliedFilters)="appliedFiltersLength($event)">
    </app-common-filter>
    <div fxFlex="100%" class="common-card-style">
       <app-common-card *ngIf="!initialLoader" [columns]="columns" [dataNotFoundMessage]="noDataMessage" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true" 
       [searchSetting]="searchSettingData" [hasPagination]="true" (actionClicked)="openDialog($event)"   (paginationChange)="handlePaginationChange($event)" [paginationData]="paginationData"  [optionalEvent]="optionalEvent" [loading]="applicationsListLoader"
          (searchChange)="handleSearchChange($event)" [appliedFilters]="appliedFilters" 
          (optionalEventOccurred)="handleOptionalEvent($event)"></app-common-card>
    </div>
</div>
</div>