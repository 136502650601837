<div *ngIf="!loading" class="common-header">
  <app-common-header (methodClicked)="headerEvent($event)" [heading]="dynamicTitle[action]" ></app-common-header>
</div>

<div *ngIf="loading" class="loader-card-style">
  <div>
    <app-skeleton-loader [formLoader]="true"></app-skeleton-loader>
  </div>
</div>

  <div *ngIf="!loading" fxLayout="column">
    <div class="container" fxLayout="column" fxLayoutGap="2%" *ngIf="categoriesForm">
      <form [formGroup]="categoriesForm">
      <mat-card class="add-edit-container common-card-style" [ngStyle.lt-sm]="{'padding':'10px 25px'}" fxLayout="column" fxLayoutGap="4%">
          <div fxLayout="column" fxLayoutGap="1%" class="hr-tag">
            <div fxLayout="row" fxLayout.lt-sm="column">
              <div fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center" fxFlex="30%"
                [ngStyle]="{'padding-top':'20px'}">
                <h4 style="font-weight: 500;text-align: center;">Category Details </h4>
              </div>
              <div fxFlex="80%" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="3%">
                <button mat-raised-button (click)="onBack()" matTooltip="Back To Category List"
                  class="button-border">Cancel
                </button>
                <button *ngIf="!isView" class="mat-button" mat-raised-button (click)="onSave()"
                  [matTooltip]="!isEdit?'Save Category':'Update Category'">
                  {{isEdit? 'Update':'Save'}}</button>
              </div>
            </div>
            <div>
              <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
            </div>
          </div>
          <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-between" fxFlex="100%"
            [ngStyle]="{'row-gap':'15px'}"
            [ngStyle.lt-md]="{'row-gap':'15px'}"
            [ngStyle.lt-sm]="{'row-gap':'10px'}">
            <div fxFlex="47.5%" fxLayoutGap="1%" fxLayout="column">
              <mat-label>Template Application </mat-label>
              <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isEdit || isView}">
                <mat-select placeholder="Select Application Name" formControlName="applicationId">
                  <mat-option *ngFor="let application of applicationDetails" [value]="application['id']">
                    {{application['name']}}
                  </mat-option>
                </mat-select>
                <mat-error data-control="applicationId"
                  *ngIf="categoriesForm?.get('applicationId')?.hasError('required')">
                  Application is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="47.5%" fxLayoutGap="1%" fxLayout="column">
              <mat-label>Category Name </mat-label>
              <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView}">
                <input matInput placeholder="Enter Category Name" formControlName="categoryName">
                <mat-error data-control="categoryName"
                  *ngIf="categoriesForm?.get('categoryName')?.hasError('required')">
                  Category Name is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="47.5%" fxLayoutGap="1%" fxLayout="column">
              <mat-label>Category Code </mat-label>
              <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView}">
                <input matInput placeholder="Enter Category Code" formControlName="categoryCode">
                <mat-error data-control="categoryCode"
                  *ngIf="categoriesForm?.get('categoryCode')?.hasError('required')">
                  Category Code is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </mat-card>
        <mat-card class="add-edit-container common-card-style" [ngStyle.lt-sm]="{'padding':'10px 25px'}" fxLayout="column">
          <div fxLayout="column" fxLayoutGap="1%" class="hr-tag">
            <div fxLayout="row" fxLayout.lt-sm="column">
              <div fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center" fxFlex="100%"
                [ngStyle]="{'padding-top':'20px'}">
                <h4 style="font-weight: 500;text-align: center;">Sub Category Details </h4>
              </div>
              <div fxFlex="80%" fxLayoutAlign="end center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="3%">
                <button *ngIf="!isView" class="mat-button" mat-raised-button
                  (click)="onAddSubCategoryDialog()"><mat-icon>add</mat-icon> Add Sub
                  Category</button>
              </div>
            </div>
            <div>
              <hr [ngStyle]="{'border':'0.5px solid #e1e1e1'}">
            </div>
          </div>
          <div class="hr-tag" [ngStyle.lt-sm]="{'padding-left':'1px','padding-right':'1px'}">
            <ng-template #dialogDisplay>
              <div class="dialog-container" fxLayout="column">
                <h5 class="dialog-header" mat-dialog-title>
                  {{isSubEdit?'Update Sub Category':'Create Sub Category'}}
                </h5>
                <form [formGroup]="subCategoriesForm">
                  <div mat-dialog-content fxLayout="column" fxLayoutGap="3%">
                    <div fxLayout="column" fxLayoutGap="4%">
                      <div fxLayoutGap="2%" fxLayout="column">
                        <mat-label>Sub Category Name </mat-label>
                        <mat-form-field appearance="outline">
                          <input matInput placeholder="Enter Sub Category Name" formControlName="subCategoryName">
                          <mat-error data-control="subCategoryName"
                            *ngIf="subCategoriesForm?.get('subCategoryName')?.hasError('required')">
                            Sub Category Name is required.
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxLayoutGap="2%" fxLayout="column">
                        <mat-label>Sub Category Code </mat-label>
                        <mat-form-field appearance="outline" [ngClass]="{'disabled-select': isView}">
                          <input matInput placeholder="Enter Sub Category code" formControlName="subCategoryCode">
                          <mat-error data-control="subCategoryCode"
                            *ngIf="subCategoriesForm?.get('subCategoryCode')?.hasError('required')">
                            Sub Category Code is required.
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3%" fxLayoutAlign="center center">
                      <button mat-raised-button (click)="dialogClose()">Cancel</button>
                      <button mat-raised-button (click)="onSaveSubCategory()">{{isSubEdit?'Update':'Save'}}</button>
                    </div>
                  </div>
                </form>
              </div>
            </ng-template>
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1%" *ngIf="isImg">
              <img src="../../../../assets/no-results-found.gif" alt="noSubCategoriesRecordsFound" class="noRecordImg">
              <p class="sub-category-text">There are no Sub Categories listed
                here!</p>
              <p class="sub-category-text">When you added sub Categories, they
                will be showcased here.</p>
            </div>
            <div formArrayName="subCategory">
              <mat-card fxLayout="row" [ngStyle]="{'padding':'10px','font-weight':'bold'}" *ngIf="!isImg">
                <div fxFlex="43%">Sub Category Name</div>
                <div fxFlex="43%">Sub Category Code</div>
                <div>Actions</div>
              </mat-card>
              <div *ngFor="let item of getSubCategory(); let i=index">
                <div [formGroupName]="i">
                  <div [ngStyle]="{'padding':'10px 0px'}">
                    <mat-card fxLayout="row" [ngStyle]="{'padding':'10px'}">
                      <div fxFlex="43%" fxLayoutGap="1%" fxLayout="column">
                        <input matInput placeholder="Enter Sub Category Name" class="input-tag"
                          formControlName="subCategoryName">
                      </div>
                      <div fxFlex="43%" fxLayoutGap="1%" fxLayout="column">
                        <input matInput placeholder="Enter Sub Category Code" class="input-tag"
                          formControlName="subCategoryCode">
                      </div>
                      <div fxLayoutGap="4%" fxLayout="row" fxLayoutAlign="center center"
                        [ngClass]="{'disabled-select': isView}">
                        <mat-icon [ngStyle]="{'color':'rgb(0, 185, 7)'}" (click)="onEditSubCategory(i)">edit</mat-icon>
                        <mat-icon [ngStyle]="{'color':'red'}" (click)="onRemoveSubCategory(i)">delete</mat-icon>
                      </div>
                    </mat-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </form>
      </div>
  </div>
