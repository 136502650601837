import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  CommonCardColorSchema,
  Typography,
  columnDef,
  PaginationData,
  TableAction,
  ActionClicked,
  SearchSetting,
  SortSetting,
  DynamicDataSource,
  OptionalEvent,
  ChipProperties,
  FilterData,
  SelectOptions,
  DropDownOptions,
} from '../../models/common-card-data.model';
import {
  ColumnType,
  DEFAULT_COMMON_CARD_COLOR_SCHEMA,
  DEFAULT_SELECTEDSORT,
  DEFAULT_TYPOGRAPHY,
} from '../../constants/common-card-data.constants';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as _ from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, debounceTime, map, retry } from 'rxjs';
import { filterConstants } from '../../constants/common-filter-data.contants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from 'src/app/auth/service/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonService } from '../../service/common.service';
@Component({
  selector: 'app-common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '*',
        opacity: 1,
        overflow: 'visible'
      })),
      state('closed', style({
        width: '0',
        opacity: 1,
        overflow: 'hidden'
      })),
      transition('closed => open', [
        animate('0.5s ease-in-out')
      ]),
      transition('open => closed', [
        animate('0.5s ease-in-out')
      ]),
    ]),
  ]
})
export class CommonCardComponent extends filterConstants implements OnInit, OnDestroy {

  /**
   * represents the interactability of the primary dropdown. 
   * @type { boolean }
   */
  @Input() dataNotFoundMessage!: string ;

  /**
   * represents the interactability of the primary dropdown. 
   * @type { boolean }
   */
  @Input() isPrimaryFilterDisabled: boolean = false;

  /**
  * Variable used to store input data for filter controls.
  * @type {FilterData}
  */
  @Input() primaryFilterData?: FilterData;

  /**
   * @type {CommonCardColorSchema}
   * Color schema for the common card.
   */
  @Input() colorSchema: CommonCardColorSchema = _.cloneDeep(
    DEFAULT_COMMON_CARD_COLOR_SCHEMA
  );
  /**
   * @type {Typography}
   * Typography settings for the common card.
   */
  @Input() typography: Typography = _.cloneDeep(DEFAULT_TYPOGRAPHY);
  /**
   * @type {Array<columnDef>}
   * Array of column definitions for the common card.
   */
  @Input() columns!: Array<columnDef>;
  /**
   * @type {Array<DynamicDataSource>}
   * Array of data sources for the common card.
   */
  @Input() dataSource!: Array<DynamicDataSource>;
  /**
   * @type {Array<TableAction>}
   * Array of action settings for the common card.
   */
  @Input() actionSetting!: Array<TableAction>;
  /**
   * @type {Array<SortSetting>}
   * Array of sort settings for the common card.
   */
  @Input() sortSetting!: Array<SortSetting>;
  /**
   * @type {SortSetting}
   * Selected sort setting for the common card.
   */
  @Input() selectedSort: SortSetting = _.cloneDeep(DEFAULT_SELECTEDSORT);
  /**
   * @type {SearchSetting}
   * Search setting for the common card.
   */
  @Input() searchSetting!: SearchSetting;
  /**
   * @type {boolean}
   * Flag indicating whether the search field is displayed.
   */
  @Input() hasSearchField: boolean = true;
  /**
   * @type {boolean}
   * Flag indicating whether the primary filter is used.
   */
  @Input() hasPrimaryFilter: boolean = false;
  /**
   * @type {boolean}
   * Flag indicating whether pagination is enabled.
   */
  @Input() hasPagination: boolean = false;
  /**
   * @type {boolean}
   * Flag indicating whether data is loading.
   */
  @Input() loading: boolean = false;
  /**
   * @type {PaginationData}
   * Pagination data for the common card.
   */
  @Input() paginationData!: PaginationData;
  /**
   * Input variable which is used to get the selected array data from the parent component
   * @type { any }
   */
  @Input() selectionArray: any[] = [];
  /**
   * Input property to hold an array of optional events.
   * This property receives an array of `OptionalEvent` objects that represent various optional events.
   * @type {Array<OptionalEvent>}
   */
  @Input() optionalEvent!: Array<OptionalEvent>;
  /**
   * Variable used to store applied filters length.
   * @type { number }
   */
  @Input() appliedFilters!: number
  /**
   * @type {EventEmitter<PaginationData>}
   * Event emitter for pagination changes.
   */
  @Output() paginationChange = new EventEmitter<PaginationData>();
  /**
   * @type {EventEmitter<ActionClicked<DynamicDataSource>>}
   * Event emitter for action clicks.
   */
  @Output() actionClicked = new EventEmitter<ActionClicked<DynamicDataSource>>();
  /**
   * @type {EventEmitter<DynamicDataSource>}
   * Event emitter for checkbox changes.
   */
  @Output() checkboxChange = new EventEmitter<DynamicDataSource>();
  /**
   * @type {EventEmitter<string>}
   * Event emitter for search changes.
   */
  @Output() searchChange = new EventEmitter<string>();
  /**
   * @type {EventEmitter<SortSetting>}
   * Event emitter for sort changes.
   */
  @Output() sortChange = new EventEmitter<SortSetting>();
  /**
   * EventEmitter for optional events.
   *
   * This EventEmitter emits an event of type `OptionalEvent` whenever an optional event occurs.
   * @type {EventEmitter<OptionalEvent>}
   */
  @Output() optionalEventOccurred = new EventEmitter<OptionalEvent>();
  /**
   * Variable used to emit output data to parent component.
   * @type {EventEmitter<SelectOptions>}
   */
  @Output() primarySelectEmittedData: EventEmitter<SelectOptions> = new EventEmitter();  
  /**
  * Event Emitter which is used to emit the selected data to the parent component.
  * @type { any }
  */
  @Output() selectedData = new EventEmitter<any>();
  /**
   * Event Emitter which is used to emit the clicked row and checkbox state(On/Off) to the parent component.
   * @type { any }
   */
  @Output() checkBoxChanged = new EventEmitter<any>();
  /**
   * @type { boolean[] }
   * An array that tracks the checked state of individual checkboxes.
   */
  isSingleCheckboxChecked: boolean[] = []
  /**
   * variable array which is used to store the unchecked rows in an array
   * @type { any }
   */
  unCheckedArray: any[] = [];
  /**
   * Selection Model which is used in checkbox multiselect to store the multiselect
   * @type { any }
   */
  selection = new SelectionModel<any>(true, []);

  /**
   * @type {number}
   * Number of flexible columns in the common card.
   */
  flexibleColumnCount: number = 5;
  /**
   * @type {ColumnType}
   * Enum for column types.
   */
  columnType = ColumnType;
  /**
   * @type {FormControl}
   * Form control for the search query.
   */
  searchQuery = new FormControl<string>('');
  /**
   * @type {string}
   * Date format used for displaying dates as 'dd MMM, yyyy'.
   */
  dateFormat: string = "dd MMM, yyyy"
  
   /**
   * @type {EventEmitter<DynamicDataSource>}
   * Event emitter for toggle changes.
   */
  @Output() toggleClicked = new EventEmitter<DynamicDataSource>();
  
  /*
    * Variable used to initialize the form group for the filter controls.
    * @type {FormGroup}
    */
  filterForm: FormGroup = new FormGroup({});

  /**
    * Variable used to store the options of type select.
    * @type {SelectOptions[]}
    */
  selectionConstant: SelectOptions[] = [];

  /**
  * Variable(array) which is used to store the selected row data in for emitting.
  * @type { any }
  */
  selectedArray: any = [];

  /**
   * Variable which us used to store the current display data for the page
   * @type { any }
   */
  displayData: any = null;
  
  /**
   * Variable used to check the existence of typed input.
   * @type {boolean}
   */
  isSelectorOptions: boolean = false;

  /**
   * Variable used to store filtered options of type select.
   * @type {SelectOptions[]}
   */
  selectionOptionValue: SelectOptions[] = [];

  /**
   * Variable used to store value of typed input in the search field.
   * @type {FormControl}
   */
  selectorSearchFilter: FormControl = new FormControl(null);

  /**
   * @type {Subscription}
   * Subscription for managing subscriptions.
   */
  subscription: Subscription = new Subscription();

  /**
   * @type { boolean }
   * Indicates whether the master checkbox (which typically selects or deselects all items) is checked or not.
   */
  isMasterCheckboxChecked: boolean = false;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;

  /**
   * Vaialbe used to flag the filter-conatiner is loaded or not
   * @type { boolean }
   */
  hasFilterAppeared: boolean = true;
  /** 
  * @param auth To refer to the AuthService to access the region subscription.
  */
  constructor(private auth: AuthService, private commonService: CommonService) {
    super();
  }
   /**
   * @type {EventEmitter<DynamicDataSource>}
   * Event emitter for dropdown changes.
   */
  @Output() dropdownClicked = new EventEmitter<DynamicDataSource>();
  /**
   * It allows the component to access an instance of MatPaginator from its template.
   */
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  ngOnInit(): void {
    this.flexibleColumnCount = this.columns?.filter((column: columnDef) => {
      return !column?.columnWidth;
    }).length;
    if (this.hasSearchField) {
      this.searchQuery?.setValue(
        this.searchSetting && this.searchSetting?.searchText
          ? this.searchSetting?.searchText
          : ''
      );
      this.subscription?.add(
        this.searchQuery?.valueChanges
          .pipe(
            debounceTime(500),
            map((value: string | null) => {
              return value ?? '';
            })
          )
          .subscribe({
            next: (value: string) => {
              this.searchChange.emit(value); 
              if(this.paginator?.pageIndex){
              this.paginator.pageIndex = 0; 
              }
              if(value != '' ){
              this.onPageChange({ length: this.paginationData?.count, pageIndex: 0, pageSize: this.paginationData?.limit, previousPageIndex: 0 });
              }
            },
          })
      );
    }
    this.setDisplayData();
    // if (this.selectionArray && this.selectionArray.length) {
    //   this.selectedArray.push(...this.selectionArray);
    //   this.selection.select(...this.selectionArray);
    //   if (this.displayData && this.displayData.length) {
    //     this.displayData.map((element: any) => {
    //       element['checked'] = this.checkForSelection(element);
    //     });
    //   }
    // }
    // this.selection.changed.subscribe((res: any) => {
    //   this.selectionDataEmit();
    // });
    this.subscription = this.commonService.isFilterClicked.subscribe(res =>{
      this.isFilterClicked = res;
    })
  }

  /**
   * Event handler triggered when @Input proporty changes
   */
  ngOnChanges(changes : SimpleChanges) : void { 
    if (this.paginationData && !_.isEqual(changes?.['paginationData']?.previousValue, changes?.['paginationData']?.currentValue)) {
      if(!!(this.paginator?.pageIndex)){
      this.paginator.pageIndex = this.paginationData?.offset;
      }
    }
    if ( this.primaryFilterData && !!(changes['primaryFilterData']) ) {
      this.filterForm = new FormGroup({});
      this.onSelectionControl(this.primaryFilterData);
      this.onSelect();
    }
    this.setDisplayData();
  }

  /**
  * If the dataSource has data, it sets the displayData and checks for existence and calls setCheckboxExistMethod.
  */
  setDisplayData(): void {
    if (this.dataSource && this.dataSource.length > 0) {
      this.displayData = this.dataSource;
      if(this.isSingleCheckboxChecked && this.isSingleCheckboxChecked.length === 0 && this.displayData && this.displayData.length > 0) {
        this.displayData && this.displayData.forEach((listData: any) => {
          this.isSingleCheckboxChecked && this.isSingleCheckboxChecked.push(false); 
        })
      } 
      this.setCheckboxExist();
    }
    else {
      this.isSingleCheckboxChecked = [];
      this.selectedArray = [];
      this.displayData = [];
    }
  }

  /**
  * Checks if each item in displayData exists in the selectedArray by matching either the id or customerId.
  * Sets the exist array at the corresponding index to true if a match is found, otherwise false. 
  */
  setCheckboxExist(): void {
    if(this.selectedArray && this.selectedArray.length > 0) {
      const selectedArray = this.selectedArray;
      this.displayData && this.displayData.forEach((listData: any, index: number) => {
        this.isSingleCheckboxChecked[index] = !!(selectedArray && selectedArray.find((selectedData: any) => 
          listData.id !== null && listData.id !== undefined ? listData.id === selectedData.id : listData.customerId === selectedData.customerId))
      })
      if(this.isSingleCheckboxChecked && this.isSingleCheckboxChecked.every((isChecked: any) => isChecked === true)) {
        this.isMasterCheckboxChecked = true;
      }
      else {
        this.isMasterCheckboxChecked = false;
      }
    }
  }

  /**
   * Metohd which is used to emit selected data
   * It will emit through checkBoxChanged and selectedData emitters
   */
  selectionDataEmit(): void {
    const ids = this.selectedArray?.map((o: any) => o.id);
    const arr = this.selectedArray;
    const data = arr.filter(({ id }: any, index: any) => !ids?.includes(id, index + 1));
    this.selectedArray = data;
    this.checkBoxChanged.emit(data);
    this.selectedData.emit(data);
  }

  /**
   * @type {void}
   * Event handler for page changes.
   */
  onPageChange(event: PageEvent): void {
    this.paginationData.count = event?.length;
    this.paginationData.offset = event?.pageIndex * event.pageSize;
    this.paginationData.limit = event?.pageSize;
    this.paginationChange.emit(this.paginationData);
  }
  /**
   * @type {void}
   * Event handler for action clicks.
   */
  onAction(method: string, data: DynamicDataSource): void {
    this.actionClicked.emit({ method: method, data: data });
  }

  /**
   * Handles the click event for a checkbox in a row.
   * @param { MatCheckboxChange } event - click event for checkbox click
   * @param data  - it passes the row data of clicked row
   */
  singleCheckBoxClick(event: MatCheckboxChange, eventData: any): void {
    if (!event.checked && this.selectedArray && this.selectedArray.findIndex((selectedData: any) => 
      selectedData.id ? selectedData.id === eventData.id : selectedData.customerId == eventData.customerId) != -1) {
        this.selectedArray.splice(this.selectedArray && this.selectedArray.findIndex((selectedData: any) => 
          selectedData.id ? selectedData.id === eventData.id : selectedData.customerId == eventData.customerId), 1);
    } 
    else {
      // this.unCheckedArray.splice(this.unCheckedArray.findIndex(item => (item.id == data.id || item.customerId == data.customerId)), 1);
      this.selectedArray && this.selectedArray.push(eventData);
      // this.selection.select(data);
      // this.displayData[this.displayData.findIndex((item: any) => (item.id == data.id || item.customerId == data.customerId))].checked = true;
    }
    this.setCheckboxExist();
    // return this.displayData[this.displayData.findIndex((item: any) => (item.id == data.id || item.customerId == data.customerId))].checked
  }

  /**
   * @type {void}
   * Event handler for sort changes.
   */
  onSortChange(sort: SortSetting): void {
    if (this.selectedSort && this.selectedSort.label === sort.label) {
      this.selectedSort.direction = 'asc';
      this.selectedSort.label = '';
      this.selectedSort.field = '';
      this.sortChange.emit(this.selectedSort);
    } else {
      this.selectedSort.label = sort?.label;
      this.selectedSort.field = sort?.field;
      this.selectedSort.direction = sort?.direction;
      this.sortChange.emit(sort);
    }
  }

  /**
   * @type {void}
   * Event handler for toggle.
   */
  onToggleChange(event: any, item: DynamicDataSource, column: columnDef): void {
    if (!column?.disable || column?.disable!==undefined) {
      const tempItem = _.cloneDeep(item);
      event.source.checked = tempItem[column?.toggleCondition];
      tempItem['toggleCondition'] = column?.toggleCondition;
      this.toggleClicked.emit(tempItem);
    }
  }
   /**
   * @type {void}
   * Event handler for dropdown.
   */
  onDropdownChange(event: any, item: DynamicDataSource, column: columnDef): void {
    if (!column?.disable) {    
      const tempItem = _.cloneDeep(item);
      event.source.value=tempItem[column?.dropDownCondition];
      this.dropdownClicked.emit(tempItem);
    }
  }
    /**
   * @type {string}
   *Method which is used to selector color for based on selected value of dropdown.
   */
  dropDownSelectedColor(item: DynamicDataSource, column: columnDef): { backgroundColor: string, textColor: string } {
    const defaultBackgroundColor = '#3498DB';
    const defaultTextColor = 'white';
    const selectedValue = item[column?.dropDownCondition];
    const selectedOption = column?.dropDownOption?.find((option:DropDownOptions) => option?.id === selectedValue);
    const backgroundColor = selectedOption && selectedOption?.backgroundColor ? selectedOption?.backgroundColor : defaultBackgroundColor;
    const textColor = selectedOption && selectedOption?.textColor ? selectedOption?.textColor : defaultTextColor;
    return { backgroundColor, textColor }
  }
  /**
   * Assign chip background color, font-color and chip text. 
   * @type {string | number | boolean}
   * @param {ChipProperties} displayValue - Contains background-color or font-color or chip text as objects.
   * @param {string | boolean | number} value - Contains the value of background-color or font-color or chip text to compare with displayValue.
   * @param {string | number | boolean} defaultValue - Contains a default value for background-color or font-color or chip text.
   */
  getChipProperties(displayValue: ChipProperties, value: string | boolean | number, defaultValue: string | number | boolean = ''): string | number | boolean {
    if (displayValue && displayValue.hasOwnProperty(""+value)) {
      return displayValue[""+value];
    } else {
      return defaultValue;
    }
  }

  /**
   * The method filters the options in a dropdown based on the user's input.
   * @param value - The input event containing the user's search value.
   * @param filter - The filter configuration object containing the options and display name.
   */
  onSelectorSearchFilter(value: Event, filter?: FilterData): void {
    if(filter && filter.options && filter.displayName){
      let searchValue: string = (value.target as HTMLInputElement).value;
     if (searchValue && searchValue.length) {
       this.isSelectorOptions = true;
       let filterValue: string = searchValue.toString().trim().toLowerCase();
       this.selectionConstant =  filter?.options;
       let filterkey: string = filter?.displayName;
       const filteredArray = this.selectionConstant.filter((selectorValue: SelectOptions) => (selectorValue[filterkey].toString()).toLowerCase().includes(filterValue));
       this.selectionOptionValue = filteredArray;
      }
     else {
       this.onSelectorSearchFilterClose();
     }
    }
   }

  /**
   * The method closes the search filter and resets the search input and options.
   */
   onSelectorSearchFilterClose(): void {
    this.isSelectorOptions = false;
    this.selectorSearchFilter.setValue(null);
    this.selectionOptionValue = [];
  }

  /**
   * The method adds a new FormControl for a selection field to the form group.
   * @param controlData consists of required information about selection control.
   */
  onSelectionControl(controlData: FilterData): void {
    if(controlData?.options && this.primaryFilterData?.valueName){
      const valueName = this.primaryFilterData?.valueName;
      this.filterForm.addControl(controlData.field, new FormControl(controlData?.options[0][valueName]));
    }
  }

  /**
   * Emits initial value. 
   * Handles the selction action for the filter form.
   * This method is called when the user selects a filters.
   * It emits the filtered data using the `primarySelectEmittedData` EventEmitter, passing the current value of the filter form.
   */
  onSelect(): void {
    this.primarySelectEmittedData.emit(this.filterForm?.value);
    this.paginationData.limit = 5;
    this.paginationData.offset = 0;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = this.paginationData?.limit;
    }
    this.paginationChange.emit(this.paginationData);
    this.onSelectorSearchFilterClose();
  }

  /**
   * If the checkbox is unchecked, it removes all items in displayData from the selectedArray.
   * If the checkbox is checked, it adds all items in displayData to the selectedArray.
   * @param { MatCheckboxChange } event - The checkbox change event.
   */
  masterCheckbox(event: MatCheckboxChange) {
    if (event && !event.checked) {
      const displayData = this.displayData;
      displayData && displayData.forEach((listData: any) => {
        const index = this.selectedArray && this.selectedArray.findIndex((selectedData: any) => 
          selectedData.id ? selectedData.id === listData.id : selectedData.customerId == listData.customerId);
        if (index !== -1) {
          this.selectedArray && this.selectedArray.splice(index, 1);
        }
      });
      // this.selection.deselect(...selectedData);
      // this.setCheckboxExist();
      this.isMasterCheckboxChecked = false;
      this.displayData && this.displayData.forEach((listData: any, index: number) => {
        this.isSingleCheckboxChecked[index] = false;
      });
    } 
    else {
      const displayData = this.displayData;
      displayData && displayData.forEach((listData: any) => {
        const index = this.selectedArray && this.selectedArray.findIndex((selectedData: any) => 
          selectedData.id ? selectedData.id === listData.id : selectedData.customerId == listData.customerId);
        if (index >=  0) {
          this.selectedArray && this.selectedArray.splice(index, 1);
        }
      })
      this.selectedArray.push(...displayData);
      // this.selection.select(...selectedData);
      // this.setCheckboxExist();
      this.isMasterCheckboxChecked = true;
      this.displayData.forEach((listData: any, index: number) => {
        this.isSingleCheckboxChecked[index] = true;
      });
    }
  }

  /**
   * Checks if all items in the display data are selected.
   * @returns { boolean } it will return boolan value based on all seleted items in that display
   */
  isAllSelected(): boolean {
    let arr = this.displayData && this.displayData.length ? this.displayData : [];
    arr = arr && arr.filter((item: any) => {
      return this.checkForSelection(item);
    });
    return arr && arr.length === this.displayData && this.displayData.length;
  }

  /**
   * Checks if a specific data item is selected.
   * @param data - The data item to check for selection.
   * @returns {boolean} - Returns true if the data item is selected, otherwise false.
   */
  checkForSelection(data: any): boolean {
    const isExist = this.selectedArray && this.selectedArray.find((item: any) => (item.id ? item.id === data.id : item.customerId == data.customerId));
    return isExist ? true : false;
  }

  /**
   * Handles the click event on a filter container.
   * @param { OptionalEvent } item  - The filter item that was clicked. This could contain data like filter type, icon, etc.
   */
  onFilterClick(item: OptionalEvent): void {
    if(this.dataSource && this.dataSource.length > 0 || this.appliedFilters > 0) {
      this.hasFilterAppeared = false;
      this.commonService.isFilterClicked.next(!this.isFilterClicked);
    }
    this.optionalEventOccurred.emit(item);
  }

  /**
   * Angular life cycle hook which is used to clear subscription.
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.selectedArray = [];
    this.displayData = [];
    this.isSingleCheckboxChecked = [];
    this.appliedFilters = -1;
    this.commonService.isFilterClicked.next(false);
  }
  isSearch:boolean=false;
  openSearch(){
    this.isSearch=!this.isSearch
  }
  isDisableFunction(disable: boolean | ((item: any) => boolean)): disable is (item: any) => boolean {
    return typeof disable === 'function';
  }
}
