<div *ngIf="!initialLoader" class="common-header">
  <app-common-header (methodClicked)="headerEvent($event)" [buttonInfo]="button" [heading]="heading" ></app-common-header>
</div>
<div *ngIf="initialLoader" class="common-card-style">
  <app-skeleton-loader [formLoader]="false"></app-skeleton-loader>
</div>
<div fxLayout.lt-md="column" *ngIf="!initialLoader"  class="common-card-style">
    <app-common-filter *ngIf="isFilter" [filterData]="filterDetails" [isFilterStatus]="isFilterValue"
       (filterClose)="closeFilter()" (emittedData)="emittedEvent($event)" (AppliedFilters)="appliedFiltersLength($event)">
    </app-common-filter>
    <div fxFlex="100%">
        <app-common-card 
        (primarySelectEmittedData)="primaryDropdownEvent($event)" [dataNotFoundMessage]="noDataMessage" [primaryFilterData]="primaryFilterOptions" 
        [columns]="columns" [dataSource]="data" [actionSetting]="actions" [hasSearchField]="true" [appliedFilters]="appliedFilters"
        (actionClicked)="onActionClicked($event)" [searchSetting]="searchSettingData" [hasPagination]="true" 
        (paginationChange)="handlePaginationChange($event)" [hasPrimaryFilter]="!!(primaryFilterOptions?.options?.length)"
        [paginationData]="paginationData" [optionalEvent]="optionalEvent" [loading]="configirationListLoader"
        (searchChange)="handleSearchChange($event)" (optionalEventOccurred)="handleOptionalEvent($event)"></app-common-card>
    </div>
  </div>