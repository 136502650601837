import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { TemplateListComponent } from './components/template-list/template-list.component';
import { CoreModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { AddEditTemplateComponent } from './components/add-edit-template/add-edit-template.component';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { MatChipsModule } from '@angular/material/chips';
import { SkeletonLoaderComponent } from '../shared/components/skeleton-loader/skeleton-loader.component';



@NgModule({
  declarations: [
    // TemplateListComponent,
    AddEditTemplateComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    EditorComponent,
    MatChipsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA] 
})
export class TemplateModule { }
