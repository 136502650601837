import { Component } from '@angular/core';
import { Subscription, switchMap } from 'rxjs';
import { ColumnType, TEMPLATE_MESSAGES } from 'src/app/shared/constants/common-card-data.constants';
import { columnDef, PaginationData, SearchSetting, SortSetting, TableAction, OptionalEvent, DynamicDataSource, ActionClicked } from 'src/app/shared/models/common-card-data.model';
import { ReportService } from '../../services/report.service';
import { Router } from '@angular/router';
import { EmittedData, FilterData, SelectOptions } from 'src/app/shared/models/common-filter.model';
import { CategoryResponse, ReportListParams, ReportResponse, Report, Reports, ReportTableData, userListDataResponse, NullFilter, filterValue } from '../../models/reports.model';
import { AuthService } from 'src/app/auth/service/auth.service';
import { FilterType } from 'src/app/shared/constants/common-filter-data.contants';
import { applicationsTableData, getApplicationResponse, getUserListResponse } from 'src/app/zen-mail/Applications/models/applications.model';
import { Heading } from 'src/app/shared/constants/common-header.constants';
import { CommonService } from 'src/app/shared/service/common.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  standalone:true,
  imports:[SharedModule,CommonModule],
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent {
  /**
   * Variable used indiacte wether the component has a search bar.
   * @type { boolean }
   */
  hasSearchFeild = true;
  /**
   * Variable used indiacte wether primary dropdown is used in the component.
   * @type { boolean }
   */
  hasPrimaryDropdown = true ; 
  /**
  * Variable used to store the table Pagination data.
  * @type { PaginationData }
  */
  paginationData: PaginationData = {
    limit: 5,
    count: 0,
    offset: 0
   };

  /**
   * Variable used to store the observables.
   * @type { Subscription }
   */
  SubscriptionObject: Subscription = new Subscription();

  /**
  * Variable used to store the searching text.
  * @type { string }
  */
  searchText!: string;

  /**
   * Variable used to store the primary filter data.
   * @type { string }
   */
  primaryFilterData: string = '';

  /**
   * Flag Variable used to indicate whether enable filter or not.
   * @type { boolean }
   */
  isFilter: boolean = false;

  /**
   * Flag Variable used to indicate whether enable the loader or not.
   * @type { boolean }
   */
  reportListLoader: boolean = true

  filterValue : filterValue = {};

  /**
   * Variable used to store search settings data.
   * @type { SearchSetting }
   */
  searchSettingData: SearchSetting = {
    searchText: '',
    placeHolder: 'Search Report',
    appearance: 'outline'
  }

  /**
   * Variable used to store the table column definition.
   * @type { Array<columnDef> }
   */
  columns: Array<columnDef> = [
    { field: '', header: '', type: ColumnType.Checkbox ,columnWidth: '10%',columnAlign:'start'},
    { field: 'customerName', header: 'Report Name', type: ColumnType.Text,columnAlign:'start',columnWidth: '35%' },
    { field: 'totalMails', header: 'Total Mails Sent', type: ColumnType.Text, format: 'short' ,columnWidth: '35%'},
    { field: 'actions', header: 'Actions', type: ColumnType.Action, columnWidth: '15%' },
  ];

  /**
   * Variable used to store the table actions data.
   * @type { Array<TableAction> } 
   */
  actions: Array<TableAction> = [
    { icon: 'remove_red_eye', tooltip: 'View Reports', method: 'onView', color: '#0068b5'}
  ];

  /**
   * Variable used to store the table filter data of optional event.
   * @type { Array<OptionalEvent> }
   */
  optionalEvent: Array<OptionalEvent> = [
    { eventName: 'onFilter', icon: 'filter_list', toolTip: 'Filter', class: 'filter', color: 'white' }
  ]

  /**
   * Variable used to store the table data.
   * @type { ReportTableData[] }
   */
  data!: ReportTableData[]

  /**
   * Variable used to store the categories of the mail to perform filter.
   * @type { SelectOptions[] }
   */
  filterCategory!: SelectOptions[];

  /**
   * Variable used to store the details for filter.
   * @type { FilterData[] }
   */
  filterDetails!: FilterData[];

  /**
   * Variable used to show application filter options.
   * @type { SelectOptions[] }
   */
  filterApplicationOption: SelectOptions[] = [{}]

  /**
   * Variable used to store user filter options.
   * @type { SelectOptions[] }
   */
  filterUserOption: SelectOptions[] = [{}]

  /**
   * Variable used to store the details for primary filter.
   * @type {FilterData}
   */
  primaryfilterDetails?: FilterData;
    /**
  * Variable used to store the isFilterValue.
  * @type {boolean}
  */
    isFilterValue:boolean=false;
  /**
   * Variable has header data to be displayed on commen header.
   * @type {Heading}
   */
  heading:Heading = {
    title : "Reports",
    description : ' View detailed reports to analyze activity and optimize your email management.',
    fontWeight : '500'
  }
  /**
   * Variable used to store no data message.
   * @type {string}
   */
  noDataMessage : string = TEMPLATE_MESSAGES.NO_RECORD_REPORTS;  
  /**
  * Flag Variable used to initial loader.
  * @type {boolean}
  */
  initialLoader:boolean=false;
  
  /**
   * Variable to save the applied filters
   * @type {number}
   */
  appliedFilters: number = -1;

  /**
   * Variable used to flag the filter-container is clicked or not
   * @type { boolean }
   */
  isFilterClicked: boolean = false;

  /**
   * component constructor which is used to inject the required services.
   * @param report To refer to the ReportService to access the mail report functions.
   * @param router To refer to the router to navigate to the report page.
   * @param auth To refer to the AuthService to access the region subscription.
   */
  constructor(private report: ReportService, private router: Router, private auth: AuthService, private commonService: CommonService) { }

  /**
   * Angular life cycle hook
   * @returns { void }
   */
  ngAfterViewInit(): void {
    this.auth.region$
      .pipe(
        switchMap(() => this.report.getMailCategories())
      )
      .subscribe({
        next: (categoryResponse: CategoryResponse) => {
          this.filterCategory = categoryResponse &&  categoryResponse.category && categoryResponse.category.rows.map(category => ({
            id: category.id,
            name: category.categoryName
          }));
          this.initialLoader=true;
          this.reportListLoader = true;
          this.gettingUserList();
          this.getReportList({ offset : 0 , limit : 5 });
          this.getApplicationList({});
          this.appliedFilters = -1;
          this.isFilter = false;
        },                                                                                                                                              
        error: (err) => {
          this.reportListLoader = false;
          console.error('Error fetching mail categories', err);
        }
      });
    this.commonService.isFilterClicked.subscribe(res =>{
      this.isFilterClicked = res;
    })
  }
  /**
   * Method which is used to subscribe the reportlist data.
   * @param { ReportListParams } params It has the query params which has to be passed in the reportlist data.
   * @returns { void }
   */
  getReportList(params: ReportListParams): void {
    this.SubscriptionObject = this.report.getReportList(params)
      .subscribe({
        next: (reportResponse: ReportResponse) => {
          this.getReportListData(reportResponse?.report?.rows);
          this.paginationData.count =  reportResponse && reportResponse.report && reportResponse.report.count;
          this.initialLoader=false;
          this.reportListLoader = false;
        },
        error: (err) => {
          console.error('Error fetching report list', err);
          this.initialLoader=false;
          this.reportListLoader = false;
        }
      });
  }
  
  /**
  * Method which is used to map the report list data to the table data format.
  * @param { Report[] } reportList It has the list of all the reports.
  * @returns { void }
  */
  getReportListData(reportList: Report[]): void {
    this.data = reportList.map((item: Report) => {
      const totalMails = item.reports.reduce((total: number, report: Reports) => {
        const successCount = report.successCount ?? 0;
        const failureCount = report.failureCount ?? 0;
        return total + successCount + failureCount;
      }, 0);
      return {
        customerName: item.customerName,
        customerId: item.customerId,
        totalMails: totalMails
      };
    });    
  }

  /**
   * Method which is used retrive user details from backend.
   * @returns { void }
   */
  gettingUserList(): void { 
    this.SubscriptionObject = this.report.getUserList({}).subscribe({
      next:(res:getUserListResponse) => {
        this.setUserDataFilterOption(res?.response?.rows);
      },
      error: (err) => { 
        console.error('Error fetching application list', err);
      }
    })
  }

  /**
   * Method which is used retrive application details from backend.
   * @param { ReportListParams } params - It has the list of report data. 
   * @returns { void }
   */
  getApplicationList(params: ReportListParams): void {
    this.SubscriptionObject = this.report.getApplicationList(params).subscribe({
      next: (res: getApplicationResponse) => {
        this.reportListLoader = false;
        this.setApplicationFilterOption(res && res.applicationData && res.applicationData.rows);
      },
      error: (err: string) => {
        console.error('Error fetching application list', err);
        this.reportListLoader = false;
      }
    })
  }

  /**
   * Method used to set filter options based on the application list data.
   * @param { applicationsTableData[] } applicationListData
   * @returns { void }
   */
  setApplicationFilterOption(applicationListData: applicationsTableData[]): void {
    this.filterApplicationOption.length = 0;
    applicationListData && applicationListData.map((applicationData: applicationsTableData, index: number) => {
      this.filterApplicationOption[index] = {'name': applicationData.applicationName, 'value': applicationData.id}
    })
  }

  /**
   * Method used to set filter options based on the user data.
   * @param { userListDataResponse[] } userListData 
   */
  setUserDataFilterOption(userListData: userListDataResponse[]): void {
    this.filterUserOption.length = 0;
    userListData.map((userData: userListDataResponse, index: number) => {
      this.filterUserOption[index] = ({"name" : userData?.firstName + userData?.lastName , "id" : userData?.id});
    });
    this.primaryfilterDetails = { title: 'User', type: FilterType.Select , options: this.filterUserOption, field: 'userId', displayName: 'name', valueName: 'id' };
  }

  /**
  * Method which is used to emit value while using filter.
  * @param { EmittedData } event It has the emitted value from the filter.
  * @returns { void }
  */
  emittedEvent(event: EmittedData): void {
    this.reportListLoader = true;
    this.filterValue = event;
    const data = this.paramAssign();
    data.offset = 0;
    this.paginationData = {
      ...this.paginationData,
      offset:  0
    };
    this.getReportList(data);
  }
  
  /**
   * Method used to assign total applied filters as number.
   * @param { number } totalAppliedFilters 
   */
  appliedFiltersLength(totalAppliedFilters: number): void {
    this.appliedFilters = totalAppliedFilters;
  }
  

  /**
  * Method which is used to for searching the table.
  * @param { string } event It has the searched string which is emitted.
  * @returns { void }
  */
  handleSearchChange(event: string): void {
    this.reportListLoader = true;
    this.searchText = event;
    const data = this.paramAssign();
    data.offset = 0
    this.getReportList(data);
  }

  /**
  * Method which is used to perform action of navigating to the reports page.
  * @param { ActionClicked<DynamicDataSource> } event It has the id of the selected report.
  * @returns { void }
  */
  handleActionClicked(event: ActionClicked<DynamicDataSource>): void {
    this.router && this.router.navigate(['app/mail/reports', event.data['customerId']])
  }

  /**
   * Method which is used to perform filtering in the table.
   * @param { OptionalEvent } event It has the event of filter in the table.
   * @returns { void }
   */
  handleOptionalEvent(event: OptionalEvent): void {
    if (event && event.eventName === 'onFilter' && this.isFilterClicked) {
      this.onFilter();
    }
  }

  /**
    * Method which is used to assign the filter data to the table.
    * @returns { void }
    */
  onFilter(): void {
    this.filterDetails = [
      { title: 'Category', type: FilterType.Select, options: this.filterCategory, field: 'categoryId', displayName: 'name', valueName: 'id' },
      { title: 'Application Name', type: FilterType.Select, options: this.filterApplicationOption, field: 'applicationId', displayName: 'name', valueName: 'value' }
    ]
    if (this.data?.length) {
      this.isFilterValue = true;
      this.isFilter= true;
    }
  }

  /**
  * Method used to close the filter tab and reset any active filters.
  * @returns { void }
  */
  closeFilter(): void{
    this.isFilterValue = !this.isFilterValue;
    setTimeout(() => {
      this.isFilter = !this.isFilter;
      const data = this.paramAssign();  
      data.offset = 0;
      this.getReportList(data);
    }, 300); 
   }

  /**
   * Method which is used for table Pagination.
   * @param { PaginationData } event It has the Pagination value which is emitted.
   * @returns { void }
   */
  handlePaginationChange(event: PaginationData): void {
    this.reportListLoader = true;
    this.paginationData.limit = event.limit;
    this.paginationData.offset = event.offset;
    const data = this.paramAssign();
    this.getReportList(data);
  }

  /**
   * Handles the event when a primary ID is selected from the dropdown.
   * @param { SelectOptions } userId 
   * @returns { void }
   */
  primaryDropdownEvent(userId: SelectOptions): void {
    this.reportListLoader = true;
    this.primaryFilterData = JSON.stringify(this.removeNullValues(userId));
    const data = this.paramAssign();
    data.offset = 0;
    if (this.isFilter === true) {
      this.closeFilter();
    }
    this.getReportList(data);
  }
  
  /**
   * This method removes any null or undefined objects in the filterValue.
   * @param { NullFilter } filterValue 
   * @returns { NullFilter }
   */
  removeNullValues(filterValue: NullFilter): NullFilter {
    return Object.keys(filterValue)
    .filter((key: string) => filterValue[key] !== null && filterValue[key] !== undefined)
    .reduce((acc: NullFilter, key: string) => {
      if (typeof filterValue[key] === 'object' && !Array.isArray(filterValue[key])) {
        const cleanedValue = this.removeNullValues(filterValue[key]);
        if (Object.keys(cleanedValue).length > 0) {
          acc[key] = cleanedValue;
        }
      } else {
        acc[key] = filterValue[key];
      }
      return acc;
    }, {});
  }

  /**
  * Method used to assign parameters for the logs list response.
  * @returns { ReportListParams } - The parameters for the log list API call.
  */
  paramAssign(): ReportListParams {
    let data: ReportListParams = {
      offset: this.paginationData.offset,
      limit: this.paginationData.limit
    }
    let cleanedFilterValue = {};
    let combinedFilterValue = {};
    if (this.isFilter) {
      cleanedFilterValue = this.removeNullValues(this.filterValue);
    }
    if (this.primaryFilterData) {
      const existingFilterData = JSON.parse(this.primaryFilterData);
      combinedFilterValue = { ...existingFilterData, ...cleanedFilterValue };
    } else {
      combinedFilterValue = cleanedFilterValue;
    }
    if (this.searchText) {
      data.searchText = this.searchText;
    }
    if (Object.keys(combinedFilterValue).length > 0) {
      data.filterData = JSON.stringify(combinedFilterValue);
    }
    return data;
  }

  /**
   * Angular life cycle hook.
   * @returns {void}
   */
  ngOnDestroy(): void {
    if (this.SubscriptionObject) {
      this.SubscriptionObject.unsubscribe();
    }
  }
}