import { Component } from '@angular/core';
import { CustomersComponent } from 'libs/shared/src/public-api';

@Component({
  selector: 'app-customers',
  templateUrl: './customers-mail.component.html',
  standalone: true,
  imports: [CustomersComponent],
  styleUrls: ['./customers-mail.component.scss']
})
export class CustomersMailComponent {
  
}
