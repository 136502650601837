import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpRoutingService } from 'src/app/common/service/http-routing.service';
import { HttpParams } from 'src/app/customers/models/customers.model';
import { DeleteUserDetail, UserListResponse, userTableData } from '../models/user.model';
import { UpdateResponse } from 'src/app/template/models/template.model';

@Injectable({
  providedIn: 'root'
})
export class UserListService {

  /**
   * component constructor which is used to inject the required services and modules.
   * @param httpClient To refer to the httpClient module to access the get and put methods.
   */
  constructor(private httpClient: HttpRoutingService) { }
  /**
  * Method which is used to get user list data from backend.
  * @param paramData It has the query params of the Url.
  * @type { Observable<UserListResponse>}
  */
  getUserList(paramData: HttpParams): Observable<UserListResponse> {
    return this.httpClient.getRequest('microservice/mail/user?', paramData);  
  }

  /**
  * Method which is used to update the selected user.
  * @param data
  * @returns { Observable<UpdateResponse> }
  */
  updateUser(data: {id: number, isSuspend: boolean}): Observable<UpdateResponse> {
    return this.httpClient.putRequest("microservice/mail/user/"+data.id+'/update', data);
  }

  /**
   * Deletes a user by sending a PUT request with the user's ID and data.
   * @param { number } userId - The ID of the user to be deleted.
   * @param { userTableData } data - The data related to the user being deleted.
   * @returns { Observable<DeleteUserDetail> } - An observable containing the details of the deleted user.
   */
  deleteUser(id: number): Observable<DeleteUserDetail> {
    return this.httpClient.putRequest( `microservice/mail/user/`+id+`/update`, {isDeleted: true});
  }

  /**
   * Creates a new user by sending a POST request with the user's data.
   * @param { userTableData } data - The data related to the user being created.
   * @returns { Observable<userTableData> } - An observable containing the details of the created user.
   */
  createUser(data: userTableData): Observable<userTableData> {
    return this.httpClient.postRequest('microservice/mail/user/create', data)
  }

}
