<div class="container" fxLayout="column" fxLayoutGap="3%">
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-card *ngIf="!isFailedToken" class="password-container" fxLayout="column" fxLayoutGap="2%" [ngStyle.lt-sm]="{'width':'325px'}">
      <div [ngStyle]="{'padding-top':'30px','text-align':'center'}">
        <h2 class="main-heading" [ngStyle]="{'margin-bottom':'8px'}">{{isChangePassword?'Reset your password':'Create a secure password'}}</h2>
        <hr [ngStyle]="{'width':'35px','height':'1px','background':'var(--primary-color)'}">
        <div [ngStyle]="{'color':'red','padding-top':ApiFailed?'12px':''}" fxLayout="row" *ngIf="ApiFailed"
        fxLayoutAlign="center none" fxLayoutGap="2%">
        <mat-icon *ngIf="ApiFailed">error_outline</mat-icon>
        <p [ngStyle]="{'padding-top':'3px'}">{{'Something Went Wrong'}}</p>
      </div>
      </div>
      <div>
        <form [formGroup]="passwordForm">
          <div fxLayout="column" fxLayoutGap="2%" [ngStyle]="{'padding':'15px 30px 30px 30px'}">
            <div fxLayout="column" fxLayoutGap="-5%" *ngIf="isChangePassword">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row">
                  <input matInput placeholder="Old Password *"
                    [type]="showOldPassword.oldPassword ? 'text' : 'password'" formControlName="oldPassword"
                    [ngStyle]="{'width':'90%'}">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)','cursor':'pointer'}"
                    (click)="showPasswordType('oldPassword')">
                    {{showOldPassword.oldPassword ? 'visibility_outline' : 'visibility_off_outline'}}
                  </mat-icon>
                </div>
                <mat-error data-control="fieldRequiredError"
                  *ngIf="passwordForm?.get('oldPassword')?.hasError('required')">
                  <span>{{message['oldPasswordRequired']}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="column" fxLayoutGap="-5%">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row">
                  <input matInput placeholder="New Password *"
                    [type]="showNewPassword.newPassword ? 'text' : 'password'" formControlName="newPassword"
                    [ngStyle]="{'width':'90%'}">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)','cursor':'pointer'}"
                    (click)="showPasswordType('newPassword')">
                    {{showNewPassword.newPassword ? 'visibility_outline' : 'visibility_off_outline'}}
                  </mat-icon>
                </div>
                <mat-error data-control="fieldRequiredError"
                  *ngIf="passwordForm?.get('newPassword')?.hasError('required')">
                  <span>{{message['newPasswordRequired']}}</span>
                </mat-error>
                <mat-error data-control="fieldRequiredError"
                  *ngIf="passwordForm.controls['newPassword'].errors">
                  <span>{{message['invalidPassword']}}</span>
                </mat-error>
              </mat-form-field>
              <div fxLayout="row" fxLayoutGap="3%" fxLayoutAlign="center center" [ngStyle]="{ 'padding': '10px 20px 10px 4px' }"*ngIf="passwordForm.controls['newPassword'].value">
              <mat-progress-bar mode="determinate" [value]="calculatePasswordStrength()" ></mat-progress-bar>
              <span class="password-text" [ngStyle]="{'color':getProgressColor()}">
                {{ getPasswordStrengthLabel() }}
              </span>
              <mat-icon class="list-icon" *ngIf="passwordForm.controls['newPassword'].errors" [matTooltip]="'Password Validation'" (click)="onShowvalidation()"> list_alt</mat-icon>
            </div>
            <div class="errors" *ngIf="isErrorShow && passwordForm.controls['newPassword'].value &&passwordForm.controls['newPassword'].errors" [ngStyle]="{'padding-top':'10px'}">
              <mat-error [ngClass]="{
                'error-red': passwordForm.get('newPassword')?.hasError('containsWhitespace'),
                'error-green': !passwordForm.get('newPassword')?.hasError('containsWhitespace')
              }">
                <span>Password must not contain any empty space.</span>
              </mat-error>
              <mat-error [ngClass]="{
                'error-red': passwordForm.get('newPassword')?.hasError('missingUpperCase'),
                'error-green': !passwordForm.get('newPassword')?.hasError('missingUpperCase')
              }">
                <span>Password must contain at least one uppercase letter.</span>
              </mat-error>
              <mat-error [ngClass]="{
                'error-red': passwordForm.get('newPassword')?.hasError('missingLowerCase'),
                'error-green': !passwordForm.get('newPassword')?.hasError('missingLowerCase')
              }">
                <span>Password must contain at least one lowercase letter.</span>
              </mat-error>
              <mat-error [ngClass]="{
                'error-red': passwordForm.get('newPassword')?.hasError('missingNumber'),
                'error-green': !passwordForm.get('newPassword')?.hasError('missingNumber')
              }">
                <span>Password must contain at least one number.</span>
              </mat-error>
              <mat-error [ngClass]="{
                'error-red': passwordForm.get('newPassword')?.hasError('missingSpecialChar'),
                'error-green': !passwordForm.get('newPassword')?.hasError('missingSpecialChar')
              }">
                <span>Password must contain at least one special character.</span>
              </mat-error>
              <mat-error [ngClass]="{
                'error-red': passwordForm.get('newPassword')?.hasError('invalidLength'),
                'error-green': !passwordForm.get('newPassword')?.hasError('invalidLength')
              }">
                <span>Password must be at least 8 characters long.</span>
              </mat-error>
            </div>
            </div>
            <div fxLayout="column" fxLayoutGap="3%">
              <mat-form-field appearance="outline" fxFlex="100%" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="3%">
                  <input matInput placeholder="Confirm Password *"
                    [type]="showConfirmPassword.confirmPassword ? 'text' : 'password'" formControlName="confirmPassword"
                    [ngStyle]="{'width':'90%'}">
                  <mat-icon [ngStyle]="{'color':'var(--primary-color)','cursor':'pointer'}"
                    (click)="showPasswordType('confirmPassword')">
                    {{showConfirmPassword.confirmPassword ? 'visibility_outline' : 'visibility_off_outline'}}
                  </mat-icon>
                </div>
                <mat-error data-control="fieldRequiredError"
                  *ngIf="passwordForm?.get('confirmPassword')?.hasError('required')">
                  <span>{{message['confirmPasswordRequired']}}</span>
                </mat-error>
                <mat-error data-control="passwordMismatchError"
                  *ngIf="passwordForm?.get('confirmPassword')?.hasError('passwordMismatch')">
                  <span>{{message['passwordsMustMatch']}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" [ngStyle]="{'padding-top':'25px'}">
              <button class="button-container" data-control="button" mat-raised-button
                (click)="onSetPassword()"><span *ngIf="!isLoading">{{isChangePassword?'Update Password':'Set Password'}}</span> <div class="dot-pulse" *ngIf="isLoading"></div></button>
            </div>
          </div>
        </form>
      </div>
    </mat-card>
    <mat-card *ngIf="isFailedToken" [ngStyle]="{'width':'350px'}">
      <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="2%" [ngStyle]="{'padding':'20px'}">
      <img [src]="image" alt="" height="100px" width="100px">
      <h2 [ngStyle]="{'text-align':'center'}">Oops! Something Went Wrong There.</h2>
    </div>
    </mat-card>
  </div>
</div>
