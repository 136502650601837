<div *ngIf="!initialLoader" class="common-header">
  <app-common-header [heading]="headerDetails" ></app-common-header>
</div>
<div *ngIf="initialLoader" class="common-card-style">
  <app-skeleton-loader [formLoader]="true"></app-skeleton-loader>
</div>
<div *ngIf="!initialLoader" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center start" class="report-container">
  <div fxFlex="100%" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
    <div fxFlex="50%" class="mat-elevation-z5 cards">
      <div class="customer-name">
        {{customerName}}<span class="customer-name-text"> 's Mail Reports</span>
      </div>
      <div echarts [options]="pieChartOptions" class="echart"></div>
      <div class="buttons" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
        <button color="primary" mat-raised-button>Invoice</button>
        <button color="primary" mat-raised-button>Export</button>
        <button color="primary" mat-raised-button (click)="onClick()">Back</button>
      </div>
    </div>
    <div fxFlex="50%" fxLayout="column" class="mat-elevation-z5 cards bar-chart-container">
      <div fxFlex="100%" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div fxFlex="40%" id="category0" class="bar-chart"></div>
        <div fxFlex="40%" id="category1" class="bar-chart"></div>
      </div>
      <div fxFlex="100%" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <div fxFlex="40%" id="category2" class="bar-chart"></div>
        <div fxFlex="40%" id="category3" class="bar-chart"></div>
      </div>
    </div>
  </div>
</div>